import React from 'react';
import LogsPage from '../Pages/Ai/Logs/LogsPage';

const LogsRouter = [
  {
    path: '/logs',
    element: <LogsPage/>,
  },
  {
    path: '/logs/:id',
    element: <LogsPage />,
  },

];

export default LogsRouter;
