import React from 'react';
import ChatPage from '../Pages/Messanger/ChatPage';
import ChatPageFirst from '../Pages/Messanger/ChatPageFirst';
import ChatConversation from '../Pages/Chat/ChatConversation';
import LayoutChat from '../Layouts/LayoutChat';
import LayoutChatAdmin from '../Layouts/LayoutChatAdmin';
import ChatHomePage from '../Pages/Messanger/ChatHomePage';
import ChatConversationAdmin from '../Pages/Chat/ChatCoversationAdmin';
import ChatPageAdminFirst from '../Pages/Chat/ChatPageAdminFirst';


const ChatRouter = [
  {
    path: '/chats',
    element: <ChatHomePage />
  },

  {
    path: '/karyawanAi',
    element: <LayoutChat />
  },

  {
    path: '/karyawanAi/:id',
    element:
      <LayoutChat>
        <ChatConversation />
      </LayoutChat>
  },

  {
    path: '/chat',
    element: <LayoutChatAdmin />,
  },

  {
    path: '/chat/:id',
    element: 
    <LayoutChatAdmin>
      <ChatConversationAdmin />
    </LayoutChatAdmin>,
  },

  {
    path: '/chat/admin',
    element: <ChatPage />,
  },

  {
    path: '/chat-user/:module/:companyId/:projectId',
    element: <ChatPageFirst />,
  },

  {
    path: '/chat-admin/:module/:companyId/:projectId',
    element: <ChatPageAdminFirst />,
  },






];

export default ChatRouter;
