// openai.js

import axios from 'axios';

const OPENAI_API_KEY = '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'; // Ganti dengan kunci API OpenAI Anda

export const getSentiment = async (message) => {
  try {
    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-3.5-turbo',
        temperature: 0,
        messages: [
          {
            'role': 'system',
            'content': 'As an AI with expertise in language and emotion analysis, your task is to analyze the sentiment of the following text. Please consider the overall tone of the discussion, the emotion conveyed by the language used, and the context in which words and phrases are used. Indicate whether the sentiment is generally positive, negative, or neutral, and provide brief explanations for your analysis where possible.'
          },
          {
            'role': 'user',
            'content': message
          }
        ],
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${OPENAI_API_KEY}`,
        },
      }
    );

    const { content } = response.data.choices[0].message;
    const totalTokens = response.data.usage.total_tokens;
    
    // Menentukan sentimen berdasarkan konten pesan
    const sentiment = content.includes('neutral', 'positive', 'negative') ? 'neutral' : content.includes('negative') ? 'negative' : 'positive';

    return { sentiment, totalTokens };
  } catch (error) {
    console.error('Error fetching sentiment:', error);
    return 'Error';
  }
};

