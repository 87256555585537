/* eslint-disable max-len */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState, } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  VStack,
  HStack,
  Divider,
  Text,
  IconButton,
  Stack,
  Checkbox,
  useToast,
  Flex,
  Switch,
  Spacer,
  Image,
  SimpleGrid,
  Select,
  Grid,
  Avatar,
  AvatarBadge,
  Icon,
  Heading,
  GridItem, // Tambahkan import untuk Checkbox
} from '@chakra-ui/react';
import { MdArrowDropDown, MdCancel, MdDelete, } from 'react-icons/md';
import {
  arrayRemoveFirebase,
  arrayUnionFirebase,
  getCollectionFirebase,
  getSingleDocumentFirebase,
  updateDocumentFirebase,
} from '../../Api/firebaseApi';
import useUserStore from '../../Hooks/Zustand/Store';
import { formatFrice, } from '../../Utils/numberUtil';
import BackButtons from '../../Components/Buttons/BackButtons';
import {
  useParams,
} from 'react-router-dom';
import CardFile from '../../Components/Accounting/CardFile';
import { decryptToken, } from '../../Utils/encrypToken';
import UploadFileToDropbox from '../../Components/DropBox/UploadFileToDropbox';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import { BsFillArrowDownCircleFill, } from 'react-icons/bs';
import { auth, } from '../../Config/firebase';
import ProductVariants from '../../Components/Product/ProductVariants';
import DynamicMultiSelectComponent from '../../Components/Select/DynamicMultiSelectComponent';


function ProductDetailPage() {
  const param = useParams()
  const productId = param?.id

  // Product State
  const [warehouses, setWarehouses] = useState([])
  const [details, setDetails] = useState([]);
  const [modules, setModules] = useState([]); // Tambahkan state untuk checkbox modules
  const globalState = useUserStore();
  const toast = useToast();
  const [dataCategory, setDataCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [productData, setProductData] = useState({
    categoryId: [],
    tags: []
  });
  const [projectId, setProjectId] = useState('');
  const [projectName, setProjectName] = useState('');
  const [fileName, setFileName] = useState('Select file to import')
  const [progress, setProgress] = useState();


  const getProject = () => {
    const res = globalState?.projects?.find(
      (e,) => e.id === globalState?.currentProject,
    );
    setProjectId(res?.id);
    setProjectName(res?.name);
  };
  const getDataCategory = async () => {
    // Fetch data from API or other source if needed
    try {
      const conditions = [
        { field: 'companyId', operator: '==', value: globalState.currentCompany, },
        { field: 'projectId', operator: '==', value: globalState.currentProject, },
        // { field: 'categoriesId', operator: '==', value: null},
        { field: 'is_parent', operator: '==', value: false, },
      ];

      const res = await getCollectionFirebase('product_categories', conditions,);
      setDataCategory(res);

    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      },);
    }
  };

  const handleDataInput = (key, value) => {
    const obj = { ...productData };
    obj[key] = value;
    // console.log(obj, 'updated obj');
    setProductData(obj)
  }



  const getProductData = async () => {
    try {
      const res = await getSingleDocumentFirebase('product', productId);
      const object = {
        ...res,
        tags : res?.tags?.length > 0 ? res?.tags : [],
        categoryId : res?.categoryId?.length > 0 ? res?.categoryId : [],
      };
      setProductData(object);
      localStorage.setItem('product_data_on_edit', JSON.stringify(object));
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      },);
    }
  };


  useEffect(() => {
    getProject();
    getProductData();
    getDataCategory()

  }, [productId, globalState.currentProject,],);


  const onSubmitProduct = async () => {
    setIsLoading(true)

    try {
      const newData = {
        name: productData.name || '',
        description: productData.description || '',
        type: productData.type || '',
        categoryId: productData.categoryId || [] ,
        companyId: globalState.currentCompany || '',
        createdBy: auth.currentUser.uid || '',
        currency: productData.currency || '',
        discount_amount: productData.discount_amount || '',
        discount_type: productData.discount_type || '',
        product_skuId: productData.product_skuId || '',
        external_productId: productData.external_productId || '',
        external_product_skuId: productData.external_product_skuId || '',
        label: productData.label || '',
        price: productData.price || '', // string
        price_int: productData.price || '0',
        price_high: productData.price_high || '0',
        price_low: productData.price_low || '0',
        projectId: productData.projectId || '0',
        details: details?.length > 0 ? details?.map((detail) => ({
          key: detail?.key?.toLowerCase(),
          value: detail?.value?.toLowerCase(),
        })) : [],
        sold: productData.sold || '0',
        stocks: productData.stocks,
        tags: productData.tags,
        is_active: productData.is_active || false,
        is_promo: productData.is_promo || false,
        warehouse_geolocation: warehouses?.length > 0 ? warehouses?.map((detail,) => ({
          latitude: detail?.latitude?.toLowerCase(),
          longitude: detail?.longitude?.toLowerCase(),
        }),) : [],
        modules: modules?.length > 0 ? modules?.map((module) => module?.toLowerCase()) : [],
      };

      console.log(newData)

      await updateDocumentFirebase('product', productId, newData)

      toast({
        title: 'Deoapp.com',
        description: 'success update data',
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });


    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      },);
      setIsLoading(false,);
    } finally {
      setIsLoading(false,);
    }
  };




  // Handle Detail Product & Warehouse 

  const handleAddDetail = () => {
    setDetails([...details, { key: '', value: '', },],);
  };
  const handleAddWarehouse = () => {
    setWarehouses([...warehouses, { latitude: '', longitude: '', },],);
  };

  const handleRemoveWarehouse = (index,) => {
    const updatedWarehouses = [...warehouses,];
    updatedWarehouses.splice(index, 1,);
    setWarehouses(updatedWarehouses,);
  };

  const handleRemoveDetail = (index,) => {
    const updatedDetails = [...details,];
    updatedDetails.splice(index, 1,);
    setDetails(updatedDetails,);
  };

  const handleWarehouseChange = (index, latitude, longitude,) => {
    const updatedWarehouses = [...warehouses,];
    updatedWarehouses[index] = { latitude, longitude, };
    setWarehouses(updatedWarehouses,);
  };
  const handleDetailChange = (index, key, value,) => {
    const updatedDetails = [...details,];
    updatedDetails[index] = { key, value, };
    setDetails(updatedDetails,);
  };


  // Handle Modules
  const handleModulesChange = (event,) => {
    const { value, checked, } = event.target;

    if (checked) {
      setModules([...modules, value,],);
    } else {
      const updatedModules = modules?.filter((module,) => module !== value,);
      setModules(updatedModules,);
    }
  };


  const onSelectFile = async (e) => {
    const file = e.target.files[0];
    console.log(file)
    setFileName(file?.name)
    try {
      const token = await getSingleDocumentFirebase('token', 'dropbox',);
      const decryptResult = decryptToken(`${token?.access_token}`,);


      const parentPath = `/${globalState?.currentCompany}/${globalState?.currentProject}/kanban`; // Ganti dengan path folder di Dropbox yang ingin Anda gunakan
      const dropboxLink = await UploadFileToDropbox(
        file,
        parentPath,
        decryptResult,
        setProgress,
        progress,
      );
      console.log(dropboxLink, 'dropboxLink');

      if (dropboxLink) {
        const collectionName = 'product';
        const docName = param.id;
        const field = 'images';
        const values = [dropboxLink?.link,];

        await arrayUnionFirebase(collectionName, docName, field, values,);

        toast({
          status: 'success',
          duration: 9000,
          title: 'Success upload your background card.',
          isClosable: true,
        });
        getProductData();
      } else {
        toast({
          status: 'error',
          duration: 9000,
          title: 'Upload image failed',
          isClosable: true,
        });
      }



    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      },);
    }

  }

  const handleRemoveFeature = (index, key) => {
    let array = productData[key];
    if (array?.length === 1) {
      array = []
    } else {
      array.splice(index, 1);
    }
    setProductData({
      ...productData,
      [key]: array
    })
  };


  const handleDeleteImage = async (data) => {
    try {
      const resultDelete = await arrayRemoveFirebase('product', productData.id, 'images', [data]);
      toast({
        status: 'success',
        description: resultDelete,
        duration: 3000
      })
      getProductData();
    } catch (error) {
      toast({
        status: 'error',
        duration: 3000,
        description: error.message
      })
    }
  };


  const handleAddValuesToArray = (categoryId, key) => {
    let arr = [];
    if (key === 'categoryId') {
      arr = [...productData?.categoryId]
    } else if (key === 'tags') {
      arr = [...productData?.tags]
    } else {
      arr = productData[key];
    }

    arr.push(categoryId);

    switch (key) {
    case 'categoryId':
      setProductData({
        ...productData,
        categoryId: arr
      });
      break;
    case 'tags':
      setProductData({
        ...productData,
        tags: arr
      });
      break;
    default:
      setProductData({
        ...productData,
        [key]: arr
      });
      break;
    }
  };



  return (
    <>
      <Stack>
        <BackButtons />
        <Heading size='md'>{productData.name}</Heading>
      </Stack>
      <Grid 
        templateColumns={{base : '1 fr',md : '1fr', lg :'repeat(5, 1fr)'}}
        gap={5}>
        <GridItem
          colSpan={3}
          gap={5}
          p={[1, 1, 5,]}
          spacing={5}
          borderRadius="md"
          shadow={'md'}
          mb={2}
          w='100%'
        >

          <VStack spacing={5} align={'left'} w="100%">
            <Stack >
              <Text fontWeight={'bold'}>Image Product: </Text>

              <CardFile
                onChange={onSelectFile}
                fileName={fileName}
                setFileName={setFileName}
              />
              {productData.images?.length > 0 && (

                <SimpleGrid h={'250px'} overflowY="scroll" py={3} spacing={4} columns={[2,]}>
                  {productData.images?.map((x, index,) => {

                    return (
                      <Stack key={index} borderWidth={1} position="relative" alignItems="center" justifyContent={'center'} borderRadius="md" minH={'200px'}>
                        <HStack flexDirection={['column', 'row', 'row',]} position={'absolute'} top={-2} right={-2} spacing={2}>
                          <DynamicButton action={'read'} size="sm" variant={'solid'} onClick={() => window.open(x, '_blank',)} />
                          <DynamicButton action={'delete'} size="sm" variant={'solid'} onClick={() => handleDeleteImage(x)} />
                        </HStack>
                        <Stack overflow={'hidden'}>
                          <Image src={x} objectFit="contain" w={'200px'} borderRadius={'md'} shadow="md" />
                        </Stack>
                      </Stack>

                    )
                  },)}
                </SimpleGrid>
              )}
              {productData.images?.length > 2 && (
                <Stack alignItems={'center'} justifyContent="center">
                  <BsFillArrowDownCircleFill />
                </Stack>
              )}

            </Stack>

            <Stack>
              <Spacer />
              <Text fontSize={'lg'} fontWeight={500}>Product Data</Text>
              <Divider />

            </Stack>

            <FormControl mt="5" id="Project" isRequired>
              <FormLabel>Project</FormLabel>
              <Input value={projectName} variant={'unstyled'} disabled />
            </FormControl>


            <HStack>
              <FormControl id="sku" isRequired>
                <FormLabel>Product ID:</FormLabel>
                <Input
                  type={'text'}
                  defaultValue={productId}
                  value={productId}
                  variant={'unstyled'}
                  disabled
                // onChange={(e) => setProductId(e.target.value)}
                />
              </FormControl>

              <FormControl id="sku" isRequired>
                <FormLabel>External Product ID:</FormLabel>
                <Input
                  type={'text'}
                  id={'external_productId'}
                  defaultValue={productData.external_productId}
                  value={productData.external_productId}
                  // onChange={(e) => setExternalId(e.target.value)}
                  onChange={(e) => handleDataInput(e.target.id, e.target.value)}
                />
              </FormControl>


            </HStack>

            <HStack>
              <FormControl id="sku" isRequired>
                <FormLabel>Product SKU ID:</FormLabel>
                <Input
                  type={'text'}
                  defaultValue={productData.product_skuId}
                  value={productData.product_skuId}
                  id={'product_skuId'}
                  onChange={(e) => handleDataInput(e.target.id, e.target.value)}
                />
              </FormControl>

              <FormControl id="sku" isRequired>
                <FormLabel>External Product SKU ID:</FormLabel>
                <Input
                  type={'text'}
                  defaultValue={productData.external_product_skuId}
                  value={productData.external_product_skuId}
                  id={'external_product_skuId'}
                  onChange={(e) => handleDataInput(e.target.id, e.target.value)}
                />
              </FormControl>


            </HStack>

            <HStack>

              <FormControl id="name" isRequired>
                <FormLabel>Name:</FormLabel>
                <Input
                  type="text"
                  w="100%"
                  defaultValue={productData.name}
                  value={productData.name}
                  id={'name'}
                  onChange={(e) => handleDataInput(e.target.id, e.target.value)}
                />
              </FormControl>

              <FormControl id="stocks" isRequired>
                <FormLabel>Stock Product</FormLabel>
                <Input
                  type="text"
                  defaultValue={productData.stocks}
                  value={productData.stocks}
                  id={'stocks'}
                  onChange={(e) => handleDataInput(e.target.id, e.target.value)}
                />
              </FormControl>



            </HStack>
            <FormControl id="name" isRequired>
              <FormLabel>Amount Sold:</FormLabel>
              <Input
                type="text"
                w="100%"
                defaultValue={productData.sold || '0'}
                value={productData.sold || '0'}
                id={'sold'}
                onChange={(e) => handleDataInput(e.target.id, e.target.value)}
              />
            </FormControl>
            <DynamicMultiSelectComponent
              title={'Category'}
              listOptions={dataCategory}
              selectedOptions={productData?.categoryId}
              masterData={dataCategory}
              avatar={false}
              handleAddSelection={handleAddValuesToArray}
              hanldeDeleteSelection={handleRemoveFeature}
              select={true}
              stringKey={'categoryId'}
            />
            <DynamicMultiSelectComponent
              title={'Tags'}
              selectedOptions={productData?.tags}
              avatar={false}
              handleAddSelection={handleAddValuesToArray}
              hanldeDeleteSelection={handleRemoveFeature}
              textInput={true}
              stringKey={'tags'}
            />


            <FormControl id="type" isRequired>
              <FormLabel>
                Type
              </FormLabel>
              <Select
                defaultValue={productData.type}
                value={productData.type}
                placeholder='Type your product'
                icon={<MdArrowDropDown />}
                // onChange={(e,) => setType(e.target.value,)}
                id={'type'}
                onChange={(e) => handleDataInput(e.target.id, e.target.value)}
                required
              >
                <option value='physical'>Physical</option>
                <option value='digital'>Digital</option>
              </Select>
            </FormControl>
            {productData?.type === 'physical' && 
            <FormControl id="type" isRequired>
              <FormLabel>
              Shipping
              </FormLabel>
              <Select
                defaultValue={productData.shipping}
                value={productData.shipping}
                placeholder='Select courier : JNE / OExpress'
                icon={<MdArrowDropDown />}
                // onChange={(e,) => setType(e.target.value,)}
                id={'shipping'}
                onChange={(e) => handleDataInput(e.target.id, e.target.value)}
                required
              >
                <option value='jne'>JNE</option>
                <option value='oexpress'>OExpress</option>
              </Select>
            </FormControl>
            
            
            }




            <FormControl isRequired>
              <FormLabel>
                Label
              </FormLabel>
              <Select
                defaultValue={productData.label}
                value={productData.label}
                placeholder='Label your product'
                icon={<MdArrowDropDown />}
                // onChange={(e,) => setLabel(e.target.value,)}
                id={'label'}
                onChange={(e) => handleDataInput(e.target.id, e.target.value)}
                required
              >
                <option value='enable'>Enable</option>
                <option value='disable'>Disable</option>
                <option value='hold'>Hold</option>
              </Select>
            </FormControl>

            <FormControl id="description" isRequired>
              <FormLabel>Description:</FormLabel>
              <Textarea
                minH={500}
                defaultValue={productData.description}
                value={productData.description}
                // onChange={(e,) => setDescription(e.target.value,)}
                id={'description'}
                onChange={(e) => handleDataInput(e.target.id, e.target.value)}
              />
            </FormControl>

            <Stack>
              <Spacer />
              <Text fontSize={'lg'} fontWeight={500}>Product Price</Text>
              <Divider />

            </Stack>

            <FormControl id="currency" isRequired>
              <FormLabel>Currency:</FormLabel>
              <Select
                defaultValue={productData.currency}
                value={productData.currency}
                icon={<MdArrowDropDown />}
                // onChange={(e,) => setCurrency(e.target.value,)}
                id={'currency'}
                onChange={(e) => handleDataInput(e.target.id, e.target.value)}
                required
                placeholder='Currency your product price'
              >
                <option value='IDR'>IDR</option>
                <option value='USD'>USD</option>
              </Select>
            </FormControl>

            <FormControl id="price" isRequired>
              <FormLabel>Price</FormLabel>
              <HStack alignItems={'center'} justifyContent="center">
                <Text>Rp.</Text>
                <Input
                  w={'auto'}
                  type="number"
                  size={'sm'}
                  defaultValue={productData.price}
                  value={productData.price}
                  // onChange={(e,) => setPrice(e.target.value,)}
                  id={'price'}
                  onChange={(e) => handleDataInput(e.target.id, e.target.value)}
                />
                <Spacer />
                <Text fontWeight={500}>
                  Rp.{formatFrice(parseFloat(productData.price || 0,),)}
                </Text>
              </HStack>
            </FormControl>







            <FormControl id="price" isRequired>
              <FormLabel>Price High</FormLabel>
              <HStack alignItems={'center'} justifyContent="center">
                <Text>Rp.</Text>
                <Input
                  w={'auto'}
                  type="number"
                  size={'sm'}
                  defaultValue={productData.price_high}
                  value={productData.price_high}
                  // onChange={(e,) => setPriceHigh(e.target.value,)}
                  id={'price_high'}
                  onChange={(e) => handleDataInput(e.target.id, e.target.value)}
                />
                <Spacer />
                <Text fontWeight={500}>
                  Rp.{formatFrice(parseFloat(productData.price_high || 0,),)}
                </Text>
              </HStack>
            </FormControl>


            <FormControl id="price" isRequired>
              <FormLabel>Price Low</FormLabel>
              <HStack>
                <Text>Rp.</Text>
                <Input
                  size={'sm'}
                  w={'auto'}
                  type="number"
                  defaultValue={productData.price_low}
                  value={productData.price_low}
                  // onChange={(e,) => setPriceLow(e.target.value,)}
                  id={'price_low'}
                  onChange={(e) => handleDataInput(e.target.id, e.target.value)}
                />
                <Spacer />
                <Text fontWeight={500}>
                  Rp.{formatFrice(parseFloat(productData.price_low || 0,),)}
                </Text>
              </HStack>
            </FormControl>


            <Stack>
              <Spacer />
              <Text fontSize={'lg'} fontWeight={500}>Product Discount</Text>
              <Divider />

            </Stack>


            <HStack>
              <FormControl id="weight" isRequired>
                <FormLabel>
                  type
                </FormLabel>
                <Select
                  defaultValue={productData.discount_type}
                  value={productData.discount_type}
                  icon={<MdArrowDropDown />}
                  // onChange={(e,) => setDiscountType(e.target.value,)}
                  id={'discount_type'}
                  onChange={(e) => handleDataInput(e.target.id, e.target.value)}
                  required
                  placeholder='selected currency'
                >
                  <option value='percentage'>Percentage</option>
                  <option value='value'>Value</option>
                </Select>
              </FormControl>
              <FormControl id="weight" isRequired>
                <FormLabel>
                  amount
                </FormLabel>
                <Input
                  type="number"
                  defaultValue={productData.discount_amount}
                  value={productData.discount_amount}
                  id={'discount_amount'}
                  onChange={(e) => handleDataInput(e.target.id, e.target.value)}
                // onChange={(e,) => setDiscountAmount(e.target.value,)}
                />
              </FormControl>

            </HStack>

            <Stack>
              <Spacer />
              <Text fontSize={'lg'} fontWeight={500}>Product Details</Text>
              <Divider />

            </Stack>


            <Text fontWeight={500}>Describe your Detail Product :</Text>

            <Grid gap={3} templateColumns={{ base: '1fr', md: '1fr 4fr' }}>
              <Stack>
                {details?.length > 0 && (
                  details?.map((detail, index) => (
                    <HStack key={index} align={'center'} justify={'center'}>
                      <FormControl id={`detail-key-${index}`}>
                        <FormLabel>Key:</FormLabel>
                        <Input
                          type="text"
                          placeholder="Title detail, ex: location"
                          value={detail.key}
                          onChange={(e) =>
                            handleDetailChange(index, e.target.value, detail.value)
                          }
                        />
                      </FormControl>
                      <FormControl id={`detail-value-${index}`}>
                        <FormLabel>Value:</FormLabel>
                        <Input
                          placeholder="Value detail, ex: Jakarta selatan"
                          type="text"
                          value={detail.value}
                          onChange={(e) =>
                            handleDetailChange(index, detail.key, e.target.value)
                          }
                        />
                      </FormControl>
                      <Box pt="7">
                        <IconButton
                          icon={<MdDelete />}
                          aria-label="Remove Detail"
                          onClick={() => handleRemoveDetail(index)}
                        />
                      </Box>
                    </HStack>
                  ))
                )}

              </Stack>
            </Grid>
            <Button
              variant={'outline'}
              colorScheme="blue"
              onClick={handleAddDetail}
            >
              Add Detail
            </Button>

            {warehouses?.map((warehouse, index,) => (
              <HStack key={index} align={'center'} justify={'center'}>
                <FormControl id={`warehouse-latitude-${index}`}>
                  <FormLabel>Latitude:</FormLabel>
                  <Input
                    type="text"
                    placeholder={'Enter detail latitude'}
                    value={warehouse.latitude}
                    defaultValue={warehouse.latitude}
                    onChange={(e,) =>
                      handleWarehouseChange(index, e.target.value, warehouse.longitude,)
                    }
                  />
                </FormControl>
                <FormControl id={`warehouse-longitude-${index}`}>
                  <FormLabel>Longitude:</FormLabel>
                  <Input
                    type="text"
                    placeholder={'Enter detail longitude'}
                    value={warehouse.longitude}
                    defaultValue={warehouse.longitude}
                    onChange={(e,) =>
                      handleWarehouseChange(index, warehouse.latitude, e.target.value,)
                    }
                  />
                </FormControl>
                <Box pt="7">
                  <IconButton
                    icon={<MdDelete />}
                    aria-label="Remove Detail"
                    onClick={() => handleRemoveWarehouse(index,)}
                  />
                </Box>
              </HStack>
            ),)}

            <Button
              variant={'outline'}
              colorScheme="blue"
              onClick={handleAddWarehouse}
            >
              Add Warehouse
            </Button>
            <Flex my>
              <FormControl id="is_active">
                <Switch
                  isChecked={productData.is_active}
                  onChange={(e) => handleDataInput(e.target.id, e.target.checked)}
                >
                  Publish Product
                </Switch>
                <Text color={'gray.400'} fontSize={13} py={3}>
                  The Product will active and appear to your pageview
                </Text>

              </FormControl>

              <FormControl id="is_promo">
                <Switch
                  isChecked={productData.is_promo}
                  // onChange={() => setIsPromo((prev,) => !prev)}
                  // id={'price_high'}
                  onChange={(e) => handleDataInput(e.target.id, e.target.value)}
                >
                  Promo Active:
                </Switch>
                <Text color={'gray.400'} fontSize={13} py={3}>
                  The Product will use shipping for delivery, and user/costumer
                  will be required to fill the shipping form{' '}
                </Text>

              </FormControl>
            </Flex>

            <FormControl id="modules">
              <FormLabel>Modules:</FormLabel>
              <Checkbox
                value="commerce"
                onChange={handleModulesChange}
                isChecked={modules?.includes('commerce',)}
              >
                Commerce
              </Checkbox>
              <Checkbox
                value="management"
                onChange={handleModulesChange}
                isChecked={modules?.includes('management',)}
                mx="5"
              >
                Management
              </Checkbox>

              <Checkbox
                value="marketing"
                onChange={handleModulesChange}
                isChecked={modules?.includes('marketing',)}
              >
                Marketing
              </Checkbox>

              <Checkbox
                value="product"
                onChange={handleModulesChange}
                isChecked={modules?.includes('product',)}
                mx="5"
              >
                Product
              </Checkbox>

              <Checkbox
                value="CRM"
                onChange={handleModulesChange}
                isChecked={modules?.includes('crm',)}
              >
                CRM
              </Checkbox>
            </FormControl>

            <Button isLoading={isLoading} colorScheme="blue" onClick={onSubmitProduct}>
              Update Data Product
            </Button>
          </VStack>
        </GridItem>

        <GridItem
          colSpan={2}
          gap={5}
          p={[1, 1, 5,]}
          spacing={5}
          borderRadius="md"
          shadow={'md'}
          mb={2}
          w='100%'
        >
          <ProductVariants productId={productId} />
        </GridItem>

      </Grid >
    </>
  );
}

export default ProductDetailPage;
