/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import {
  Box,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
} from '@chakra-ui/react';
import moment from 'moment';
import { useState, useRef } from 'react';
import {
  addDocumentFirebase,
  getCollectionFirebase,
  setDocumentFirebase,
} from '../../Api/firebaseApi';
import useUserStore from '../../Hooks/Zustand/Store';
import { BsFillChatDotsFill } from 'react-icons/bs';
import MessageUserAdmin from '../../Components/Chat/MessageUserAdmin';
  
function ChatPageAdminFirst({ module, companyId, projectId }) {
  const globalState = useUserStore();
  const [idMessage, setIdMessage] = useState('');
  
  const [notif, setNotif] = useState(0);
  
  
  const initialFocusRef = useRef();
  
  //   const checkSessionStorageData = () => {
  //     const storedData = sessionStorage.getItem('dataChat');
  
  //     if (storedData) {
  //       const parsedData = JSON.parse(storedData);
  //       const currentTime = new Date().getTime();
  
  //       if (currentTime < parsedData.expiration) {
  //         // Data masih berlaku, gunakan nilai uid dari sessionStorage
  
  //         console.log(parsedData);
  
  //         return true;
  //       } else {
  //         // Data sudah kadaluwarsa, hapus dari sessionStorage
  //         sessionStorage.removeItem('dataChat');
  //         return false;
  //       }
  //     } else {
  //       return false;
  //     }
  //   };
  

  const handleChatButtonClick = async () => {
    handleNewChat();
  };

  const handleNewChat = async () => {
    const collectionName = 'chat_customer';
    const docName = `${globalState?.uid}-${globalState?.currentProject}`;
    const data = {
      companyId: globalState.currentCompany,
      projectId: globalState.currentProject,
      karyawanAi: '',
      createdAt: moment(new Date()).unix(),
      user: globalState.uid
    };

    try {
      const result = await setDocumentFirebase(collectionName, docName, data, companyId);
      console.log(result); // Pesan toast yang berhasil
      setIdMessage(docName);

    } catch (error) {
      console.log('Terjadi kesalahan:', error);
    }



  };
  
  return (
    <Stack>
      <Stack bgColor={'transparent'} alignItems="flex-end">
        <Stack bottom={5} right={5} p={[1, 1, 5]}>
          <Popover
            initialFocusRef={initialFocusRef}
            placement="top"
            closeOnBlur={false}
          >
            <PopoverTrigger>
              <Stack
                alignItems={'center'}
                justifyContent="center"
                onClick={() => handleChatButtonClick()}
              >
                <Box
                  p={5}
                  borderRadius="full"
                  bgColor="blue.700"
                  _hover={{ transform: 'scale(1.05)' }}
                  transition="0.3s ease-in-out"
                  shadow={'md'}
                  cursor="pointer"
                >
                  <BsFillChatDotsFill color="white" size={30} />
                </Box>
              </Stack>
            </PopoverTrigger>
  
            <PopoverContent bgColor="blue.200" width={'500px'} mr={5}>
              <MessageUserAdmin id={idMessage} companyId={companyId} notif={notif} />
            </PopoverContent>
          </Popover>
        </Stack>
      </Stack>
    </Stack>
  );
}
  
export default ChatPageAdminFirst;
  