/* eslint-disable react/prop-types */
import { Avatar, AvatarBadge, Box, Divider, HStack, Spacer, Stack, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import { readMore } from '../../Utils/Helper'
import { stringShortener } from '../../Utils/stringUtil'

function KaryawanCard({ message, highlightChat, setHighlightChat }) {




  return (
    <Stack  onClick={() => setHighlightChat(message.id)}>
      <HStack
        align="flex-start"
        spacing={5}
        px="1"
        py="1"
        w='full'
        _hover={{
          bg: 'bg.muted',
        }}
        rounded="md"
        cursor="pointer"
        border={highlightChat === message.id ? '1px' : '0px'}
        borderradius='lg'
        borderColor='#ffd600'
       
      >
        <Box pt="1">
          <Avatar
            size="sm"
            src={message.image}
            name={message.name}
          >
            <AvatarBadge />
          </Avatar>
        </Box>
        <Stack spacing="0" fontSize="sm" flex="1" isTruncated>
          <HStack spacing="1">
            <Text fontWeight="medium" flex="1">
              {message.name} - {readMore(message.title, 25)}
            </Text>
            <Spacer />
            <Text color="fg.subtle" fontSize="xs">
              {message.updatedAt}
            </Text>
          </HStack>
          <Box color="fg.subtle" isTruncated>
            <Text noOfLines={1}>
              {stringShortener(message.description, 35)}

            </Text>
          </Box>
        </Stack>

      </HStack>
      <Divider />

    </Stack>
  )
}

export default KaryawanCard