import {
  FcConferenceCall,
  FcSettings,
  FcBriefcase,
  FcCollaboration,
  FcBusinessContact,
  FcReading,
  FcCircuit,
  FcFlashOn,
  FcMoneyTransfer,
  FcReadingEbook,
  FcContacts,
  FcBusinessman,
  FcRefresh,
  FcAdvance,
  FcInternal,
  FcManager,
  FcTodoList,
  FcSpeaker,
  FcAssistant,
  FcHome,
  FcShipped,
  FcScatterPlot,
  FcLike,
  FcBusiness,
} from 'react-icons/fc';


export const data = [

  {
    name: 'Chat',
    icon: FcCollaboration,
    // link: "https://productivity.deoapp.com/",
    link: '/chats',
    description:
      'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
    submenu: [
      {
        name: 'Costumer Service',
        icon: FcSpeaker,
        link: '/chat',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
      {
        name: 'Admin',
        icon: FcManager,
        link: '/chat/admin',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
      {
        name: 'Example Chat',
        icon: FcTodoList,
        link: '/chat-user/chat/8NCG4Qw0xVbNR6JCcJw1/8NCG4Qw0xVbNR6JCcJw1',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
      {
        name: 'Example Admin Chat',
        icon: FcTodoList,
        link: '/chat-admin/chat/8NCG4Qw0xVbNR6JCcJw1/8NCG4Qw0xVbNR6JCcJw1',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
      {
        name: 'KaryawanAI',
        icon: FcBusiness,
        link: '/karyawanAi',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
    ],
  },


  {
    name: 'Customer',
    icon: FcBusinessContact,
    link: '/customer',
    description:
      'All-in-one HRIS solution that simplifies HR tasks, enhances compliance, and provides valuable data-driven insights for strategic decision-making.',
    submenu: [
      {
        name: 'Contacts',
        icon: FcContacts,
        link: '/relations/contacts',
      },
      {
        name: 'Sales Pipeline',
        icon: FcInternal,
        link: '/relations/sales-pipeline',
        submenu: [
          {
            name: 'Acquisition (sales)',
            icon: FcBusinessman,
            link: '/relations/acquisition',
          },
          {
            name: 'Retention (After Sales)',
            icon: FcAssistant,
            link: '/relations/retention',
          },
        ],
      }
    ],
  },

  {
    name: 'Product',
    icon: FcCircuit,
    link: '/product',
    description:
      'All-in-one HRIS solution that simplifies HR tasks, enhances compliance, and provides valuable data-driven insights for strategic decision-making.',
    submenu: [

      {
        name: 'Products',
        icon: FcLike,
        link: '/products-fix',

      },

      // {
      //   name: 'Products Variants',
      //   icon: FcLike,
      //   link: '/variants',

      // },

      {
        name: 'Category',
        icon: FcScatterPlot,
        link: '/category',

      },


      {
        name: 'Shipping',
        icon: FcShipped,
        link: '/shipping',

      },
      {
        name: 'Warehouse',
        icon: FcHome,
        link: '/warehouse',
      },

    ],
  },

  {
    name: 'Orders',
    icon: FcMoneyTransfer,
    link: '/order',
    submenu: [
      {
        name: 'Orders',
        icon: FcMoneyTransfer,
        link: '/orders',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
      {
        name: 'Subscription Billing',
        icon: FcRefresh,
        link: '/billing/subscribtion',
      },
      {
        name: 'One Time Billing',
        icon: FcAdvance,
        link: '/billing/one-time',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
      {
        name: 'Per Transaction Billing',
        icon: FcInternal,
        link: '/billing/transaction',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },

    ],
  },

  {
    name: 'Integration',
    icon: FcFlashOn,
    link: '/integration',
    description:
      'All-in-one HRIS solution that simplifies HR tasks, enhances compliance, and provides valuable data-driven insights for strategic decision-making.',
    submenu: [

      {
        name: 'Basic Knowledge',
        icon: FcReading,
        link: '/ai/knowledge',
      },
      {
        name: 'Product Knowledge',
        icon: FcReadingEbook,
        link: '/ai/knowledge/product',
      },
      {
        name: 'Knowledge Categories',
        icon: FcBriefcase,
        link: '/ai/knowledges/categories',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },

      {
        name: 'Integration Whatsapp',
        icon: FcBriefcase,
        link: '/integration/whatsapp',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },

      {
        name: 'Integration Web-Chat',
        icon: FcBriefcase,
        link: '/integration/web-chat',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
    ],
  },


  {
    name: 'Team',
    icon: FcCollaboration,
    link: '/management/configuration',
    description:
      'All-in-one HRIS solution that simplifies HR tasks, enhances compliance, and provides valuable data-driven insights for strategic decision-making.',
    submenu: [

      {
        name: 'Settings',
        icon: FcSettings,
        link: 'https://recruitment-deoapp.firebaseapp.com/',
        submenu: [
          {
            name: 'Project',
            icon: FcBriefcase,
            link: '/configuration/project',
            description:
              'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
          },

          {
            name: 'Users',
            icon: FcConferenceCall,
            link: '/hris/users',
            description:
              'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
          },


        ],
      },
    ],
  },


];
