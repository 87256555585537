import { Box, Button,useColorMode,useToast,Flex, Heading,Stack, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Table, TableContainer, Tbody, Td, Text,Th, Thead, Tr, useDisclosure } from '@chakra-ui/react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { addDocumentFirebaseV3, deleteDocumentFirebase } from '../../../Api/firebaseApi'
import { clientTypessense } from '../../../Api/Typesense'
import useUserStore from '../../../Hooks/Zustand/Store'
import { readMore } from '../../../Utils/Helper'
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md'
import DynamicButton from '../../../Components/Buttons/DynamicButton';

function KnowledgePage() {
  const [data,setData]=useState()
  const { colorMode } = useColorMode()
  const [saveLoader,setSaveLoader]=useState(false)
  const [newTitle,setNewTitle]=useState()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const globalState =useUserStore()
  const navigate = useNavigate()
  const toast = useToast()
  const handleAddKnowledge = async() => {
    //create newfilesId with type=knowledge
    setSaveLoader(true)
    try {
      const newData = {
        title:newTitle,
        type:'knowledge',
        status:'draft',
        companyId:globalState.currentCompany,
        projectId:globalState.currentProject
      }
      const res = await addDocumentFirebaseV3('files',newData,globalState.currentProject)
      navigate(res)
    } catch (error) {
      console.log(error.message)
    }
    setSaveLoader(false)

  }

  const deleteData = async (id, index) => {
    // eslint-disable-next-line no-alert
    const confirmDelete = window.confirm('If you delete this data you can not Undo');
    if (confirmDelete) {
      await deleteDocumentFirebase('files', id)
      getData()
      toast({
        title: 'Admin',
        description: 'Success delete banner',
        status: 'success',
      })
    }
  }

  const getData= async () => {
    const searchParameters = {
      q: '*',
      //   query_by: 'email',
      filter_by: `type:knowledge && projectId:${globalState.currentProject}`,
    };
    await clientTypessense
      .collections('files')
      .documents()
      .search(searchParameters)
      .then((x) => {
        const newData = x.hits.map((y) => { return { ...y.document } })
        // console.log(newData,globalState.currentProject)
        setData(newData)
      });
  }

  useEffect(() => {
    getData()
  
    return () => {
      setData()
    }
  }, [globalState.currentProject])
  
  return (
    <Stack p={[0, 5, 7]} h='100vh' overflow='auto'>
      <Box bgColor={colorMode === 'light' ? 'white' : ''} p="5" borderRadius="md" shadow="base">
        <HStack mx='5'>
          <Heading >Knowledge</Heading>
          <Spacer/>
          <Input type='text' maxW='sm' placeholder='Search knowledge'/>
          <Button colorScheme='green' onClick={onOpen}>+</Button>
        </HStack>
        <TableContainer
          border='1px'
          borderRadius='sm'
          borderColor={colorMode === 'light' ? 'gray.100' : 'gray.600'}
          boxShadow='base'
          mt='5'
        >
          <Table variant='striped'>
            <Thead >
              <Tr >
                <Th>Title</Th>
                <Th>Category</Th>
                <Th>Status</Th>
                <Th>Stats</Th>
                <Th>Last Update</Th>
                <Th textAlign='center'>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.slice(0,7).map((x,i) =>
                <Tr key={i}>
                  <Td>
                    <HStack>
                      <Text>{readMore(String(x.title),20)}</Text>
                      {/* <Tooltip label={x.title}>
                      <InfoIcon/>
                    </Tooltip> */}
                    </HStack>
                  </Td>
                  <Td>{x.category}</Td>
                  <Td>{x.status}</Td>
                  <Td>{x.statistic}</Td>
                  <Td>{moment.unix(x.lastUpdate).fromNow()}</Td>
                  <Td>
                    <HStack justifyContent="center">
                      <DynamicButton
                        size="sm"
                        action="read"
                        onClick={() => navigate(`${x.id}`)}
                      />
                      <DynamicButton
                        size="sm"
                        action="delete"
                        // isLoading={loadingStates[i]}
                        onClick={() => deleteData(x.id, i)}
                      />
                    </HStack>
                    {/* <HStack>
                      <Button colorScheme='blue' onClick={() => navigate(`${x.id}`)}>View</Button>
                    </HStack> */}
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
        <Modal isOpen={isOpen} onClose={onClose} >
          <ModalOverlay/>
          <ModalContent>
            <ModalHeader>Add Knowledge</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>Title</Text>
              <Input type='text' onChange={(e) => setNewTitle(e.target.value)}/>
            </ModalBody>

            <ModalFooter>
              <Button isLoading={saveLoader} colorScheme='blue' mr={3} onClick={() => handleAddKnowledge()}>
              Add
              </Button>
              {/* <Button variant='ghost'>Secondary Action</Button> */}
            </ModalFooter>
          </ModalContent>
        </Modal>
        <HStack justifyContent={'center'} spacing={5} pt={4}>
          <Button
            colorScheme="blue"
            size={'sm'}
          >
            <HStack color="white" spacing={1} pr={3}>
              <MdNavigateBefore size={23} />
              <Text>Prev</Text>
            </HStack>
          </Button>

          <Button
            colorScheme="blue"
            size={'sm'}
          >
            <HStack color="white" spacing={1} pl={3}>
              <Text>Next</Text>
              <Box>
                <MdNavigateNext size={23} />
              </Box>
            </HStack>
          </Button>
        </HStack>
      </Box>
    </Stack>
  )
}

export default KnowledgePage