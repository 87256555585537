/* eslint-disable react/prop-types */
import { Avatar, Tooltip, AvatarBadge, Badge, Box, Divider, HStack, Icon, Spacer, Stack, Text } from '@chakra-ui/react'
import moment from 'moment'
import React from 'react'
import { BsWhatsapp } from 'react-icons/bs'
import { FaBell, FaRegAngry, FaRegSmile } from 'react-icons/fa'
import { readMore } from '../../Utils/Helper'

function ChatCard({ data, setActiveChat }) {
  return (
    <Stack >
      <HStack cursor='pointer' onClick={() => setActiveChat(data?.user)} align="flex-start" gap="3" p='1' _hover={{ bg: 'bg.muted' }} >
        <Box pt="1">
          <Avatar size="sm" src={data?.image} name={data?.name ? data.name : data.user}>
            {data?.sentiment === 'negative' ? (
              <AvatarBadge boxSize="1.5em" bg="red.500" color={'white'} >
                <FaRegAngry size={20}  />
              </AvatarBadge>
            ) : (
              <AvatarBadge boxSize="1.5em" bg="green.500" color={'white'} >
                <FaRegSmile size={20} />
              </AvatarBadge>
            )}
          </Avatar>
        </Box>
        <Stack spacing={1} fontSize="sm" flex="1" isTruncated>
          <HStack spacing={3}>
            <Text fontWeight="bold" flex="1">
              {readMore(data?.name ? data?.name : data?.user, 20)}
            </Text>
           
            {data?.status === 'unread' && (
              <Stack borderRadius={'full'} alignItems='center' justifyContent={'center'} w='20px' h={'20px'} bgColor='red'>
                <FaBell color='white' />
              </Stack>
            )}


          </HStack>

          <Box color="fg.subtle" isTruncated>
            {data?.message}
          </Box>
          <HStack>
            <Badge colorScheme="red" fontSize='xx-small'>
              Score -10
            </Badge>
            <Spacer />
            <Text color="fg.subtle" fontSize="xx-small">
              {moment(data?.createdAt * 1000).fromNow(true)}
            </Text>

            <Icon as={BsWhatsapp} color='green' />


            <Tooltip
              label={data?.lastUpdatedBy?.email}
              aria-label="Name"
            >
              <Avatar
                size={'xs'}
                name={data?.lastUpdatedBy?.email}
                image={data?.lastUpdatedBy?.uid}
              />
            </Tooltip>

          

          </HStack>
        </Stack>
      </HStack>
      <Divider />
    </Stack>
  )
}

export default ChatCard