/* eslint-disable no-useless-escape */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import {
  Avatar,
  AvatarBadge,
  Box,
  Button,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spacer,
  Spinner,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import {
  addDoc,
  collection,
  getCountFromServer,
  increment,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import {
  addDocumentFirebase,
  getSingleDocumentFirebase,
  setDocumentFirebase,
  updateDocumentFirebase,
} from '../../Api/firebaseApi';
import { db } from '../../Config/firebase';
import useUserStore from '../../Hooks/Zustand/Store';
import parse from 'html-react-parser';
import DropboxUploader from '../DropBox/DropboxUploader';
import { MdOutlinePermMedia } from 'react-icons/md';
import { decryptToken } from '../../Utils/encrypToken';
import DynamicButton from '../Buttons/DynamicButton';
import { FaWhatsapp } from 'react-icons/fa';
import { BsMicFill, BsPaperclip } from 'react-icons/bs';
import { getSentiment } from '../../Api/OpenAi';

function MessageUserAdmin({ id, companyName, notif }) {

  const globalState = useUserStore();
  const messagesEndRef = useRef(null)



  const [isModalOpen, setModalOpen] = useState(false);
  const [shareLink, setShareLink] = useState('');
  const [value, setValue] = useState('');
  const [dataCustomer, setDataCustomer] = useState('')

  const [accessTokenDb, setAccessTokenDb] = useState('');
  const toast = useToast()

  const [messageLoading, setMessageLoading] = useState(false)

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const itemsPerPage = 5;

  const apiKey = `Bearer ${process.env.REACT_APP_FLOWISE}`

  const [chat, setChat] = useState('');
  const [chatData, setChatData] = useState([]);

  const handleWhatsapp = () => {
    const message = encodeURIComponent('Hi Admin Deoapp for Enterprise,\n\nSilahkan tinggalkan pesanmu, dan Team Support kami akan segera membalas 😊');
    const source = encodeURIComponent(window.location.href);
    const url = `https://api.whatsapp.com/send?phone=6281241678382&text=${message}%0A%0ASource:%20${source}`;
    window.open(url, '_blank');
  }

  const scrollToBottom = () => {
    // console.log(messagesEndRef.current)
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
  }


  const handleShareLinkChange = (x) => {
    if (x !== '') {
      setShareLink({ link: x.link, type: x.type });
      const { link, type } = x;
      let htmlContent = '';

      if (type === 'image') {
        htmlContent = `<p><img src="${link}" alt="Image" width="500px" /></p><br/> <p>file: <a href=${link} rel="noopener noreferrer" target="_blank">${JSON.stringify(
          link
        )}</a></p>`;
      } else if (type === 'audio') {
        htmlContent = `<p><iframe class="ql-video" frameborder="0" allowfullscreen="true" src=${link}></iframe></p><br/> <p>file: <a href=${link} rel="noopener noreferrer" target="_blank">${JSON.stringify(
          link
        )}</a></p>`;
      } else if (type === 'video') {
        htmlContent = `<p><iframe class="ql-audio" frameborder="0" allowfullscreen="true" src=${link}></iframe></p><br/> <p>file: <a href=${link} rel="noopener noreferrer" target="_blank">${JSON.stringify(
          link
        )}</a></p>`;
      } else {
        htmlContent = `<p>file: <a href=${link} rel="noopener noreferrer" target="_blank">${JSON.stringify(
          link
        )}</a></p><br/> `;
      }

      setValue((prevContent) => prevContent + ` ${htmlContent}`);
    }
  };

  const getDataCustomer = async () => {
    try {
      const res = await getSingleDocumentFirebase('chat_customer', id)
      setDataCustomer(res)
    } catch (error) {
      console.log(error, 'ini error')
    }
  }

  const getDataChat = async () => {
    if (!id) return;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const limitValue = startIndex + itemsPerPage;
    const q = query(
      collection(db, 'chat'),
      where('projectId', '==', globalState.currentProject),
      // where('user', '==', globalState.uid),
      where('chatId', '==', id),
      orderBy('createdAt', 'desc'),
      limit(limitValue)
    );

    return new Promise((resolve, reject) => {
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const chats = [];
        querySnapshot.forEach((doc) => {
          chats.push(doc.data());
        });
        setChatData(chats); // Set the chat data once outside the loop

        const collectionRef = collection(db, 'chat');
        const countQuery = query(
          collectionRef,
          where('projectId', '==', globalState.currentProject),
          // where('user', '==', globalState.uid),
          where('chatId', '==', id),
        );

        getCountFromServer(countQuery)
          .then((snapshot) => {
            const assessmentLength = snapshot.data().count;
            setTotalPages(Math.ceil(assessmentLength / itemsPerPage));
            resolve(); // Resolve the promise when both data and count are fetched
          })
          .catch((error) => {
            reject(error); // Reject the promise in case of an error
          });
      });

      // Return the unsubscribe function to clean up the listener when needed
      return unsubscribe;
    });

  };

  const getAccessToken = async () => {
    try {
      const result = await getSingleDocumentFirebase('token', 'dropbox');
      const resultData = decryptToken(result?.access_token);
      setAccessTokenDb(resultData);
    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    getDataCustomer()
    getDataChat();
    getAccessToken();

    return () => {
      setChatData([]);
      setValue('');
    };
  }, [id, currentPage]);

  const updateChatCustomer = async (data) => {


    const message = {
      message: data?.message,
      characters: data?.characters,
      createdAt: moment().unix(),
      lastUpdated: new Date(),
      status: 'unread',
      sentiment: data?.sentiment
    }
    try {
      await updateDocumentFirebase('chat_customer', `${globalState.uid}-${globalState.currentProject}`, message)
    } catch (error) {
      console.log(error.message)
    }
  }


  const handleChat = async () => {

    setMessageLoading(true);
    setChat('');

    const sentimentData = await getSentiment(chat)
    console.log(sentimentData,'ini sentiment data')


    const insertData = {
      companyId: globalState.currentCompany,
      projectId: globalState.currentProject,
      message: chat,
      characters: chat.length,
      type: 'human',
      chatId: id,
      user: globalState?.uid,
      status: 'unread',
      medium: 'web',
      createdAt: moment().unix(),
      lastUpdated: new Date(),
      sentiment: sentimentData.sentiment,
      token: sentimentData.totalTokens
    };

    try {
      const userDoc = await addDoc(collection(db, 'chat'), insertData);

      if (userDoc) await updateChatCustomer(insertData);
      if (dataCustomer.type === 'AI') {
        // querying AI
        const query = {
          'question': chat,
          overrideConfig: {
            'sessionId': `${globalState.uid}-${globalState.currentProject}`
          }
        };
        const response = await fetch('https://flowise.deoapp.com/api/v1/prediction/b1f11238-255e-4f7f-b47e-317ece72032c',
          {
            headers: {
              Authorization: apiKey,
              'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify(query),
          }
        );
        const result = await response.json();

        const characterOri = result?.text?.length

        // insert to firestore AI
        const aiReply = {
          companyId: globalState.currentCompany,
          projectId: globalState.currentProject,
          message: result.text,
          // characters: result.text.length,
          characters: characterOri,
          chatId: id,
          type: 'AI',
          user: 'AI',
          createdAt: moment().unix(),
          lastUpdated: new Date(),
          status: 'read',
        };
        const aiDoc = await addDoc(collection(db, 'chat'), aiReply);

        if (aiDoc) await updateChatCustomer(aiReply);
      }



      setMessageLoading(false);
      // scrollToBottom();

      // return result;

      //   console.log(result);

      // setUserNotif(0);
    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
      setChat('');
    } finally {
      setChat('');
      setMessageLoading(false)
    }

  };


  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
    scrollToBottom()
    //buatkan fungsi lihat lebih banyak disiini menggunakan table contact has more yang sudah ad a
  }


  function openModal() {
    setModalOpen(true);
  }

  function closeModal() {
    setModalOpen(false);
  }

  return (
    <Stack>
      <Stack>
        <Stack p={3} bgColor="white" color='black'>
          <HStack spacing={3}>
            <Avatar size="sm">
              <AvatarBadge boxSize="1.25em" bg="green.500" />
            </Avatar>
            <Text textTransform={'capitalize'} fontWeight={500}>
              Admin
            </Text>
            <Spacer />
            {notif === 0 ? null : (
              <Text color={'red.400'} fontWeight={500} fontSize={'sm'}>
                {notif} New Notification
              </Text>
            )}
            <DynamicButton action={'custom'} icon={FaWhatsapp} color="green" onClick={handleWhatsapp} size="sm" variant={'solid'} />
          </HStack>
        </Stack>
        {currentPage < totalPages ? (
          <Stack>
            <Button onClick={handleLoadMore}>See more</Button>
          </Stack>
        )
          :
          (
            <></>
          )}

        <Stack overflowY="scroll" h={'400px'} px="5" py="4"
          direction={'column-reverse'}
        >

          {chatData.length > 0 && (
            <>
              {chatData?.map((x, i) =>
                <Stack key={i} m='1'

                >

                  <HStack gap="5">

                    {x.user === globalState?.uid ? <Spacer /> : <></>}

                    <Stack spacing="1" bgColor={x.user !== globalState?.uid ? '#1F2C33' : '#015C4B'} maxW='xl' borderRadius='lg' p='2' textColor='white'>
                      <HStack spacing="2">
                        <Spacer />
                        <Box color="fg.muted" lineHeight="tall" >
                          <div dangerouslySetInnerHTML={{ __html: x.message.replace(/(?:\r\n|\r|\n)/g, '<br>') }} />
                          <HStack>
                            <Spacer />
                            <Text fontSize='xx-small' textAlign={x.user !== globalState?.uid ? 'end' : 'start'}>{moment.unix(x.createdAt).fromNow()}</Text>
                            {x.user !== globalState?.uid ?
                              <Text fontSize='xx-small' textAlign='end'>By {x.user}</Text>
                              : <></>
                            }
                          </HStack>
                        </Box>
                      </HStack>
                    </Stack>

                    {x.user !== globalState?.uid ? <Spacer /> : <></>}

                  </HStack>
                </Stack>)}
              <div ref={messagesEndRef} />
            </>
          )}

        </Stack>
        {value !== '' && (
          <Stack
            bgColor={'blackAlpha.700'}
            overflowY={'scroll'}
            alignItems="center"
            justifyContent={'center'}
            h="full"
            position="absolute"
            p={10}
          >
            <Stack fontSize="2xs">
              {parse(value, {
                replace: (domNode) => {
                  if (domNode.type === 'text') {
                    const textWithLinksReplaced = domNode.data.replace(
                      /(\b(?:https?:\/\/|www\.)[^\s]+)/g,
                      (match) => {
                        const url = match.startsWith('http')
                          ? match
                          : `https://${match}`;
                        return `<a href="${url}" target="_blank">${match}</a>`;
                      }
                    );
                    return parse(textWithLinksReplaced);
                  }
                },
              })}
            </Stack>
          </Stack>
        )}
        <Stack shadow={3}>
          <HStack
            p="2"
            alignItems={'center'}
            space={3}
            justifyContent={'space-evenly'}
          >

            <InputGroup>
              <InputLeftElement>
                <IconButton
                  size="sm"
                  aria-label="Record voice"
                  icon={<Icon as={BsMicFill} fontSize="md" />}
                  variant="ghost"
                />
              </InputLeftElement>
              <Input
                placeholder="Send a message..."
                fontSize="sm"
                value={chat}
                isDisabled={messageLoading}
                onChange={(e) => setChat(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    // handleSave();
                    // ChatComponent()
                    handleChat()
                    // console.log('enter')
                  }
                }}
              />
              <InputRightElement>
                {messageLoading ?
                  <Spinner />
                  :
                  <IconButton
                    size="sm"
                    variant="ghost"
                    aria-label="Send message"
                    onClick={openModal}
                    icon={<Icon as={BsPaperclip} fontSize="md" />}
                  />
                }

              </InputRightElement>
            </InputGroup>
          </HStack>
        </Stack>
      </Stack>

      <DropboxUploader
        accessTokenDb={accessTokenDb}
        isActive={isModalOpen}
        onClose={closeModal}
        parentPath={`/${companyName || 'edrus'}/chat`}
        shareLink={shareLink}
        setShareLink={handleShareLinkChange}
      />
    </Stack>
  );
}

export default MessageUserAdmin;
