import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Select,
  Spacer,
  Text,
  Stack,
  useToast,
  Textarea,
  Grid,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getSingleDocumentFirebase,
  updateDocumentFirebase,
} from '../../Api/firebaseApi';
import BackButtonComponent from '../../Components/Buttons/BackButtons';
import useUserStore from '../../Hooks/Zustand/Store';
import MapComponent from '../../Components/Map/MapComponent';
import AddressIndex from '../../Components/Address/AddressIndex';
import DynamicButton from '../../Components/Buttons/DynamicButton';

function WarehouseDetailPage() {
  const globalState = useUserStore();
  const [data, setData] = useState();
  const [input, setInput] = useState({});
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');


  const [isLoading, setIsLoading] = useState(false);


  const params = useParams();
  const toast = useToast();
  const navigate = useNavigate();



  const getData = async () => {
    try {
      const dataRes = await getSingleDocumentFirebase('warehouses', params.id);
      setData(dataRes);
      setLongitude(dataRes?.geolocation?.longitude);
      setLatitude(dataRes?.geolocation?.latitude);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        duration: 3000,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const saveData = async () => {

    setIsLoading(true);
    try {
      await updateDocumentFirebase(
        'warehouses',
        params.id,
        { ...input, geolocation: { latitude: latitude, longitude: longitude } },
      );

      toast({
        status: 'success',
        duratio: 5000,
        description: 'Sucess edit office',
      });

      navigate(-1);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        duration: 3000,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
      // window.location.reload();
    }
  };

  const onLatitude = (latitude) => {
    setLatitude(latitude);
  };

  const onLongitude = (longitude) => {
    setLongitude(longitude);
  };

  useEffect(() => {
    getData();
    return () => {
      setData([]);
    };
  }, []);

  return (
    <Stack p={[1, 1, 5]} spacing={5}>
      <HStack>
        <BackButtonComponent />
        <Spacer />
        <Box>
          <Heading size={'lg'}>Warehouse</Heading>
          <Text fontSize="3xs">ID: {params.id}</Text>
        </Box>
      </HStack>

      <Grid templateColumns={{ base: '1fr', md: '1fr 1fr' }} w={'100%'} gap={5}>
        <Stack spacing={5}  p={5} borderRadius="md" shadow={'base'}>


          <FormControl >
            <FormLabel>Warehouse Name</FormLabel>
            <Input
              type="text"
              placeholder="Office name"
              defaultValue={data?.name}
              onChange={(e) => setInput({ ...input, name: e.target.value })}
            />
          </FormControl>

          <FormControl >
            <FormLabel>Warehouse Description</FormLabel>
            <Textarea
              type="text"
              defaultValue={data?.description}
              placeholder="Description"
              onChange={(e) =>
                setInput({ ...input, description: e.target.value })
              }
            />
          </FormControl>

          <FormControl >
            <FormLabel>External ID</FormLabel>
            <Input
              type="text"
              defaultValue={data?.externalId}
              placeholder="External ID"
              onChange={(e) =>
                setInput({ ...input, externalId: e.target.value })
              }
            />
          </FormControl>

          <FormControl >
            <FormLabel>Status</FormLabel>
            <Select
              value={input?.status || data?.status}
              onChange={(e) => setInput({ ...input, status: e.target.value })}
            >
              <option value="enable">Enable</option>
              <option value="disable">Desable</option>
            </Select>
          </FormControl>

          <FormControl >
            <FormLabel>Office Phone Number</FormLabel>
            <Input
              isDisabled={
                params.id !== 'new' && globalState.roleCompany !== 'owner'
                  ? true
                  : false
              }
              type="number"
              defaultValue={data?.phone}
              placeholder="Phone Number"
              onChange={(e) => setInput({ ...input, phone: e.target.value })}
            />
          </FormControl>
        
        
          <DynamicButton
            isLoading={isLoading}
            action={'create'}
            title='Save'
            variant={'solid'}
            onClick={() => saveData()}
          />
        </Stack>

        <Box p={5} borderRadius="md" shadow={'base'}>
          <Stack spacing={3}>
            <AddressIndex warehouseData={data} refreshData={() => getData()} />
            <Stack >
              <Text fontWeight={500}>Location (for geolocation)</Text>
              <Box >
                <MapComponent onLongitude={onLongitude} onLatitude={onLatitude} />
              </Box>
              <HStack>
                <FormControl>
                  <FormLabel>Latitude</FormLabel>
                  <Input
                    type="text"
                    defaultValue={latitude}
                    onChange={(e) => setLatitude(e.target.value)}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Longitude</FormLabel>
                  <Input
                    type="text"
                    defaultValue={longitude}
                    onChange={(e) => setLongitude(e.target.value)}
                  />
                </FormControl>
              </HStack>
            </Stack>
          </Stack>
        </Box>
      </Grid>
    </Stack>
  );
}

export default WarehouseDetailPage;
