import {
  Box,
  Button,
  Flex,
  Image,
  Stack,
  Text,
  Icon,
  ButtonGroup,
  Center,
  Spacer,
  AspectRatio,
  Divider,
  UnorderedList,
  ListItem,
  keyframes,
  SimpleGrid,
  Heading,
  Container,
  HStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { AiOutlineRobot, AiOutlineUser } from 'react-icons/ai';
import { FaDiscord } from 'react-icons/fa6';
import PricingComponent from '../../Components/Pricing/PricingComponent';
import OrganizationChartComponent from './OrganizationChartComponent';
import { getSingleDocumentFirebase } from '../../Api/firebaseApi';


const HomeMainPage = () => {
  const navigate = useNavigate()

  const [organizationConfig,setOrganzationConfig]=useState()
  const organisasi = [
    {department:'marketing', divisi:[
      {name:'digital marketing', subDivisi:['facebook ads','google ads','tiktok ads']},
      {name:'kol', subDivisi:['kol instagram','kol tiktok']},
      {name:'social media', subDivisi:['copywriter','story telling script']},
    ]},
    {department:'finance', divisi:[
      {name:'acconting', subDivisi:['coa','google ads','tiktok ads']},
      {name:'finance', subDivisi:['kol instagram','kol tiktok']},
      {name:'tax', subDivisi:['kol instagram','kol tiktok']},
    ]},
    {department:'creative', divisi:[
      {name:'design', subDivisi:['graphic design','tiktok ads']},
      {name:'copywriting', subDivisi:['headline','website copy','seo writer','story telling script writer']},
      {name:'production', subDivisi:['video editor','image editor','photographer']},
    ]},
  ]

  const faq = [
    {q:'Apa itu karyawan.ai?',a:'karyawan.ai adalah software AI yang di latih dengan data yang ada di Indonesia. Software ini dapat membantu mempercepat produktifitas sebuah perusahaan'},
    {q:'Apa yang harus dilakukan untuk menggunakan karyawan.ai ?',a:'Kamu dapat langsung subscribe gaji karyawan.ai'},
    {q:'Apa kelebihan menggunakan karyawan.ai?',a:'Salah satu keunggulan karyawan.ai adalah semua data kamu akan masuk ke dalam "KNOWLEDGE" karyawan.ai sehingga kamu dapat bertanya kepada karyawan.ai selayaknya kamu bertanya kepada karyawan kamu.'},
    {q:'Apa kah harus paham teknologi banget untuk menggunakan karyawan.ai?',a:'karywan.ai di design seperti whatsapp chat sehingga user dengan kemampuan teknologi yang tidak seberapa tetap dapat menggunakan karyawa.ai dengan mudah.'},
    {q:'Apakah ada pelatihan dalam menggunakan karyawan.ai?',a:'Kami melakukan training setiap minggunya untuk menggunakan karyawan.ai'},
  ]
  const communityVideo = [
    {
      title:'Flowise AI Tutorial #1 - Introduction & Setup',
      image:'https://www.youtube.com/embed/tD6fwQyUIJE',
    },
    {
      title:'How to Build an AI Document Chatbot in 10 Minutes',
      image:'https://www.youtube.com/embed/riXpu1tHzl0' 
    },
    {    title:'Flowise is an open source no-code UI visual tool to build 🦜🔗LangChain applications',
      image:'https://www.youtube.com/embed/CovAPtQPU0k' 
    },
    {    title:'Flowise: No Code ChatBot Building Platform: LangChain',
      image:'https://www.youtube.com/embed/Q7_dKkosJY4' 
    },
    { title:'Build a No-Code Chat-with-PDF LangChain app using Flowise and Bubble',
      image:'https://www.youtube.com/embed/kOwmPe8aLAA' 
    },
    { title:'Flowise AI: Create LLM Apps with NO Code - FREE Opensource LangChain Apps',
      image:'https://www.youtube.com/embed/tD6fwQyUIJEhttps://www.youtube.com/embed/kvvBUz0q-6I' 
    },
  ]

  const imageLogo = [
    {
      image: 'https://cdn.msgsndr.com/location%2FO05Yo0bfX94TBxoHHhla%2Fimages%2Fbcbf08ce-4e67-4305-b634-80c46ef1ac32.png?alt=media'
    },
    {
      image: 'https://d2jnbxtr5v4vqu.cloudfront.net/filemanager/Maul/logo-org-importir1-2021_10_05_09_58_32.png?v=1.0'
    },
    {
      image: 'https://klamby.id/cdn/shop/files/Logo_klamby_baru_banget.png?v=1643345083'
    },
    {
      image: 'https://ditusi.co.id/assets/images/logo-white.png'
    },
       
    {
      image: 'https://eklesclinic.com/wp-content/uploads/2023/02/LOGO-EKLES-Large-New.png'
    },
    {
      image: 'https://d2jnbxtr5v4vqu.cloudfront.net/filemanager/Maul/logo-org-importir1-2021_10_05_09_58_32.png?v=1.0'
    },
    {
      image: 'https://klamby.id/cdn/shop/files/Logo_klamby_baru_banget.png?v=1643345083'
    },
    {
      image: 'https://ditusi.co.id/assets/images/logo-white.png'
    },
    {
      image: 'https://cdn.msgsndr.com/location%2FO05Yo0bfX94TBxoHHhla%2Fimages%2Fbcbf08ce-4e67-4305-b634-80c46ef1ac32.png?alt=media'
    },
    {
      image: 'https://d2jnbxtr5v4vqu.cloudfront.net/filemanager/Maul/logo-org-importir1-2021_10_05_09_58_32.png?v=1.0'
    },
    {
      image: 'https://klamby.id/cdn/shop/files/Logo_klamby_baru_banget.png?v=1643345083'
    },
    {
      image: 'https://ditusi.co.id/assets/images/logo-white.png'
    },
  ]

  const animationKeyFrames = keyframes`
        from {
            transform: translateX(0)
        }
        to {
            transform: translateX(-100%)
    }`
  const animation = `${animationKeyFrames} 30s infinite linear`;


  const linearColor ='linear(to-r, #FFA500, #ffd600)'

  const getConfig = async() => {
    try {
      const res = await getSingleDocumentFirebase('config','karyawanAi')
      console.log(res.divisi)
      setOrganzationConfig(res.divisi)
    } catch (error) {
      console.log(error.message)
    }
  }

  useEffect(() => {
    getConfig()
    return () => {
      setOrganzationConfig()
    }

  },[])
  return (
    <Stack bgColor={'rgb(21,23,25)'}
    >
      <Container  maxW='container.lg'  mt='1'>

        <HStack>
          <Image
            // w={'10em'}
            h={'2.9em'}
            src="https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/img-fzl/logo/2.png"
          />
          <Spacer/>
          <ButtonGroup variant={'none'} textColor={'white'} fontSize={5}>
	
            <Button
              variant={'blue'}
              rounded={'full'}
              bgGradient={linearColor}
              leftIcon={<AiOutlineRobot />}
              color='black'
              onClick={() => navigate('/login')}
            >
								Login
            </Button>
            <Button
              variant={'blue'}
              rounded={'full'}
              // colorScheme='green'
              // bgGradient={linearColor}
              leftIcon={<AiOutlineUser />}
              // color='black'
              // onClick={()=>navigate('/signup')}

            >
								Sign Up
            </Button>
          </ButtonGroup>

        </HStack>
      </Container>

      <Stack mt={'5em'} >
        <Box
          // backgroundImage=''
          // backgroundPosition=''
          // backgroundRepeat='no-repeat'
          bgImage='https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/img-fzl/Blue+And+White+Technology+Linktree+Background+.png'
          bgSize='contain'
          bgRepeat='no-repeat'
          bgPos='bottom'
        >
          <Flex
            direction={{ base: 'column', md: 'column' }}
            justifyContent={'center'}
            alignItems={'center'}
            fontSize={{ base: '1.5em',md: '2em', lg: '3em' }}
            fontWeight="bold"
            color={'white'}
            // gap={'0.25em'}
					
          >
            <Text stroke='ActiveBorder'>100+ Karyawan AI dengan skillset expert</Text>
            <Text bgGradient={linearColor} bgClip="text">
						dan gaji 10% dari UMR Jogjakarta
            </Text>
          </Flex>
          <Text
            textAlign={'center'}
            fontSize={{ base: '0.9em', md: '1em', lg:'1.1em' }}
            textColor={'gray.500'}
            mt={'1em'}
          >
					Dari data badan pusat statistik hingga content tiktok bisa dibuat secara otomatis menggunakan KARYAWAN.AI
          </Text>
          <ButtonGroup
            mt={'0.75em'}
            display={'flex'}
            flexDirection={{ base: 'column', md: 'row' }}
            gap={'0.5em'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Button
              w={'9em'}
              variant={'blue'}
              rounded={'full'}
              bgGradient={linearColor}
              leftIcon={<AiOutlineRobot />}
              textColor={'black'}
              onClick={() => navigate('/login')}
            >
						Login{' '}
            </Button>
            <Button rounded={'full'} w={'9em'} leftIcon={<AiOutlineUser />}>
						Sign Up
            </Button>
          </ButtonGroup>
          <Center>
            <HStack>
              <Text
                mt={'0.25em'}
                display={'flex'}
                justifyContent={'center'}
                gap={3}
                fontSize={'1em'}
                textColor={'gray.500'}
              >
					Dibangun oleh{' '}
              </Text>
              <Image h='50px'  src='https://business.deoapp.com/static/media/1.8cd0242eae18427fbd29.png' alt='deoapp logo'/>
            </HStack>
          </Center>
				
          <center>
            <Box mt={{ base: '2em', lg: '5em' }} ml={1} >
              <AspectRatio
                // boxShadow='outline'
                // dropShadow='light-lg'
                maxW={{ base: '25em', md: '30em', lg: '65em' }}
                ratio={16 / 9}
              >
                <iframe
                  title="introduction"
                  src="https://www.youtube.com/embed/mToe6D2fSOA?si=cSYn5PT9pHIW5Wbv"
                />
              </AspectRatio>
            </Box>
          </center>
            
        </Box>
			
        {/* <Divider colorScheme='gray' h={0.3}  /> */}

        {/* <Text
				// mt={'2em'}
				display={'flex'}
				justifyContent={'center'}
				fontSize={'1.1em'}
				textColor={'gray.500'}
                textAlign={'center'}
			>
				Trusted and used by teams around the globe
			</Text> */}
        {/* <Box overflow={'hidden'} justifyContent={'center'}>
                <Flex display={'inline-flex'}  gap={10} whiteSpace={'nowrap'} animation={animation} >
                    {imageLogo.map((x,i) => (
                        <Image h={'1.5em'}  src={x.image}/>
                    ))}
                    
                </Flex>
            </Box> */}

        <Divider colorScheme='gray' h={0.3}  />

        <Flex mt={'5em'} direction={'column'} textColor={'#98A6B0'} fontWeight={500} alignItems={'center'} textAlign={'center'}>
          <Text bgGradient={linearColor} bgClip='text' fontSize={['1em', '1.5em', '2.75em']} fontWeight={700}>3 Langkah mudah hire karyawan AI</Text>
          {/* <Text mt={'0.5em'} w={{lg:'35em'}} >
					Developing LLM apps takes countless iterations. With low code
					approach, we enable quick iterations to go from testing to production
				</Text> */}
          <SimpleGrid columns={{base:1,md:3}} gap='3' >
            <Box p='2'>
              <Heading fontSize='lg'>Step#1</Heading>
              <Text>Pilih Karyawan.ai kamu</Text>
              <Text>Dengan Karyawan.ai, kamu memiliki keleluasaan untuk memilih karyawan yang sesuai dengan kebutuhan bisnis kamu.
Mulai dari level staff bahkan anak magang sampai level manager semua divisi</Text>
            </Box>
            <Box p='2'>
              <Heading fontSize='lg'>Step#2</Heading>
              <Text>Tanyakan kebutuhan bisnis kamu kepada karyawan.ai kamu.</Text>
              <Text>Kamu bisa langsung tanya jawab dengan karyawan.ai kamu dengan UI selayaknya chat melalui whatsapp. Data kamu juga tersimpat dengan rapih di dalam platform kita.</Text>
            </Box>
            <Box p='2'>
              <Heading fontSize='lg'>Step#3</Heading>
              <Text>Eksekusi hasil</Text>
              <Text>Setelah mendapatkan jawaban yang sesuai, kamu tinggal eksekusi seluruh hasil dari jawaban karyawan.ai yang kamu gaji.</Text>
            </Box>
          </SimpleGrid>

          {/* <Flex borderRadius={10} mt={'0.5em'} bgColor={'gray.900'} p='5' direction={'column'} alignItems={'center'} justifyContent={'center'} textColor={'gray.300'} fontWeight={600}>
					<Text>Penggunaan karyawan.ai dapat membantu</Text>
					<Text> produktifitas team kamu hingga 80%</Text>
				</Flex> */}
          <Button mt={'2em'} rounded={'full'} bgColor={'gray.700'} textColor={'white'}>
					Get Started
          </Button>
        </Flex>
            
        <SimpleGrid columns={{base:1,md:2}} p='2'>
          <Box  display={'flex'} flexDirection={'column'} textColor={'white'} maxW='md' justifyContent={'center'} gap={'0.25em'} justifySelf='end'>
            <Text textColor={'blue.600'}>Chatflow</Text>
            <Text fontSize={'1.8em'} fontWeight={'700'}>Chat dengan AI (kecerdasan buatan) yang bisa langsung menjawab pertanyaan kamu.</Text>
            <Text textColor={'gray.400'} fontWeight={600}>
						Diskusi langsung dengan karyawan.ai selayaknya kamu berdiksui denga professionals kelas dunia.
            </Text>
            <SimpleGrid columns='2'>
              <UnorderedList mt={'1em'} listStyleType={'none'}>
                <ListItem>
                  <Flex gap={1} alignItems={'center'}>
                    <Icon as={FaCheckCircle} color={'green'}/>
                    <Box
                      textColor={'gray.400'} fontWeight={600}
                      fontSize={'1em'}
                      ml={1}
                      display={'flex'}
                      alignItems={'center'}
                    >
                      {' '}
									Facebook Ads Expert
                    </Box>
                  </Flex>
                  <Flex gap={1} alignItems={'center'}>
                    <Icon as={FaCheckCircle} color={'green'} />
                    <Box
                      textColor={'gray.400'} fontWeight={600}
                      fontSize={'1em'}
                      ml={1}
                      display={'flex'}
                      alignItems={'center'}
                    >
                      {' '}
									Tax Expert
                    </Box>
                  </Flex>
                  <Flex gap={1} alignItems={'center'}>
                    <Icon as={FaCheckCircle} color={'green'}/>
                    <Box
                      textColor={'gray.400'} fontWeight={600}
                      fontSize={'1em'}
                      ml={1}
                      display={'flex'}
                      alignItems={'center'}
                    >
                      {' '}
									Stastic Expert
                    </Box>
                  </Flex>
                </ListItem>
              </UnorderedList>
              <UnorderedList mt={'1em'} listStyleType={'none'}>
                <ListItem>
                  <Flex gap={1} alignItems={'center'}>
                    <Icon as={FaCheckCircle} color={'green'}/>
                    <Box
                      textColor={'gray.400'} fontWeight={600}
                      fontSize={'1em'}
                      ml={1}
                      display={'flex'}
                      alignItems={'center'}
                    >
                      {' '}
									Trend Researcher
                    </Box>
                  </Flex>
                  <Flex gap={1} alignItems={'center'}>
                    <Icon as={FaCheckCircle} color={'green'} />
                    <Box
                      textColor={'gray.400'} fontWeight={600}
                      fontSize={'1em'}
                      ml={1}
                      display={'flex'}
                      alignItems={'center'}
                    >
                      {' '}
									Legal Expert
                    </Box>
                  </Flex>
                  <Flex gap={1} alignItems={'center'}>
                    <Icon as={FaCheckCircle} color={'green'}/>
                    <Box
                      textColor={'gray.400'} fontWeight={600}
                      fontSize={'1em'}
                      ml={1}
                      display={'flex'}
                      alignItems={'center'}
                    >
                      {' '}
									100+ Expert lainnya
                    </Box>
                  </Flex>
                </ListItem>
              </UnorderedList>
            </SimpleGrid>
				
          </Box>
          <Box >
            <Image
              shadow='inner'
              boxSize={{base:'17em',md:'md',lg:'xl'}}
              src="https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/img-fzl/logo/karyawan.ai.png"
            />
          </Box>
				
        </SimpleGrid>

        <SimpleGrid  columns={{base:1,md:2}} mt='5' p='2'>
          <Box  w={'35em'} justifySelf='end' pr='5' >
            <AspectRatio
              ratio={16 / 9}
            >
              <iframe
                title="introduction"
                src="https://flowiseai.com/videos/agentassistant.mp4"
              />
            </AspectRatio>
          </Box>
          <Box maxW={'30em'} display={'flex'} flexDirection={'column'} textColor={'white'} justifyContent={'center'} gap={'0.25em'}>
            <Text textColor={'blue.600'}>Automation</Text>
            <Text fontSize={'1.8em'} fontWeight={'700'}>Otomatisasi untuk bisnis kamu</Text>
            <Text textColor={'gray.400'} fontWeight={600}>
						Dari chat bot untuk customer service, graphic designer hingga editing video.
            </Text>
            <UnorderedList mt={'1em'} listStyleType={'none'}>
              <ListItem>
                <Flex gap={1} alignItems={'center'}>
                  <Icon as={FaCheckCircle} color={'green'}/>
                  <Box
                    textColor={'gray.400'} fontWeight={600}
                    fontSize={'1em'}
                    ml={1}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    {' '}
									Copywriting
                  </Box>
                </Flex>
                <Flex gap={1} alignItems={'center'}>
                  <Icon as={FaCheckCircle} color={'green'} />
                  <Box
                    textColor={'gray.400'} fontWeight={600}
                    fontSize={'1em'}
                    ml={1}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    {' '}
									Story Telling Script
                  </Box>
                </Flex>
                <Flex gap={1} alignItems={'center'}>
                  <Icon as={FaCheckCircle} color={'green'}/>
                  <Box
                    textColor={'gray.400'} fontWeight={600}
                    fontSize={'1em'}
                    ml={1}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    {' '}
									OpenAI Assistant
                  </Box>
                </Flex>
              </ListItem>
            </UnorderedList>
          </Box>
        </SimpleGrid>

        <SimpleGrid  columns={{base:1,md:2}} mt='20'>
          <Box					maxW='lg'
            justifySelf='end' display={'flex'} flexDirection={'column'} textColor={'white'} justifyContent={'center'} gap={'0.25em'}>
            <Text textColor={'blue.600'}>Developer Friendly</Text>
            <Text fontSize={'1.8em'} fontWeight={'700'}>Basic Knowledge</Text>
            <Text textColor={'gray.400'} fontWeight={600}>
                    karyawan.ai memhamai data-data spesifik yang dibutuhkan di Indonesia
            </Text>
            <SimpleGrid columns='2'>
              <UnorderedList mt={'1em'} listStyleType={'none'}>
                <ListItem>
                  <Flex gap={1} alignItems={'center'}>
                    <Icon as={FaCheckCircle} color={'green'}/>
                    <Box
                      textColor={'gray.400'} fontWeight={600}
                      fontSize={'1em'}
                      ml={1}
                      display={'flex'}
                      alignItems={'center'}
                    >
                      {' '}
									Badan Pusat Statistik
                    </Box>
                  </Flex>
                  <Flex gap={1} alignItems={'center'}>
                    <Icon as={FaCheckCircle} color={'green'} />
                    <Box
                      textColor={'gray.400'} fontWeight={600}
                      fontSize={'1em'}
                      ml={1}
                      display={'flex'}
                      alignItems={'center'}
                    >
                      {' '}
									Kementrian Keuangan
                    </Box>
                  </Flex>
                  <Flex gap={1} alignItems={'center'}>
                    <Icon as={FaCheckCircle} color={'green'}/>
                    <Box
                      textColor={'gray.400'} fontWeight={600}
                      fontSize={'1em'}
                      ml={1}
                      display={'flex'}
                      alignItems={'center'}
                    >
                      {' '}
									Trending News
                    </Box>
                  </Flex>
                </ListItem>
              </UnorderedList>
              <UnorderedList mt={'1em'} listStyleType={'none'}>
                <ListItem>
                  <Flex gap={1} alignItems={'center'}>
                    <Icon as={FaCheckCircle} color={'green'}/>
                    <Box
                      textColor={'gray.400'} fontWeight={600}
                      fontSize={'1em'}
                      ml={1}
                      display={'flex'}
                      alignItems={'center'}
                    >
                      {' '}
									Trending Keyword
                    </Box>
                  </Flex>
                  <Flex gap={1} alignItems={'center'}>
                    <Icon as={FaCheckCircle} color={'green'} />
                    <Box
                      textColor={'gray.400'} fontWeight={600}
                      fontSize={'1em'}
                      ml={1}
                      display={'flex'}
                      alignItems={'center'}
                    >
                      {' '}
									Trending Products
                    </Box>
                  </Flex>
                  <Flex gap={1} alignItems={'center'}>
                    <Icon as={FaCheckCircle} color={'green'}/>
                    <Box
                      textColor={'gray.400'} fontWeight={600}
                      fontSize={'1em'}
                      ml={1}
                      display={'flex'}
                      alignItems={'center'}
                    >
                      {' '}
									Alquran & hadist
                    </Box>
                  </Flex>
                </ListItem>
              </UnorderedList>
            </SimpleGrid>
          </Box>
          <Image
            boxSize={'md'}
            src="https://imagetolink.com/ib/UN9UxVZTlf.png"
          />
        </SimpleGrid>

        <SimpleGrid  columns={{base:1,md:2}} mt='20'>
          <Image
            justifySelf='end'
            // boxSize={'md'}
            src="https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/img-fzl/logo/AiKaryawan2.png"
          />
          <Box w={'30em'} display={'flex'} flexDirection={'column'} textColor={'white'} justifyContent={'center'} gap={'0.25em'}>
            <Text textColor={'blue.600'}>Platform Agnostic</Text>
            <Text fontSize={'1.8em'} fontWeight={'700'}>User Knowledge</Text>
            <Text textColor={'gray.400'} fontWeight={600}>
                    Tambahkan data dari perusahaan kamu kepada karyawan.ai untuk diolah menjadi informasi baru.
            </Text>
            <SimpleGrid columns='2'>
              <UnorderedList mt={'1em'} listStyleType={'none'}>
                <ListItem>
                  <Flex gap={1} alignItems={'center'}>
                    <Icon as={FaCheckCircle} color={'green'}/>
                    <Box
                      textColor={'gray.400'} fontWeight={600}
                      fontSize={'1em'}
                      ml={1}
                      display={'flex'}
                      alignItems={'center'}
                    >
                      {' '}
									PDF
                    </Box>
                  </Flex>
                  <Flex gap={1} alignItems={'center'}>
                    <Icon as={FaCheckCircle} color={'green'} />
                    <Box
                      textColor={'gray.400'} fontWeight={600}
                      fontSize={'1em'}
                      ml={1}
                      display={'flex'}
                      alignItems={'center'}
                    >
                      {' '}
									CSV
                    </Box>
                  </Flex>
                  <Flex gap={1} alignItems={'center'}>
                    <Icon as={FaCheckCircle} color={'green'}/>
                    <Box
                      textColor={'gray.400'} fontWeight={600}
                      fontSize={'1em'}
                      ml={1}
                      display={'flex'}
                      alignItems={'center'}
                    >
                      {' '}
									DOCX
                    </Box>
                  </Flex>
                </ListItem>
              </UnorderedList>
              <UnorderedList mt={'1em'} listStyleType={'none'}>
                <ListItem>
                  <Flex gap={1} alignItems={'center'}>
                    <Icon as={FaCheckCircle} color={'green'}/>
                    <Box
                      textColor={'gray.400'} fontWeight={600}
                      fontSize={'1em'}
                      ml={1}
                      display={'flex'}
                      alignItems={'center'}
                    >
                      {' '}
									SRT
                    </Box>
                  </Flex>
                  <Flex gap={1} alignItems={'center'}>
                    <Icon as={FaCheckCircle} color={'green'} />
                    <Box
                      textColor={'gray.400'} fontWeight={600}
                      fontSize={'1em'}
                      ml={1}
                      display={'flex'}
                      alignItems={'center'}
                    >
                      {' '}
									IMAGES(JPG/JPEG/PNG)
                    </Box>
                  </Flex>
                  <Flex gap={1} alignItems={'center'}>
                    <Icon as={FaCheckCircle} color={'green'}/>
                    <Box
                      textColor={'gray.400'} fontWeight={600}
                      fontSize={'1em'}
                      ml={1}
                      display={'flex'}
                      alignItems={'center'}
                    >
                      {' '}
									APIs
                    </Box>
                  </Flex>
                </ListItem>
              </UnorderedList>
            </SimpleGrid>
					
          </Box>
				
        </SimpleGrid>

        <Divider mt={'4em'}/>
        <Box 
          direction={'column'} 
          bgImage='https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/img-fzl/background/Blue+and+Green+Gradient+Technology+Linktree+Background.png'
          backgroundPosition='inherit'
          // bgSize='contain'
          bgRepeat='repeat-x'
          // backgroundBlendMode='darken'
        >
          <Flex mt={'5em'} direction={'column'}  textColor={'#98A6B0'} fontWeight={500} alignItems={'center'} textAlign={'center'}>
            <Text bgColor='black'  bgGradient={linearColor} bgClip="text" bgBlendMode='lighten' fontSize={['1em', '1.5em', '2.75em']} fontWeight={700}>Struktur Organisasi AI </Text>
            <Text mt={'0.5em'} w={'35em'} >
                1 Organisasi dapat di jalankan dengan karyawan.ai
            </Text>
          </Flex>

          <Container  bgColor='black' maxW='container.lg' border='1px' borderRadius='lg' borderColor='#ffd600'>
            <OrganizationChartComponent data={organisasi}/>
          </Container>
        </Box>

        <Divider mt={'6em'} />

        <Flex direction={'column'} 
			
        >
          <Flex mt={'5em'} direction={'column'}  textColor={'#98A6B0'} fontWeight={500} alignItems={'center'} textAlign={'center'}>
            <Text bgGradient={linearColor}  bgClip="text" fontSize={['1em', '1.5em', '2.75em']} fontWeight={700}>Rekrut Karyawan AI kamu</Text>
            <Text mt={'0.5em'} w={'35em'} >
                Beragam macam karyawan ai yang bisa kamu rekrut untuk organisasi kamu.
            </Text>
            <Button
              mt={'2em'}
              w={'15em'}
              variant={'blue'}
              rounded={'full'}
              bgGradient={linearColor}
              leftIcon={<FaDiscord />}
              textColor={'white'}
            >
						Join Discord{' '}
            </Button>
          </Flex>
          {/* <SimpleGrid mt={'3em'} columns={[1, 2, 3]} gap='2'>
                {communityVideo.map((x,i) => (
					<AspectRatio maxW='560px' ratio={16/9} key={i}>
					<iframe
					  title='naruto'
					  src={x.image}
					  allowFullScreen
					/>
				  </AspectRatio>
					// <Center key={i}>
                    //  <iframe maxW="352" maxH="198" src={x.image} title={x.title} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
					//  </Center>
				))}
            </SimpleGrid> */}
        </Flex>

        <Divider mt={'5em'}/>

        {/* <Box
			bgImage='https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/img-fzl/background/Neon+Green+and+Black+Tech+Virtual+Background.png'
			bgSize='cover'
			// bgColor='red'
			bgPos='center'
			width='full'
			bgRepeat='no-repeat'
			p='20'
			borderBottom='1px'
			borderColor='gray'
			>

			<Flex mt={'5em'} direction={'column'} textColor={'#98A6B0'} fontWeight={500} alignItems={'center'} textAlign={'center'}>
                <Text bgGradient={linearColor} bgClip='text' fontSize={['1em', '1.5em', '2.75em']} fontWeight={700}>Webinars</Text>
				<Text mt={'0.5em'} w={'35em'} >
                Learn how to use Flowise from different webinar series with our partners
				</Text>
            </Flex>

            <SimpleGrid gap={5} m={'2em'} columns={[1, 2, 3]} >
            <Card maxW='sm'bgColor={'gray.700'}>
				<CardBody p='2'>
					<Image
						h={'11.5em'}
					src='https://flowiseai.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fsinglestore_aws_webinar.185bcd9c.png&w=1080&q=75'
					/>
					<Flex ml={'1em'} gap={5}  mt='6' spacing='3'>
					<Image
						boxSize={50}
					src='https://flowiseai.com/_next/static/media/singlestore.a5b72cb0.svg'
					/>
					<Image
					src='https://flowiseai.com/_next/static/media/aws.c9a4034b.svg'
					/>
					</Flex>
					<Text mt={'1em'} mx={'1em'} textColor={'white'}>How to Build a NoCode AWS Bedrock LLM App on Flowise</Text>
				</CardBody>
				<CardFooter>
					<NavLink>
						<Text textColor={'purple.400'}>Learn More</Text>
					</NavLink>
				</CardFooter>
			</Card>
			<Card maxW='sm'bgColor={'gray.700'}>
				<CardBody margin={0} p={0}>
					<Image
					src='https://flowiseai.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fmilvus_webinar.0165f039.png&w=828&q=75'
					/>
					<Flex ml={'1em'} gap={5}  mt='6' spacing='3'>
					<Image
						boxSize={50}
					src='https://flowiseai.com/_next/static/media/milvus.1ae32e3c.svg'
					/>
					</Flex>
					<Text mt={'1em'} mx={'1em'} textColor={'white'}>Drag and Drop LLMs: Simplifying RAG with FlowiseAI and Milvus
				</Text>
				</CardBody>
				<CardFooter>
					<NavLink>
						<Text textColor={'purple.400'}>Learn More</Text>
					</NavLink>
				</CardFooter>
			</Card>
			<Card maxW='sm'bgColor={'gray.700'}>
				<CardBody margin={0} p={0}>
					<Image
					src='https://flowiseai.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flangchainwebinar.2f5914b2.png&w=640&q=75'
					/>
					<Flex ml={'1em'} gap={5}  mt='6' spacing='3'>
					<Image
						boxSize={50}
					src='https://flowiseai.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flangchain.c1e79304.png&w=48&q=75'
					/>
					</Flex>
					<Text mt={'1em'} mx={'1em'} textColor={'white'}>LangChain Weekly Webinar - Low-Code/No-Code LLM</Text>
				</CardBody>
				<CardFooter>
					<NavLink>
						<Text textColor={'purple.400'}>Learn More</Text>
					</NavLink>
				</CardFooter>
			</Card>
			</SimpleGrid>
			</Box>
 */}

	
        {/* <Divider mt={'5em'}/> */}
        <Container maxW='container.lg' mt='5em'>
          <Text bgGradient={linearColor} textAlign='center'  bgClip="text" fontSize={['1em', '1.5em', '2.75em']} fontWeight={700}>Gaji Karyawan AI kamu</Text>
          <Text m={'0.5em'} color='white' textAlign='center' >
					Beragam macam karyawan ai yang bisa kamu rekrut untuk organisasi kamu.
          </Text>
					
          <SimpleGrid columns={{base:1,md:3}} color='white' gap='2'>

            <PricingComponent  keterangan={'Recruit seluruh staff di karyawan.ai mampu menyelesaikan tugas per KPI.'} linearColor={linearColor} posisi={'Staff'} credit={'12.000'} harga={'1.000k/bln'}/>
            <PricingComponent fiturTambahan={['API']} keterangan={'Recruit seluruh staff & manager di karyawan.ai mampu menyelesaikan tugas dan menggabungkan KPI dari sebuah divisi.'} linearColor={linearColor} posisi={'Manager'} credit={'50.000'} harga={'3.500k/bln'}/>
            <PricingComponent fiturTambahan={['API','Customer Service AI','ONSITE TRAINING']}keterangan={'Seorang direktur di karyawan.ai mampu menyelesaikan tugas, menganalisa report dan menggabungkan KPI dalam sebuah divisi.'} linearColor={linearColor} posisi={'Direktur'} credit={'100.000'} harga={'7.500k/bln'}/>

          </SimpleGrid>
        </Container>
			
        <Container
          maxW='container.lg'					
          // mt={'5'}
          // h={'12em'}
          p='10'
          // m='2'

          bgGradient={linearColor}
          // display={'flex'}
          // justifyContent={'center'}
          // alignItems={'center'}
          borderRadius={30}
          // gap={'20em'}
        >
          <HStack>
           
            <Box>
              <Text
                textAlign={'start'}
                fontSize={'x-large'}
                fontWeight={600}
                textColor={'white'}
              >
						Enterprise
              </Text>
              <Text
                textAlign={'start'}
                fontSize={'x-large'}
                fontWeight={600}
                textColor={'white'}
              >
						Looking for specific use cases and support?
              </Text>
            </Box>
            <Spacer/>
            <Box
              as={Button}
              borderRadius={100}
              border={'none'}
              bgColor={'rgb(42,72,223)'}
            >
              <NavLink to={'/auth/signin'}>
                <Text
                  textColor={'white'}
                  fontSize={{base:'md', md:'1.5em'}}
                  fontWeight={700}
                  p='2'
                >
								Lets Chat
                </Text>
              </NavLink>
            </Box>
          </HStack>
        </Container>

        <Container 
          mt='5em'
          color='white'
        >
          <Text bgGradient={linearColor} textAlign='center'  bgClip="text" fontSize={['1em', '1.5em', '2.75em']} fontWeight={700}>FAQ (Frequently Asked Question)</Text>
          <Text m={'0.5em'} color='white' textAlign='center' >
					Beragam macam karyawan ai yang bisa kamu rekrut untuk organisasi kamu.
          </Text>
          <Accordion >
            {faq.map((x,i) =>
              <AccordionItem key={i} border='1px'm='2' borderColor='#ffd600' borderRadius='lg'>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex='1' textAlign='left' >
                      {x.q}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} borderTop='1px' borderColor='#ffd600'>
                  {x.a}
                </AccordionPanel>
              </AccordionItem>
            )}
						

          </Accordion>
			
        </Container>

        <Center mt={'1em'}>
          <Text textColor={'white'}>©karyawan.ai All rights reserved.</Text>
        </Center>
      </Stack>
    </Stack>
  );
};

export default HomeMainPage;