import React from 'react';
import ContactsPage from '../Pages/Contacts/ContactsPage';
import ContactDetailsPage from '../Pages/Contacts/ContactsDetailPage';
import RetentionPage from '../Pages/Customer/RetentionPage';
import RetentionPipelineViewPage from '../Pages/Customer/RetentionPipelineViewPage';
import PipelineCreatePage from '../Pages/Customer/PipelineCreatePage';
import CustomerHomePage from '../Pages/Customer/CustomerHomePage';
import AcquisitionPipelinePageV2 from '../Pages/Customer/AcquisitionPipelinePageV2';
import AcquisitionPipelineViewPageV3 from '../Pages/Customer/AcquisitionPipelineViewPageV3';



const CustomerRelationRouter = [
  {
    path: '/customer',
    element: <CustomerHomePage />,
  },
  {
    path: '/relations/contacts',
    element: <ContactsPage />,
  },
  {
    path: '/relations/contacts/detail/:id',
    element: <ContactDetailsPage />,
  },

  {
    path: '/relations/retention',
    element: <RetentionPage />,
  },
  {
    path: '/relations/Retantion/pipeline/view/:id',
    element: <RetentionPipelineViewPage />,
  },
  {
    path: '/relations/acquisition',
    element: <AcquisitionPipelinePageV2 />,
  },
  {
    path: '/pipeline/create',
    element: <PipelineCreatePage />,
  },

  {
    path: '/relations/acquisition/view/:id',
    element: <AcquisitionPipelineViewPageV3 />,
  }
];

export default CustomerRelationRouter;
