import React from 'react';
import Pricing from '../Pages/Ai/Pricing/PricingPage';
import PaymentPage from '../Pages/Payment/PaymentPage';

const PricingRouter = [
  {
    path: '/pricing',
    element: <Pricing/>,
  },
  {
    path: '/pricing/:id',
    element: <PaymentPage />,
  },

];

export default PricingRouter;
