import React from 'react';
import ProductPage from '../Pages/Products/ProductPage';
import FormPageProduct from '../Pages/Products/FormPageProduct';
import ProductArticlePage from '../Pages/Products/ProductArticlePage';
import ProductEditSinglePage from '../Pages/Products/ProductEditSinglePage';
import ProductArticleCreatePage from '../Pages/Products/ProductArticleCreatePage';
import ProductHomePage from '../Pages/Products/ProductHomePage';
import ProductDetailPage from '../Pages/Products/ProductDetailPage';
import CategoryPage from '../Pages/Products/CategoryPage';
import ProductPageV2 from '../Pages/Products/ProductPageV2';
import WarehousePage from '../Pages/Products/WarehousePage';
import WarehouseDetailPage from '../Pages/Products/WarehouseDetailPage';
import VariantPage from '../Pages/Products/VariantPage';
import VariantDetailPage from '../Pages/Products/VariantDetailPage';

const ProductRouter = [
  {
    path: '/product',
    element: <ProductHomePage />,
  },
  {
    path: '/products',
    element: <ProductPage />,
  },

  {
    path: '/products-fix',
    element: <ProductPageV2 />,
  },

  {
    path: '/products-fix/:id',
    element: <ProductDetailPage />,
  },

  {
    path: '/products/:type',
    element: <FormPageProduct />,
  },

  {
    path: '/products/articles',
    element: <ProductArticlePage />,
  },
  {
    path: '/products/articles/create',
    element: <ProductArticleCreatePage />,
  },
  {
    path: '/products/article/edit/:id',
    element: <ProductEditSinglePage />,
  },
  {
    path: '/category',
    element: <CategoryPage />,
  },

  {
    path: '/warehouse',
    element: <WarehousePage />,
  },

  {
    path: '/warehouse/:id',
    element: <WarehouseDetailPage />,
  },

  {
    path: '/products/:id/variant/:variantId',
    element: <VariantDetailPage />,
  },

  // {
  //   path: '/payment/shipping/data',
  //   element: <ShippingPaymentPage />,
  // },
];

export default ProductRouter;
