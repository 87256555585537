/* eslint-disable no-alert */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Stack,
  Text,
  HStack,
  Divider,
  Image,
  ModalFooter,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Spacer,
  useToast,
  useDisclosure,
  Input,
  Button,
} from '@chakra-ui/react';
import {
  collection,
  getCountFromServer,
  query,
  where,
} from 'firebase/firestore';
import { db } from '../../Config/firebase';
import {
  addDocumentFirebase,
  deleteDocumentFirebase,
  getCollectionFirebase,
} from '../../Api/firebaseApi';
import useUserStore from '../../Hooks/Zustand/Store';

import 'react-date-range/dist/styles';
import 'react-date-range/dist/theme/default.css';

import { useNavigate } from 'react-router-dom';
import BackButtons from '../../Components/Buttons/BackButtons';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import DynamicTable from '../../Components/Table/DynamicTable';

const ProductPageV2 = () => {
  const navigate = useNavigate();
  const globalState = useUserStore();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [name, setName] = useState('')
  const [dataProduct, setDataProduct] = useState([])
  const toast = useToast()


  const itemsPerPage = 8;


  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);


  const createNewProduct = () => {
    const unix = name.toLowerCase() + ' ' + Date.now();
    const slug = unix.split(' ').join('-')

    const data = {
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject,
      name: name,
      slug: slug
    };
    addDocumentFirebase('product', data, globalState.currentCompany).then((docId) => {
      navigate(docId);
    });
  };

  const getData = async () => {
    const startIndex = (currentPage - 1) * itemsPerPage;

    const conditions = [
      {
        field: 'companyId',
        operator: '==',
        value: globalState.currentCompany,
      },
      {
        field: 'projectId',
        operator: '==',
        value: globalState.currentProject,
      }
    ];
    const sortBy = { field: 'lastUpdated', direction: 'desc' };
    const limitValue = startIndex + itemsPerPage;
    try {
      const res = await getCollectionFirebase(
        'product',
        conditions,
        sortBy,
        limitValue,
      );
      setDataProduct(res);

      const collectionRef = collection(db, 'product');

      // Membuat kondisi filter tambahan untuk pemilik (owner) jika filterOwner aktif
      const countQuery = query(collectionRef,
        where('companyId', '==', globalState.currentCompany),
        where('projectId', '==', globalState.currentProject),
      );


      const snapshot = await getCountFromServer(countQuery);
      const assessmentLength = snapshot.data().count;

      setTotalPages(Math.ceil(assessmentLength / itemsPerPage));

    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };


  useEffect(() => {
    getData();


  }, [globalState?.currentProject, currentPage]);

  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
  };



  const tableHeader = ['image', 'name', 'price', 'type', 'stocks', 'sold'];


  let tableData = '';
  tableData = dataProduct?.map((data) => {
    const image = data?.images?.length > 0 ? data?.images[0] : '';
    const name = data?.name || '';
    const price = data?.price || '';
    const stocks = data?.stocks || '';
    const sold = data?.sold || '';
    const type = data?.type || '';
    const published = !data?.is_active ? 'draft' : data.is_active;
    const on_discount = !data?.is_promo ? '-' : data.is_promo;

    return {
      data,
      image,
      name: (
        <Stack spacing={1}>
          <Text fontWeight={500} textTransform={'capitalize'}>
            {name}
          </Text>
          <Text fontSize={9}>{data?.id}</Text>
          <Text fontSize={9}>SKU : {data?.sku}</Text>
        </Stack>
      ),
      sold,
      price,
      stocks,
      type,
      // published,
      // on_discount
    };
  });

  const handleDetail = (x) => {
    navigate(`/products-fix/${x?.data?.id}`)
  }

  const deleteData = async (x, index) => {
    const confirmDelete = window.confirm('If you delete this data you can\'t Undo');
    if (confirmDelete)

      try {
        await deleteDocumentFirebase('product', x.data.id)
        getData()
        toast({
          title: 'Success',
          description: `Delete ${x.data.name} success`,
          status: 'success',
        })
      } catch (error) {
        console.log(error.message)
      }
  }

  return (
    <Box>
      <Stack py={2}>
        <HStack gap={5}>
          <BackButtons />
          <Text fontSize={'xl'} fontWeight={500}>
            Product
          </Text>
          <Spacer />
          <DynamicButton action={'create'} onClick={onOpen} title={'Add Product'} />
        </HStack>

        <Divider />
      </Stack>

      <Box>
        <DynamicTable
          header={tableHeader}
          data={tableData}
          onDelete={deleteData}
          // onRead={openModalDeleteRole}
          onEdit={handleDetail}
        />

        <Stack alignItems={'center'} justifyContent="center" py={5}>
          <Box>
            {currentPage < totalPages && (
              <Button colorScheme={'blue'} fontSize="sm" onClick={handleLoadMore}>
                Load More
              </Button>
            )}
          </Box>
        </Stack>
      </Box>





      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add new product</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* <Lorem count={2} /> */}
            <Input type="text" placeholder='Product name' onChange={(e) => setName(e.target.value)} />
          </ModalBody>

          <ModalFooter>
            <DynamicButton
              action={'create'}
              onClick={createNewProduct}
              title="Save"
              variant={'solid'}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>


    </Box>
  );
};

export default ProductPageV2;
