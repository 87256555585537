import React from 'react';
import HomeComingSoonPage from '../Pages/Home/HomeComingSoonPage';
import HomePage from '../Pages/Home/HomePage';

const HomeRouter = [
  {
    path: '/',
    element: <HomePage />,
  },
  {
    path: '/homePage',
    element: <HomeComingSoonPage />,
  },


];

export default HomeRouter;
