import ShippingPage from '../Pages/Shipping/ShippingPage';
import React from 'react'

const ShippingRouter = [
  {
    path: '/shipping',
    element: <ShippingPage />,
  }
]


export default ShippingRouter;