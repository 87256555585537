/* eslint-disable no-alert */
import {
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Skeleton,
  Spacer,
  Stack,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  useToast,
  Tabs,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  useDisclosure
} from '@chakra-ui/react';
import {
  collection,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
  getCountFromServer
} from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import BackButtons from '../../Components/Buttons/BackButtons';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import { addDocumentFirebase, deleteDocumentFirebase, getCollectionFirebase, updateDocumentFirebase } from '../../Api/firebaseApi';
import { auth, db } from '../../Config/firebase';
import useUserStore from '../../Hooks/Zustand/Store';
import { MdArrowDropDown, MdArrowDropDownCircle } from 'react-icons/md';
  
const CategoryPage = () => {
  const toast = useToast();
  const [searchValue, setSearchValue] = useState('');
  const [OpenModalCategory, setOpenModalCategory] = useState('');
  const [OpenModalSubCategory, setOpenModalSubCategory] = useState('');
  const [editData, setEditData] = useState('');
  const globalState = useUserStore();
  const [title, setTitle] = useState('')
  const [type, setType] = useState('physical')
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [dataCategory, setDataCategory] = useState([]);
  const [dataSubCategory, setDataSubCategory] = useState([]);
  const [filterData, setFilterData] = useState({categoriesId: ''})
  const [titleModalCategory, setTitleModalCategory] = useState('')
  const [titleModalSubCategory, setTitleModalSubCategory] = useState('')
  const [externalId, setExternalId] = useState('')

  const itemsPerPage = 10;

  const projectId = globalState.currentProject;
  // console.log(filterData, 'ini data filter')
  // console.log(type, 'ini typenya')

  const getDataCategory = async () => {
    // Fetch data from API or other source if needed
    try {
      const startIndex = (currentPage - 1) * itemsPerPage;
      const conditions = [
        { field: 'companyId', operator: '==', value: globalState.currentCompany},
        { field: 'projectId', operator: '==', value: globalState.currentProject},
        { field: 'categoriesId', operator: '==', value: null},
        { field: 'is_parent', operator: '==', value: true},
      ];
        
      const sortBy = { field: 'createdAt', direction: 'asc' };
      const limitValue = startIndex + itemsPerPage;
  
      const res = await getCollectionFirebase('product_categories', conditions);
      setDataCategory(res);
  
      const collectionRef = collection(db, 'product_categories');
      const snapshot = await getCountFromServer(
        query(
          collectionRef,
          where('companyId', '==', globalState.currentCompany),
          // where('projectId', '==', globalState.currentProject),
        ),
      );
  
      const assesmentLength = snapshot.data().count;
      setTotalPages(Math.ceil(assesmentLength / itemsPerPage));
    } catch (error) {
      console.log(error.message)
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };


  const getDataSubcategory = async () => {
    // Fetch data from API or other source if needed
    try {
      const startIndex = (currentPage - 1) * itemsPerPage;
      const conditions = [
        { field: 'companyId', operator: '==', value: globalState.currentCompany},
        { field: 'projectId', operator: '==', value: globalState.currentProject},
        { field: 'is_parent', operator: '==', value: false},
      ];
    
      if(filterData?.categoriesId)
        conditions.push({
          field: 'categoriesId',
          operator: '==',
          value: filterData?.categoriesId,
        });
        
      const sortBy = { field: 'createdAt', direction: 'asc' };
      const limitValue = startIndex + itemsPerPage;
  
      const res = await getCollectionFirebase('product_categories', conditions );
      setDataSubCategory(res);
  
      const collectionRef = collection(db, 'product_categories');
      const snapshot = await getCountFromServer(
        query(
          collectionRef,
          where('companyId', '==', globalState.currentCompany),
          // where('projectId', '==', globalState.currentProject),
        ),
      );
  
      const assesmentLength = snapshot.data().count;
      setTotalPages(Math.ceil(assesmentLength / itemsPerPage));
    } catch (error) {
      console.log(error.message)
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };
  
  useEffect(() => {

    getDataCategory();
    getDataSubcategory()

  }, [globalState.currentProject, filterData]);

  const onSubmitModalCategory = async () => {
    try {
      setIsLoading(true)
      const dataSave = {
        categoriesId: null,
        companyId: globalState.currentCompany,
        createdAt: new Date(),
        createdBy: auth.currentUser.uid,
        externalId: externalId,
        is_parent: true,
        module: ['commerce'],
        projectId: projectId,
        title: title,
        type: type,
      }
      if (editData) {
        await updateDocumentFirebase('product_categories', editData, dataSave);
      } else {
        await addDocumentFirebase('product_categories', dataSave, globalState.currentCompany);
      }
  
      getDataCategory()
      setIsLoading(false)
      handleCloseModalCategory();
    } catch (error) {
      console.error('Error submitting data:', error);
      toast({
        title: 'Admin',
        description: 'An error occurred while saving data',
        status: 'error',
      });
      setIsLoading(false);
    }
  };

  const onSubmitModalSubCategory = async () => {
    try {
      setIsLoading(true)
      const dataSave = {
        categoriesId: filterData?.categoriesId,
        companyId: globalState.currentCompany,
        createdAt: new Date(),
        createdBy: auth.currentUser.uid,
        externalId: externalId,
        is_parent: false,
        module: ['commerce'],
        projectId: projectId,
        title: title,
        type: type,
      }
      if (editData) {
        await updateDocumentFirebase('product_categories', editData, dataSave);
      } else {
        await addDocumentFirebase('product_categories', dataSave, globalState.currentCompany);
      }
  
      getDataSubcategory()
      setIsLoading(false)
      handleCloseModalSubCategory();
    } catch (error) {
      console.error('Error submitting data:', error);
      toast({
        title: 'Admin',
        description: 'An error occurred while saving data',
        status: 'error',
      });
      setIsLoading(false);
    }
  };
  
  const deleteData = async (id, index) => {
    const confirmDelete = window.confirm('If you delete this data you cant Undo');
    if (confirmDelete) 
    
      try {
        await deleteDocumentFirebase('product_categories', id)
        getDataCategory()
        toast({
          title: 'Admin',
          description: 'Success delete banner',
          status: 'success',
        })
      } catch (error){
        console.log(error.message)
      }
  }


  const handleOpenModalCategory = () => {
    setOpenModalCategory(true)
  }
  const handleCloseModalCategory = () => {
    setOpenModalCategory(false)
    setEditData()
  }
  const handleOpenModaSubCategory = () => {
    setOpenModalSubCategory(true);
  }
  const handleCloseModalSubCategory = () => {
    setOpenModalSubCategory(false);
    setEditData()
  }

  const filteredDataCategory = dataCategory.filter((x) =>
    x.title.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <>
      <Box p='5'>
        <HStack mb='5'>
          <BackButtons />
          <Spacer/>
          <Heading>Category</Heading>
        </HStack>

        <Box

          borderRadius="md"
          shadow="md"
        // bgColor={'white'}
        >
          <Tabs variant="enclosed">
            <TabList>
              <Tab>Category</Tab>
              <Tab>Sub Category</Tab>

              {/* <Tab>Team</Tab> */}
            </TabList>
            <TabPanels>
              <TabPanel>
                <Box p="5" borderRadius="md" shadow="base" mt={5}>
                  <HStack mb='2'>
                    <Spacer />
                    <InputGroup size="md" w='30%'>
                      <Input
                        type="text"
                        placeholder="Search Name"
                        onChange={(event) => setSearchValue(event.target.value)}
                      />
                      <InputRightElement width="4.5rem">
                        <Button h="1.75rem" size="sm" mr={2}>
                  Search
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                   
                    <DynamicButton
                      size={'md'}
                      action="create"
                      title={'Create New'}
                      onClick={() => {setTitleModalCategory('Create New Category'); handleOpenModalCategory()}}
                    />
                  </HStack>
                  <TableContainer
                    border={'1px'}
                    borderRadius={'sm'}
                    borderColor={'gray.100'}
                    boxShadow={'base'}
                  >
                    <Table variant="striped">
                      <Thead>
                        <Tr>
                          <Th>name </Th>
                          <Th>type </Th>
                          <Th>Document ID</Th>
                          <Th textAlign="center">Action</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {filteredDataCategory.map((x,i) => (
                          <Tr key={i}>
                            <Td>
                              <HStack>
                                <Stack spacing={0}>
                                  <Text
                                  //   textTransform={'capitalize'}
                                    fontWeight={'bold'}
                                    fontSize="sm"
                                  >
                                    {x.title}
                                  </Text>
                                </Stack>
                              </HStack>
                            </Td>
                            <Td>
                              <Text>
                                {x.type}
                              </Text>
                            </Td>
                            <Td>
                              {x.id}
                            </Td>
               
                            <Td>
                              <HStack spacing={2} justifyContent="center">
                                <DynamicButton
                                  size="sm"
                                  action="update"
                                  onClick={() => {setEditData(x.id); setTitleModalCategory('Update Category'); handleOpenModalCategory()}}
                                />
                                <DynamicButton
                                  size="sm"
                                  action="delete"
                                  // isLoading={loadingStates[i]}
                                  onClick={() => deleteData(x.id, i)}
                                />
                              </HStack>
                            </Td>
                          </Tr>
                        ))} 
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Box>
              </TabPanel>

              <TabPanel>
                <Box p='5' borderRadius="md" shadow="base" mt='5'>
                  <HStack mb='2'>
                    <Spacer />
                
                    <Select
                      maxW='26%'
                      value={filterData?.categoriesId}
                      placeholder="Filter All Operations"
                      icon={<MdArrowDropDown/>}
                      onChange={(e) => setFilterData({...filterData, categoriesId: e.target.value})}
                      required
                    >
                      {dataCategory.map((x,i) => (
                        <option key={i} value={x.id}>{x.title}</option>
                   
                      ))}
                    </Select>
                    <DynamicButton
                      size={'md'}
                      action="create"
                      title={'Create New'}
                      onClick={() => {setTitleModalSubCategory('Create Sub Category'); handleOpenModaSubCategory()}}
                    />
                  </HStack>
  
                  <TableContainer
                    border={'1px'}
                    borderRadius={'sm'}
                    borderColor={'gray.100'}
                    boxShadow={'base'}
                  >
                    <Table variant="striped">
                      <Thead>
                        <Tr>
                          <Th>name </Th>
                          <Th>type </Th>
                          <Th>category id </Th>
                          <Th textAlign="center">Action</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {dataSubCategory.map((x,i) => (
                          <Tr key={i}>
                            <Td>
                              <HStack>
                                <Stack spacing={0}>
                                  <Text
                                  //   textTransform={'capitalize'}
                                    fontWeight={'bold'}
                                    fontSize="sm"
                                  >
                                    {x.title}
                                  </Text>
                                </Stack>
                              </HStack>
                            </Td>
                            <Td>
                              <Text>
                                {x.type}
                              </Text>
                            </Td>
                            <Td>
                              {x.categoriesId}
                            </Td>
               
                            <Td>
                              <HStack spacing={2} justifyContent="center">
                                <DynamicButton
                                  size="sm"
                                  action="update"
                                  onClick={() => {setEditData(x.id); setTitleModalSubCategory('Update Sub Category'); handleOpenModaSubCategory()}}
                                />
                                <DynamicButton
                                  size="sm"
                                  action="delete"
                                  onClick={() => deleteData(x.id, i)}
                                />
                              </HStack>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                 
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
  
        <Modal isOpen={OpenModalCategory} onClose={handleCloseModalCategory} size={'xl'} >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{titleModalCategory}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex
                w={'100%'}
                gap={5}
                direction={['column', 'row', 'row']}
              >
                <Box
                  p={5}
                  w={['100%']}
                  shadow={'base'}
                >
                  <FormControl mb="4" isRequired>
                    <FormLabel>Name</FormLabel>
                    <Input
                      type="text"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </FormControl>

                  <FormControl mb="4" isRequired>
                    <FormLabel>External ID</FormLabel>
                    <Input
                      type="text"
                      value={externalId}
                      onChange={(e) => setExternalId(e.target.value)}
                    />
                  </FormControl>
                  <FormControl mb="4" isRequired>
                    <FormLabel>Type</FormLabel>
                    <Select
                      icon={<MdArrowDropDown/>}
                      onChange={(e) => {setType(e.target.value);}}
                      value={type}
                    >
                      <option>physical</option>
                      <option>digital</option>
                    </Select>
                  </FormControl>
                </Box>
              </Flex>
              <HStack mt="10" mb="5" display="flex" justifyContent="end" spacing={2}>
                <DynamicButton
                  size={'sm'}
                  action="custom"
                  color={'red'}
                  // icon={IoCloseSharp}
                  title={'Cancel'}
                  onClick={handleCloseModalCategory}
                />
                <DynamicButton
                  color={'green'}
                  isLoading={isLoading}
                  size={'sm'}
                  action="custom"
                  title={'Submit'}
                  onClick={() => onSubmitModalCategory()}
                />
              </HStack>
            </ModalBody>
          </ModalContent>
        </Modal>

        <Modal isOpen={OpenModalSubCategory} onClose={handleCloseModalSubCategory} size={'xl'} >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{titleModalSubCategory}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex
                w={'100%'}
                gap={5}
                direction={['column', 'row', 'row']}
              >
                <Box
                  p={5}
                  w={['100%']}
                  shadow={'base'}
                >
                  <FormControl mb="4" isRequired>
                    <FormLabel>Name</FormLabel>
                    <Input
                      type="text"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      required
                    />
                  </FormControl>
                  <FormControl mb="4" isRequired>
                    <FormLabel>Type</FormLabel>
                    <Select
                      icon={<MdArrowDropDown/>}
                      onChange={(e) => {setType(e.target.value);}}
                    >
                      <option>physical</option>
                      <option>digital</option>
                    </Select>
                  </FormControl>
                  <FormControl mb="4" isRequired>
                    <FormLabel>Category</FormLabel>
                    <Select
                      value={filterData?.categoriesId}
                      icon={<MdArrowDropDown/>}
                      onChange={(e) => setFilterData({...filterData, categoriesId: e.target.value})}
                    >
                      {dataCategory.map((x,i) => (
                        <option key={i} value={x.id}>{x.title}</option> ))}
                    </Select>
                  </FormControl>
                </Box>
              </Flex>
              <HStack mt="10" mb="5" display="flex" justifyContent="end" spacing={2}>
                <DynamicButton
                  size={'sm'}
                  action="custom"
                  color={'red'}
                  // icon={IoCloseSharp}
                  title={'Cancel'}
                  onClick={handleCloseModalSubCategory}
                />
                <DynamicButton
                  color={'green'}
                  // isLoading={isLoading}
                  size={'sm'}
                  action="custom"
                  title={'Submit'}
                  onClick={() => onSubmitModalSubCategory()}
                />
              </HStack>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
}
  

export default CategoryPage