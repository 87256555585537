/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { InfoIcon } from '@chakra-ui/icons';
import { Box, Button,useColorModeValue, Heading,Spacer, List, ListIcon, ListItem, Text, Tooltip } from '@chakra-ui/react';
import { AiOutlineUser } from 'react-icons/ai';

const PricingComponent = (props) => {
  const colorMode = useColorModeValue('dark', 'light')

  return(
    <Box display='flex' flexDirection='column' textColor={colorMode === 'dark' ? 'black' : 'white'} p='2' border='1px' borderColor='#ffd600' borderRadius='lg'>
      <Text>{props.posisi}</Text>
      <Heading bgGradient={props.linearColor} bgClip="text">{props.harga}</Heading>
      <Text fontSize='sm'>{props.keterangan}</Text>

      <List spacing={1} m='2' fontSize={{base:'sm', xl:'xs'}}>
        <ListItem>
          <ListIcon as={AiOutlineUser} color='green.500' />
          {props.credit} credit
          {' '}
          <Tooltip label='1 credit = 1 query text'>
            <InfoIcon boxSize='10px' color='yellow'/>
  							</Tooltip>
        </ListItem>
        <ListItem>
          <ListIcon as={AiOutlineUser} color='green.500' />
							Akses kepada {props.posisi} karyawan.ai
        </ListItem>
        <ListItem>
          <ListIcon as={AiOutlineUser} color='green.500' />
							Akses kepada user knowledge
          {' '}
          <Tooltip label='1 credit = 10,000 karakter'>
            <InfoIcon boxSize='10px' color='yellow'/>
  							</Tooltip>
        </ListItem>

        <ListItem>
          <ListIcon as={AiOutlineUser} color='green.500' />
							Image creation
          {' '}
          <Tooltip label='10 credit = 1 query gambar'>
            <InfoIcon boxSize='10px' color='yellow'/>
  							</Tooltip>
        </ListItem>

        <ListItem>
          <ListIcon as={AiOutlineUser} color='green.500' />
							Video Editing
          {' '}
          <Tooltip label='10 credit = 1 detik video (1080hd)'>
            <InfoIcon boxSize='10px' color='yellow'/>
  							</Tooltip>
        </ListItem>
                        
        <ListItem>
          <ListIcon as={AiOutlineUser} color='green.500' />
							Scan website
          {' '}
          <Tooltip label='1 credit = 10,000 karakter'>
            <InfoIcon boxSize='10px' color='yellow'/>
  							</Tooltip>
        </ListItem>
        <ListItem>
          <ListIcon as={AiOutlineUser} color='green.500' />
							Scan PDF / DOCX / PPTX
          {' '}
          <Tooltip label='1 credit = 10,000 karakter'>
            <InfoIcon boxSize='10px' color='yellow'/>
  							</Tooltip>
        </ListItem>
        <ListItem>
          <ListIcon as={AiOutlineUser} color='green.500' />
							UNLIMITED User
        </ListItem>
        {props?.fiturTambahan?.map((x,u) =>
          <ListItem key={u}>
            <ListIcon as={AiOutlineUser} color='green.500' />
            {x}
          </ListItem>
        )}
      </List>
      <Spacer/>
      <Button bgGradient={props.linearColor} w='full'>Bayar Gaji</Button>
      <Text fontSize='xx-small' textAlign='center'>Trial untuk akses karyawan magang karyawan.ai
        {' '}
        <Tooltip label='Max 3 task per karyawan magang'>
          <InfoIcon boxSize='10px'/>
  					</Tooltip>
      </Text>

				
							
    </Box>
  )
}

export const PricingComponentMagang = (props) => {
  const colorMode = useColorModeValue('dark', 'light')

  return(
    <Box display='flex' flexDirection='column' textColor={colorMode === 'dark' ? 'black' : 'white'} p='2' border='1px' borderColor='#ffd600' borderRadius='lg'>
      <Text>{props.posisi}</Text>
      <Heading bgGradient={props.linearColor} bgClip="text">{props.harga}</Heading>
      <Text fontSize='sm'>{props.keterangan}</Text>

      <List fontSize={{base:'sm', xl:'xs'}} spacing={1} m='2'>
        <ListItem>
          <ListIcon as={AiOutlineUser} color='green.500' />
          {props.credit} credit
          {' '}
          <Tooltip label='1 credit = 1 query text'>
            <InfoIcon boxSize='10px' color='yellow'/>
  							</Tooltip>
        </ListItem>
        <ListItem>
          <ListIcon as={AiOutlineUser} color='green.500' />
							Akses kepada {props.posisi} karyawan.ai
        </ListItem>
        <ListItem>
          <ListIcon as={AiOutlineUser} color='green.500' />
							Akses kepada user knowledge
          {' '}
          <Tooltip label='1 credit = 10,000 karakter'>
            <InfoIcon boxSize='10px' color='yellow'/>
  							</Tooltip>
        </ListItem>

        <ListItem>
          <ListIcon as={AiOutlineUser} color='green.500' />
							Image creation
          {' '}
          <Tooltip label='10 credit = 1 query gambar'>
            <InfoIcon boxSize='10px' color='yellow'/>
  							</Tooltip>
        </ListItem>
        {props?.fiturTambahan?.map((x,u) =>
          <ListItem key={u}>
            <ListIcon as={AiOutlineUser} color='green.500' />
            {x}
          </ListItem>
        )}
      </List>
      <Spacer/>
      <Button bgGradient={props.linearColor} w='full'>Bayar Gaji</Button>
      <Text fontSize='xx-small' textAlign='center'>Trial untuk akses karyawan magang karyawan.ai
        {' '}
        <Tooltip label='Max 3 task per karyawan magang'>
          <InfoIcon boxSize='10px'/>
  					</Tooltip>
      </Text>

				
							
    </Box>
  )
}

export default PricingComponent;