/* eslint-disable react/prop-types */
import { InfoIcon } from '@chakra-ui/icons';
import {
  Icon,
  HStack,
  IconButton,
  Input,
  Stack,
  Switch,
  Spacer,
  Button,
  Center,
  Grid,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
  Text,
  Skeleton,
  Tooltip,
} from '@chakra-ui/react';
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import {
  BsBook,
  BsFilter,
  BsBookFill,
  BsClipboard2Minus,
} from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { clientTypessense } from '../Api/Typesense';
import BackButtons from '../Components/Buttons/BackButtons';
import ChatCard from '../Components/Card/ChatCard';
import { db } from '../Config/firebase';
import useUserStore from '../Hooks/Zustand/Store';
import ChatSidebarComponent from '../Pages/Chat/ChatSidebarComponent';


function LayoutChatAdmin({ children }) {
  const globalState = useUserStore();
  const [config, setConfig] = useState()
  const [loading, setLoading] = useState(false);
  const [customerChat, setCustomerChat] = useState();
  const [searchInput, setSearchInput] = useState('');
  const [searchData, setSearchData] = useState([])


  const [activeChat, setActiveChat] = useState()
  const toast = useToast()


  const navigate = useNavigate()

  const [tabBar, setTabBar] = useState('all')

  useEffect(() => {
    setLoading(true);

    let q;

    if (tabBar === 'all') {
      // Query for all chats
      q = query(
        collection(db, 'chat_customer'),
        where('projectId', '==', globalState.currentProject),
        orderBy('createdAt', 'desc'),
        limit(9)
      );
    } else if (tabBar === 'unread') {
      // Query for unread chats
      q = query(
        collection(db, 'chat_customer'),
        where('projectId', '==', globalState.currentProject),
        where('status', '==', 'unread'),
        orderBy('createdAt', 'desc'),
        limit(9)
      );
    } else if (tabBar === 'negative') {
      // Query for negative sentiment chats
      q = query(
        collection(db, 'chat_customer'),
        where('projectId', '==', globalState.currentProject),
        where('sentiment', '==', 'negative'),
        orderBy('createdAt', 'desc'),
        limit(9)
      );
    }

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const chats = [];
      querySnapshot.forEach((doc) => {
        let newData = { id: doc.id, ...doc.data() };
        newData = { ...newData };
        chats.push(newData);
      });
      setCustomerChat(chats);
      setLoading(false);
    });

    return () => {
      unsubscribe();
      setCustomerChat([])
    };
  }, [globalState?.currentProject, tabBar]);

  const handleSearch = (q) => {
    setSearchInput(q);

    const searchParameters = {
      q: q,
      query_by: 'message',
      filter_by: `projectId:${globalState?.currentProject}`,
      sort_by: '_text_match:desc',
    };
    clientTypessense
      .collections('chat')
      .documents()
      .search(searchParameters)
      .then((x) => {
        const hits = x.hits.map((x) => x.document);
        console.log(hits);
        setSearchData(hits);
      })
      .catch((err) => {
        toast({
          title: 'Deoapp.com',
          duration: 3000,
          description: err.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      });
  };


  const handleNavigate = (data) => {
    navigate(`/chat/${data.id}`)
  }


  return (
    <Grid templateColumns={{ base: '1fr', md: '1fr 2fr 1fr' }}>
      <Stack spacing="4" height="100vh" overflow="hidden" borderEndWidth="1px" pt="6">
        <HStack px="5">
          <BackButtons />
          <Spacer />
          <Text fontSize="md" fontWeight="medium">
            Conversation
          </Text>
        </HStack>
        <HStack p='1'>
          <Input onChange={(e) => handleSearch(e.target.value)} type="text" size="sm" placeholder="Search User / Message" />
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label='Options'
              icon={<BsFilter />}
              // variant='outline'
              boxSize='30px'
            />
            <MenuList>
              {config?.map((x, i) => <MenuItem key={i} textTransform='capitalize' onClick={() => console.log(x?.department)}>{x?.department}</MenuItem>)}
            </MenuList>
          </Menu>
        </HStack>

        <Stack flex="1" overflowY="auto"  >
          <Stack >

            <Stack>

              <HStack color="fg.muted" cursor={'pointer'} spacing={3} alignItems='center' justifyContent={'center'}>
                <HStack borderBottomWidth={2} p={2} borderBottomColor={tabBar === 'all' && 'blue.600'} color={tabBar === 'all' && 'blue.600'} spacing={1} onClick={() => setTabBar('all')}>
                  <Icon as={BsBook} />
                  <Text fontWeight="semibold" textTransform="uppercase" fontSize="xs" cursor='pointer' >
                    All
                  </Text>
                </HStack>

                <HStack spacing={1} borderBottomWidth={2} p={2} borderBottomColor={tabBar === 'unread' && 'blue.600'} color={tabBar === 'unread' && 'blue.600'} cursor={'pointer'} onClick={() => setTabBar('unread')}>
                  <Icon as={BsBookFill} />
                  <Text fontWeight="semibold" textTransform="uppercase" fontSize="xs">
                    Unread
                  </Text>
                </HStack>

                <HStack borderBottomWidth={2} p={2} borderBottomColor={tabBar === 'negative' && 'blue.600'} color={tabBar === 'negative' && 'blue.600'} spacing={1} cursor={'pointer'} onClick={() => setTabBar('negative')}>
                  <Icon as={BsClipboard2Minus} color='red' />
                  <Text fontWeight="semibold" textTransform="uppercase" fontSize="xs">
                    Negative
                  </Text>
                </HStack>
              </HStack>
            </Stack>


            <Stack spacing="4" overflowY="auto" px="3" >

              <Stack>
                {loading ? <Skeleton height='50px' width='full' /> : <></>}

                {searchInput === '' ? (
                  customerChat?.length > 0 && (
                    customerChat?.map((x, i) =>
                      <Stack onClick={() => handleNavigate(x)} key={i}>
                        <ChatCard data={x} setActiveChat={setActiveChat} key={i} />
                      </Stack>
                    )
                  )
                ) : (
                  searchData?.length > 0 && (
                    searchData?.map((x, i) => 
                      <Stack onClick={() => navigate(`/chat/${x.user}-${globalState.currentProject}`, 'xxx')} key={i}>
                        <ChatCard data={x} setActiveChat={setActiveChat} key={i} />
                      </Stack>)
                  )
                )}
                
                <Center>
                  <Button colorScheme='green' mt='2' size='sm'>Load More</Button>
                </Center>
              </Stack>

            </Stack>




          </Stack>

        </Stack>
      </Stack>
      <Stack overflow="hidden">{children}</Stack>
      <Stack overflow="hidden" >
        {activeChat ?
          <ChatSidebarComponent userId={activeChat} />
          :
          <></>
        }
      </Stack>

    </Grid>
  );
}

export default LayoutChatAdmin;
