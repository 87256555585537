import { InfoIcon } from '@chakra-ui/icons'
import { Avatar,Icon, Box, Flex, HStack, Input, Stack, Text, AvatarBadge, Switch, Spacer, Button, Center, Badge, Tooltip, Skeleton, Divider, useColorMode } from '@chakra-ui/react'
import { collection, limit, onSnapshot, orderBy, query, where } from 'firebase/firestore'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { BsBook, BsRobot, BsReverseListColumnsReverse, BsPersonLinesFill, BsWhatsapp } from 'react-icons/bs'
import { useParams } from 'react-router-dom'
import BackButtons from '../../Components/Buttons/BackButtons'
import { db } from '../../Config/firebase'
import useUserStore from '../../Hooks/Zustand/Store'
import { readMore } from '../../Utils/Helper'
import ChatComponent from '../Chat/ChatComponent'
import ChatSidebarComponent from '../Chat/ChatSidebarComponent'

function KaryawanMainPage() {
  const globalState = useUserStore()
  const [loading,setLoading]=useState(false)
  const [customerChat,setCustomerChat]=useState()
  const [activeChat,setActiveChat]=useState()

  const param=useParams()


  useEffect(() => {
    setLoading(true)
    const q = query(collection(db, 'chat_customer'), 
      where('projectId', '==', globalState.currentProject),
      //   where('user','==',globalState.uid),
      orderBy('createdAt','desc'),
      limit(10));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const chats = [];
      querySnapshot.forEach((doc) => {
        chats.push(doc.data());
      });
      setCustomerChat(chats)
      setLoading(false)
      // console.log(chats)
    })
  
    return () => {
      unsubscribe()
    }
  }, [globalState.currentProject])
  
  return (
    <Flex height="100vh" overflow="hidden">
      <Stack spacing="4" width="320px" borderEndWidth="1px" pt="6">
        <HStack px="5">
          <BackButtons/>
          <Text fontSize="md" fontWeight="medium">
            Conversation
          </Text>
          <Spacer/>
          <Text>AI</Text>     
          <Switch/>
          <Tooltip label='Run AI on all conversation' fontSize='md'>
            <InfoIcon />
          </Tooltip>

        </HStack>

        <Stack spacing="4" flex="1" overflowY="auto" px="5" pb="5">
          <Stack spacing="2">

            <HStack color="fg.muted">
              <Icon as={BsPersonLinesFill} />
              <Text fontWeight="semibold" textTransform="uppercase" fontSize="xs" cursor='pointer' onClick={() => console.log('all')}>
                {param.id}
              </Text>
            </HStack>
            <Divider/>  

            <HStack color="fg.muted">
              <Icon as={BsRobot} />
              <Text fontWeight="semibold" textTransform="uppercase" fontSize="xs" cursor='pointer' onClick={() => console.log('all')}>
                Bot
              </Text>
            </HStack>
            <Divider/>    

            <HStack color="fg.muted">
              <Icon as={BsBook} />
              <Text fontWeight="semibold" textTransform="uppercase" fontSize="xs" cursor='pointer' onClick={() => console.log('all')}>
                Knowledge
              </Text>
            </HStack>
            <Divider/> 

            <HStack color="fg.muted">
              <Icon as={BsReverseListColumnsReverse} />
              <Text fontWeight="semibold" textTransform="uppercase" fontSize="xs" cursor='pointer' onClick={() => console.log('all')}>
                Logs
              </Text>
            </HStack>
            <Divider/>
            
            <HStack color="fg.muted">
              <Icon as={BsBook} />
              <Text fontWeight="semibold" textTransform="uppercase" fontSize="xs" cursor='pointer' onClick={() => console.log('all')}>
                Account
              </Text>
            </HStack>
            <Divider/>       
            <Input type='text' size='sm' placeholder='Search User / Message'/>


            <Stack spacing="0" mx="-4">
              {loading?<Skeleton height='50px' width='full'/>:<></>}
              {customerChat?.map((x,i) =>
                <HStack borderBottom='1px' cursor='pointer' onClick={() => setActiveChat(x.user)} key={i} align="flex-start" gap="3" p='2' _hover={{ bg: 'bg.muted' }} >
                  <Box pt="1">
                    <Avatar size="sm" src={x?.image} name={x?.name?x.name:x.user}>
                      <AvatarBadge />
                    </Avatar>
                  </Box>
                  <Stack spacing="0" fontSize="sm" flex="1" isTruncated>
                    <HStack spacing="1">
                      <Text fontWeight="medium" flex="1">
                        {readMore(x?.name?x.name:x.user,20)}
                      </Text>
                      <Icon as={BsWhatsapp} color='green'/>
                      
                    </HStack>
                    <Box color="fg.subtle" isTruncated>
                      {x.message}
                    </Box>
                    <HStack>
                      <Badge colorScheme="red" fontSize='xx-small'>
                          Score -10
                      </Badge>
                      <Spacer/>
                      <Text color="fg.subtle" fontSize="xs">
                        {moment.unix(x.createdAt.seconds).fromNow() }
                      </Text>
                    </HStack>
                  </Stack>
                </HStack>
              )}
              <Center>
                <Button colorScheme='green' mt='2' size='sm'>Load More</Button>
              </Center>
            </Stack>
          </Stack>
          

          <Stack mt="2" spacing="4">
            {/* <ChatGroupHeader icon={BsChatTextFill}>Messages</ChatGroupHeader> */}
            {/* <Stack spacing="0" mx="-4">
              {messages.map((message, index) => (
                <ChatMessage key={index} data={message} />
              ))}
            </Stack> */}
          </Stack>
        </Stack>
      </Stack>

      <Flex direction="column" flex="1">
        {activeChat?
          <ChatComponent userId={activeChat}/>
          :
          <></>
        }
      </Flex>

      <Stack width="320px" borderStartWidth="1px" pt="1" m='1'>
        
        {activeChat?
          <ChatSidebarComponent userId={activeChat}/>
          :
          <></>
        }
      </Stack>

    </Flex>
  )
}

export default KaryawanMainPage