import React, { useEffect, useState } from 'react'
import ChatComponent from './ChatComponent'
import { useParams } from 'react-router-dom'
import { Box, Flex, Stack, Text } from '@chakra-ui/react'
import { getSingleDocumentFirebase } from '../../Api/firebaseApi'
import useUserStore from '../../Hooks/Zustand/Store'
import { FaLock } from 'react-icons/fa'
import ChatComponentAdmin from './ChatComponentAdmin'

function ChatConversationAdmin(props) {
  const [customer, setCustomer] = useState()
  const globalState = useUserStore()
  const param = useParams()

  const getKaryawan = async () => {
    try {


      const result = await getSingleDocumentFirebase('chat_customer', param.id)
      console.log(result, 'ini resut')
      setCustomer(result)
    } catch (error) {
      console.log(error.message)
    }
  }

  useEffect(() => {
    getKaryawan()
    return () => {
    }
  }, [param.id])


  return (
    <Flex height="100vh" overflow="hidden">
      <Flex direction="column" flex="1" position={'relative'}>


        {customer?.user ?
          <ChatComponentAdmin data={customer} />
          :
          <></>
        }
      </Flex>

    </Flex>
  )
}

export default ChatConversationAdmin