/* eslint-disable no-useless-escape */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import { Icon, InfoIcon } from '@chakra-ui/icons'
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Avatar, AvatarGroup, Box, Button, Flex, HStack, IconButton, Input, InputGroup, InputLeftElement, InputRightElement, Spacer, Spinner, Stack, Switch, Text, Tooltip, useToast } from '@chakra-ui/react'
import { addDoc, collection, getCountFromServer, limit, onSnapshot, orderBy, query, where } from 'firebase/firestore'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { BsMicFill, BsPaperclip } from 'react-icons/bs'
import { updateDocumentFirebase } from '../../Api/firebaseApi'
import { db } from '../../Config/firebase'
import useUserStore from '../../Hooks/Zustand/Store'
import { readMore } from '../../Utils/Helper'
import { FaRegAngry, FaRegSmile } from 'react-icons/fa';
import { FaRegFaceMeh } from 'react-icons/fa6';
import parse from 'html-react-parser';



function ChatComponentAdmin(props) {
  if (!props) return

  const [chat, setChat] = useState([])
  const [messageLoading, setMessageLoading] = useState(false)
  const [inputValue, setInputValue] = useState()
  const globalState = useUserStore()

  const messagesEndRef = useRef(null)



  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const isActiveRef = useRef(props.data.type === 'AI' ? true : false);





  const toast = useToast()


  const updateChatCustomer = async (data) => {

    const message = {
      message: data?.message,
      characters: data?.characters,
      createdAt: moment().unix(),
      lastUpdated: new Date(),
      status: 'read',
    }
    try {
      await updateDocumentFirebase('chat_customer', props?.data?.id, message)
      setIsActive(isActiveRef.current); // Perbarui state lokal

    } catch (error) {
      console.log(error.message)
    }
  }

  const handleMessage = async () => {
    setMessageLoading(true);
    setInputValue('');

    try {
      // insert to firestore human
      const insertData = {
        companyId: globalState.currentCompany,
        projectId: globalState.currentProject,
        message: inputValue,
        characters: inputValue.length,
        type: 'human',
        chatId: props?.data?.id,
        user: globalState?.uid,
        status: 'read',
        medium: 'web',
        createdAt: moment().unix(),
        lastUpdated: new Date()
      };
      const userDoc = await addDoc(collection(db, 'chat'), insertData);

      if (userDoc) await updateChatCustomer(insertData);


      setMessageLoading(false);
      // scrollToBottom();

      return result;
    } catch (error) {
      setMessageLoading(false);
      // Handle other error handling logic here
    }
  };


  const scrollToBottom = () => {
    // console.log(messagesEndRef.current)
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
  }


  const getData = async () => {
    if (!props?.data?.id) return;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const limitValue = startIndex + itemsPerPage;
    const q = query(
      collection(db, 'chat'),
      where('projectId', '==', globalState.currentProject),
      where('chatId', '==', props.data.id),
      orderBy('createdAt', 'desc'),
      limit(limitValue)
    );

    return new Promise((resolve, reject) => {
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const chats = [];
        querySnapshot.forEach((doc) => {
          chats.push(doc.data());
        });
        setChat(chats); // Set the chat data once outside the loop

        const collectionRef = collection(db, 'chat');
        const countQuery = query(
          collectionRef,
          where('projectId', '==', globalState.currentProject),
          // where('user', '==', globalState.uid),
          where('chatId', '==', props.data.id),
        );

        getCountFromServer(countQuery)
          .then((snapshot) => {
            const assessmentLength = snapshot.data().count;
            setTotalPages(Math.ceil(assessmentLength / itemsPerPage));
            resolve(); // Resolve the promise when both data and count are fetched
          })
          .catch((error) => {
            reject(error); // Reject the promise in case of an error
          });
      });

      // Return the unsubscribe function to clean up the listener when needed
      return unsubscribe;
    });

  };

  useEffect(() => {
    getData();

    return () => {
      setChat([]);
      setMessageLoading(false);
      // messagesEndRef.current=null
    };
  }, [props?.data?.id, currentPage]);

  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
    scrollToBottom()
    //buatkan fungsi lihat lebih banyak disiini menggunakan table contact has more yang sudah ad a
  }

  const handleAiActive = async () => {
    isActiveRef.current = !isActiveRef.current;


    const data = {
      type: isActiveRef.current === true ? 'AI' : 'human'
    }

    try {
      await updateDocumentFirebase('chat_customer', props?.data?.id, data)
      toast({
        title: 'Deoapp.com',
        description: `${data.type} mode has active`,
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });
    } catch (error) {
      console.log(error, 'ini error')
    }
  }



  return (
    <>
      <Flex borderBottomWidth="1px" px="4" py="4">
        <HStack spacing="4" flex="1">
          <Avatar name={props?.data?.user} src={props?.data?.user} />
          <Box>
            <Text >{readMore(props?.data?.user, 25)}</Text>
            <Text fontSize='sm'>{readMore(props?.data?.title, 25)}</Text>

          </Box>

          <Spacer />

          <Input type='text' placeholder={`Search Message from ${props?.data?.name}`} />
          <Text>AI</Text>
          <Switch
            defaultChecked={isActiveRef.current}
            onChange={handleAiActive}
          />
          <Tooltip label='Run AI for this conversation (only for positive sentiment users)' fontSize='sm'>
            <InfoIcon />
          </Tooltip>
        </HStack>


      </Flex>
      {currentPage < totalPages ? (
        <Stack>
          <Button onClick={handleLoadMore}>See more</Button>
        </Stack>
      )
        :
        (
          <></>
        )}

      <Stack flex="1" overflow="auto" px="5" py="4"
        direction={'column-reverse'}
      >


        {chat?.map((x, i) =>
          <Stack key={i} m='1'

          >

            <HStack gap="5">

              {x.user === globalState?.uid ? <Spacer /> : <></>}

              <Stack spacing="1" bgColor={x.user !== globalState?.uid ? '#1F2C33' : '#015C4B'} maxW='xl' borderRadius='lg' p='2' textColor='white'>
                {x?.media && (
                  <Stack fontSize={'2xs'}>
                    {parse(x?.media, {
                      replace: (domNode) => {
                        if (domNode.type === 'text') {
                          const textWithLinksReplaced = domNode.data.replace(
                            /(\b(?:https?:\/\/|www\.)[^\s]+)/g,
                            (match) => {
                              const url = match.startsWith('http')
                                ? match
                                : `https://${match}`;
                              return `<a href="${url}" target="_blank">${match}</a>`;
                            }
                          );
                          return parse(textWithLinksReplaced);
                        }
                      },
                    })}
                  </Stack>
                )}
                <HStack spacing="2">
                  <Stack alignItems={'flex-start'} justifyContent='flex-start' p={1}>
                    {x?.sentiment && (
                      x.sentiment === 'positive' ? (
                        <FaRegSmile color='green' size={20} />
                      ) : (
                        x.sentiment === 'negative' ?
                          <FaRegAngry  color='red' size={20} /> : <FaRegFaceMeh color='white' size={20} />
                      )

                    )}
                  </Stack>
                  <Box color="fg.muted" lineHeight="tall" >
                    <div dangerouslySetInnerHTML={{ __html: x.message.replace(/(?:\r\n|\r|\n)/g, '<br>') }} />
                    <HStack>
                      <Text fontSize='xx-small' textAlign={x.user !== globalState?.uid ? 'end' : 'start'}>{moment.unix(x.createdAt).fromNow()}</Text>
                      <Text fontSize='xx-small' textAlign='end'>By {x.user}</Text>


                    </HStack>
                  </Box>
                </HStack>
              </Stack>

              {x.user !== globalState?.uid ? <Spacer /> : <></>}

            </HStack>
          </Stack>)}
        {/* {inputLoading?  <Skeleton height='50px' startColor='blue.100' endColor='blue.300' width='250px' borderRadius='md'/>:<></>} */}
        <div ref={messagesEndRef} />
      </Stack>

      <Box bg="bg.subtle" py="4" px="5" borderTopWidth="1px">
        {props?.data?.promptReccomendation ?

          <Accordion allowToggle>
            {props?.data?.promptReccomendation?.map((x, i) =>
              <AccordionItem key={i + 1}>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex='1' textAlign='left'>
                      Rekomendasi chat#{i + 1}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  {x}
                </AccordionPanel>
              </AccordionItem>
            )}



          </Accordion>
          :
          <></>
        }

        <InputGroup>
          <InputLeftElement>
            <IconButton
              size="sm"
              aria-label="Record voice"
              icon={<Icon as={BsMicFill} fontSize="md" />}
              variant="ghost"
            />
          </InputLeftElement>
          <Input
            placeholder="Send a message..."
            fontSize="sm"
            value={inputValue}
            isDisabled={messageLoading}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                // handleSave();
                // ChatComponent()
                handleMessage()
                // console.log('enter')
              }
            }}
          />
          <InputRightElement>
            {messageLoading ?
              <Spinner />
              :
              <IconButton
                size="sm"
                variant="ghost"
                aria-label="Send message"
                icon={<Icon as={BsPaperclip} fontSize="md" />}
              />
            }

          </InputRightElement>
        </InputGroup>
      </Box>
    </>
  )
}

export default ChatComponentAdmin