/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  VStack,
  HStack,
  Divider,
  Text,
  IconButton,
  Select,
  Stack,
  Grid,
  SimpleGrid,
  Checkbox,
  Image,
  useToast,
  Container,
  Flex,
  Heading,
  Switch,
  Spacer,
  Center,
  CloseButton, // Tambahkan import untuk Checkbox
} from '@chakra-ui/react';
import { MdDelete, MdOutlinePermMedia } from 'react-icons/md';
import CreatableSelece from 'react-select/creatable';
import {
  collection,
  doc,
  limit,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore';
import { db } from '../../Config/firebase';
import {
  addDocumentFirebase,
  arrayRemoveFirebase,
  arrayUnionFirebase,
  getCollectionFirebase,
  getSingleDocumentFirebase,
  setDocumentFirebase,
  updateDocumentFirebase,
  uploadFile,
} from '../../Api/firebaseApi';
import useUserStore from '../../Hooks/Zustand/Store';
import { formatFrice } from '../../Utils/numberUtil';
import BackButtons from '../../Components/Buttons/BackButtons';
import {
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import moment from 'moment';

function FormPageProduct() {
  const [searchParams, setSearchParams] = useSearchParams();

  const idProject = searchParams.get('id');

  const [isShipping, setIsShipping] = useState(true);
  const [projectList, setProjectList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [projectId, setProjectId] = useState('');
  const [projectName, setProjectName] = useState('');
  const [category, setCategory] = useState([]);
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [weight, setWeight] = useState(1000);
  const [files, setFiles] = useState([]); // Initialize with an empty array
  const [filesImage, setFilesImage] = useState([]);
  const [details, setDetails] = useState([]);
  const [formId, setFormId] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [modules, setModules] = useState([]); // Tambahkan state untuk checkbox modules
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [filesImageLogo, setFilesImageLogo] = useState([]);
  const [filesLogo, setFilesLogo] = useState([]);

  const [categoryData, setCategoryData] = useState([]);
  const [checkPrice, setCheckPrice] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [formPage, setFormPage] = useState(false);
  const [queries, setQueries] = useState('');
  const [currentForm, setCurrentForm] = useState();
  const [lastFormId, setLastFormId] = useState('');
  const [priceEnd, setPriceEnd] = useState('');
  const [imageUrl, setImageUrl] = useState(null);
  const [logoUrl, setLogoUrl] = useState(null);
  const [detailProduct, setDetailProduct] = useState(false);
  const [stock, setStock] = useState(0);
  const [volume, setVolume] = useState(0);
  const [dataForm, setDataForm] = useState();
  const [sku, setSku] = useState('')
  const globalState = useUserStore();
  const toast = useToast();
  const navigate = useNavigate();
  const params = useParams();
  const [dataProduct, setDataProduct] = useState({});
  const companyId = globalState.currentCompany;

  const [isVariant, setIsVariant] = useState(false);
  const [variants, setVariants] = useState([]);
  const [newVariant, setNewVariant] = useState({ sku_variant: '', price_variant: 0, title_variant: '', stock_variant: '', image_variant: '' });

  const handleAddVariant = () => {
    if (newVariant.sku_variant && newVariant.title_variant && newVariant.stock_variant) { // Check if values are not undefined or empty
      setVariants([...variants, newVariant]);
      setNewVariant({ sku_variant: '', price_variant: 0, title_variant: '', stock_variant: '', image_variant: '' });
    }
  };

  const handleRemoveVariant = (index) => {
    const updatedVariants = [...variants];
    updatedVariants.splice(index, 1);
    setVariants(updatedVariants);
  };

  const getProject = () => {
    const res = globalState?.projects?.find(
      (e) => e.id === globalState?.currentProject
    );
    setProjectId(res?.id);
    setProjectName(res?.name);
  };

  const getListing = async () => {
    const res = await getSingleDocumentFirebase('listings_product', idProject);
    setTitle(res.title);
    setStock(res.stock);
    setWeight(res.weight);
    setVolume(res.volume);
    setDescription(res.description);
    setPrice(res.price);
    setSku(res.sku)
    setFiles(res?.image || []);
    setImageUrl(res?.image);
    setFilesLogo(res?.logo || []);
    setLogoUrl(res?.logo);
    setModules(res.modules);
    setIsActive(res.is_active);
    setProjectName(res.projectName);
    setPriceEnd(res.priceEnd);
    setProjectId(res.projectId);
    setFormId(res.formId);
    setLastFormId(res.formId);
    setVariants(res?.variants)
    setIsVariant(res?.isVariant)

    const cat = res.category;
    const arr = [];
    cat.map((c) => {
      arr.push({ value: c, label: c });
    });

    setSelectedCategory(arr);
    setDetails(res.details);
    if (res.priceEnd) {
      setCheckPrice(true);
    }

    //get form
    const conditions = [
      { field: 'product_used', operator: 'array-contains', value: idProject },
    ];
    const productForm = await getCollectionFirebase('forms', conditions);
    setCurrentForm(productForm);
  };

  const getDataForms = async () => {
    try {
      const q = query(
        collection(db, 'forms'),
        where('projectId', '==', globalState.currentProject)
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const data = [];
        snapshot.forEach((doc) => {
          const docData = doc.data();
          if (
            (!docData.ticket_used || docData.ticket_used.length === 0) &&
            (!docData.product_used || docData.product_used.length === 0) &&
            (!docData.membership_used || docData.membership_used.length === 0)
          ) {
            data.push({ id: doc.id, ...docData });
          }
        });

        setDataForm(data);
      });

      return () => {
        unsubscribe();
      };
    } catch (error) {
      toast({
        title: 'Error',
        description: error,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const getData = async () => {
    try {
      const q = query(
        collection(db, 'projects'),
        where('companyId', '==', companyId),
        limit(25)
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const data = [];
        snapshot.forEach((doc) => {
          const docData = doc.data();
          data.push({ id: doc.id, ...docData });
        });
        setProjectList(data);
      });

      return () => {
        unsubscribe();
      };
    } catch (error) {
      console.log(error, 'ini error');
    }
  };

  const getCategory = async () => {
    try {
      const unsubscribe = await onSnapshot(
        doc(db, 'categories', globalState?.currentProject),
        (docCat) => {
          setCategories({ id: docCat.id, ...docCat.data() });
        }
      );
      return () => {
        unsubscribe();
      };
    } catch (error) {
      console.log(error, 'ini error');
    }
  };

  const getCategoryList = async () => {
    try {
      const arr = [];
      await Promise.all(
        categories?.data?.map(async (x) => {
          const result = await getSingleDocumentFirebase(
            `categories/${globalState?.currentProject}/${x}`,
            'data'
          );
          arr.push(...result?.category);
        })
      );
      const uniqueValues = Array.from(new Set(arr)); // Filter unique values
      setCategoryList(uniqueValues);
    } catch (error) {
      console.log(error);
    }
  };

  const getSelectedCategory = (value) => {
    setSelectedCategory(value);
  };

  const handleDBInputChange = (newValue) => {
    setQueries(newValue);
  };

  useEffect(() => {
    getData();
    getCategory();
    getCategoryList();
    getProject();
    getDataForms();
    if (idProject) {
      getListing();
    }
  }, [globalState.currentCompany, categories?.data?.length]);

  const handleSubmit = async (e) => {
    // e.preventDefault();
    setLoading(true);

    const newListing = {
      title: title,
      description: description,
      type: 'product',
      category: selectedCategory?.map((categories) =>
        categories?.value.toLowerCase()
      ),
      sku: sku,
      price: parseInt(price),
      priceEnd: priceEnd,
      projectId: projectId,
      projectName: projectName?.toLowerCase(),
      details: details.map((detail) => ({
        key: detail.key.toLowerCase(),
        value: detail.value.toLowerCase(),
      })),
      isVariant: isVariant,
      variants: variants,
      stock: stock,
      weight: weight,
      volume: volume,
      is_active: isActive,
      is_shipping: isShipping,
      formId: formId,
      modules: modules.map((module) => module.toLowerCase()),
    };


    if (filesImage[0]) {
      const resImage = await uploadFile(
        `${title}-${moment(new Date()).valueOf()}`,
        'listings_product',
        filesImage[0]
      );
      newListing.image = resImage;
    }
    if (filesImageLogo[0]) {
      const resImage = await uploadFile(
        `${title}-${moment(new Date()).valueOf()}-logo`,
        'listings_product',
        filesImageLogo[0]
      );
      newListing.logo = resImage;
    }


    try {
      const docID = await addDocumentFirebase(
        'listings_product',
        newListing,
        companyId
      );
      console.log('ID Dokumen Baru:', docID);

      if (docID) {
        const docName = projectId;
        const categoryField = 'data';
        const categoryValues = modules;

        try {
          await arrayUnionFirebase(
            'categories',
            docName,
            categoryField,
            categoryValues
          );
        } catch (error) {
          // Jika dokumen belum ada, tambahkan dokumen baru dengan nilai awal
          if (error.message.includes('No document to update')) {
            await setDocumentFirebase(
              'categories',
              docName,
              { [categoryField]: categoryValues },
              companyId
            );
          } else {
            console.log('Terjadi kesalahan:', error);
          }
        }

        for (const module of modules) {
          const subCollectionName = `categories/${docName}/${module}`;
          const subDocName = 'data';
          const subField = 'category';
          const subValues = selectedCategory.map((categories) =>
            categories?.value.toLowerCase()
          );

          try {
            await arrayUnionFirebase(
              subCollectionName,
              subDocName,
              subField,
              subValues
            );
          } catch (error) {
            // Jika dokumen belum ada, tambahkan dokumen baru dengan nilai awal
            if (error.message.includes('No document to update')) {
              await setDocumentFirebase(
                subCollectionName,
                subDocName,
                { [subField]: subValues },
                companyId
              );
            } else {
              console.log('Terjadi kesalahan:', error);
            }
          } finally {
            setLoading(false);
            // navigate(-1);
            window.location.href = '/products';
            toast({
              title: 'Deoapp.com',
              description: 'success add new listing',
              status: 'success',
              position: 'top-right',
              isClosable: true,
            });
          }
        }
      }

      if (newListing?.formId) {
        const collectionName = 'forms';
        const docName = dataProduct.formId;
        const field = 'product_used';
        const values = [docID];

        try {
          const result = await arrayUnionFirebase(
            'forms',
            formId,
            field,
            values
          );
          console.log(result); // Pesan toast yang berhasil
          toast({
            title: 'Deoapp.com',
            description: `success add new product with document id ${docID}`,
            status: 'success',
            position: 'top-right',
            isClosable: true,
          });
          navigate('/products');
        } catch (error) {
          console.log('Terjadi kesalahan:', error);
        }
      } else {
        toast({
          title: 'Deoapp.com',
          description: `success add new product with document id ${idProject}`,
          status: 'success',
          position: 'top-right',
          isClosable: true,
        });
        navigate('/products');
      }

      console.log('berhasil');

      // Reset nilai input setelah submit
      setTitle('');
      setDescription('');
      setCategory([]);
      setPrice('');
      setFilesImageLogo([]);
      setFiles([]);
      setFilesImage([]);
      setFilesLogo([]);
      setDetails([]);
      setStock('');
      setWeight(1000);
      setVolume(10);
      setIsActive(true);
      setSelectedCategory([]);
      setModules([]);
      setPriceEnd('');
      setFormId('');
    } catch (error) {
      console.log('Terjadi kesalahan:', error);
    }
  };

  const handleFileInputChange = (event) => {
    const { files: newFiles } = event.target;

    if (newFiles.length) {
      const newFileArray = [];
      for (let i = 0; i < newFiles.length; i++) {
        const reader = new FileReader();
        reader.readAsDataURL(newFiles[i]);
        reader.onload = () => {
          newFileArray.push({
            file: reader.result,
            fileName: newFiles[i].name,
            description: newFiles[i].type,
          });
          setFiles(newFileArray);

          if (i === 0) {
            setImageUrl(reader.result);
          }
        };
      }
      setFilesImage(newFiles);
    }
  };

  const handleFileLogoInputChange = (event) => {
    const { files: newFiles } = event.target;
    if (newFiles?.length) {
      const newFileArray = [...filesLogo];
      for (let i = 0; i < newFiles?.length; i++) {
        const reader = new FileReader();
        reader.readAsDataURL(newFiles[i]);
        reader.onload = () => {
          newFileArray.push({
            file: reader.result,
            fileName: newFiles[i].name,
            description: newFiles[i].type,
          });
          setFilesLogo(newFileArray);

          if (i === 0) {
            setLogoUrl(reader.result);
          }
        };
      }
      setFilesImageLogo(newFiles);
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const newListing = {
      title: title,
      description: description,
      category: selectedCategory.map((categories) =>
        categories?.value.toLowerCase()
      ),
      price: parseInt(price),
      priceEnd: priceEnd,
      projectId: projectId,
      projectName: projectName.toLowerCase(),
      details: details.map((detail) => ({
        key: detail.key.toLowerCase(),
        value: detail.value.toLowerCase(),
      })),
      stock: stock,
      weight: weight,
      is_active: isActive,
      is_shipping: isShipping,
      formId: formId || '',
      modules: modules.map((module) => module.toLowerCase()),
    };

    if (filesImage[0]) {
      const resImage = await uploadFile(
        `${title}-${moment(new Date()).valueOf()}`,
        'listings_product',
        filesImage[0]
      );
      newListing.image = resImage;
    }
    if (filesImageLogo[0]) {
      const resImage = await uploadFile(
        `${title}-${moment(new Date()).valueOf()}-logo`,
        'listings_product',
        filesImageLogo[0]
      );
      newListing.logo = resImage;
    }
    const collectionName = 'listings_product';
    // const data = newListing;

    try {
      const docID = await updateDocumentFirebase(
        'listings_product',
        idProject,
        newListing
      );
      console.log('ID Dokumen Baru:', docID);

      if (formId !== '') {
        const getForm = await getSingleDocumentFirebase('forms', formId);

        if (!getForm.product_used || getForm.product_used?.length === 0) {
          await updateDocumentFirebase('forms', formId, {
            product_used: [idProject],
          });
        }
      }

      // if (!formId) {
      //   const newArr = await arrayUnionFirebase(
      //     "forms",
      //     formId,
      //     "product_used",
      //     [idProject]
      //   );
      // }

      if (docID && formId !== lastFormId) {
        try {
          const result = await arrayRemoveFirebase(
            'forms',
            lastFormId,
            'product_used',
            [idProject]
          );

          const newArr = await arrayUnionFirebase(
            'forms',
            formId,
            'product_used',
            [idProject]
          );

          console.log(newArr);
        } catch (error) {
          console.log(error);
        }
      }

      if (docID) {
        const categoryCollectionName = 'categories';
        const docName = projectId;
        const categoryField = 'data';
        const categoryValues = modules;

        try {
          await arrayUnionFirebase(
            'categories',
            docName,
            categoryField,
            categoryValues
          );
        } catch (error) {
          // Jika dokumen belum ada, tambahkan dokumen baru dengan nilai awal
          if (error.message.includes('No document to update')) {
            await setDocumentFirebase(
              categoryCollectionName,
              docName,
              { [categoryField]: categoryValues },
              companyId
            );
          } else {
            console.log('Terjadi kesalahan:', error);
          }
        }

        for (const module of modules) {
          const subCollectionName = `${categoryCollectionName}/${docName}/${module}`;
          const subDocName = 'data';
          const subField = 'category';
          const subValues = selectedCategory.map((categories) =>
            categories?.value.toLowerCase()
          );

          try {
            await arrayUnionFirebase(
              subCollectionName,
              subDocName,
              subField,
              subValues
            );
          } catch (error) {
            // Jika dokumen belum ada, tambahkan dokumen baru dengan nilai awal
            if (error.message.includes('No document to update')) {
              await setDocumentFirebase(
                subCollectionName,
                subDocName,
                { [subField]: subValues },
                companyId
              );
            } else {
              console.log('Terjadi kesalahan:', error);
            }
          } finally {
            setLoading(false);
            navigate(-1);
            toast({
              title: 'Deoapp.com',
              description: 'success edit listing',
              status: 'success',
              position: 'top-right',
              isClosable: true,
            });
          }
        }
      }
    } catch (error) {
      console.log('Terjadi kesalahan:', error);
    }
  };

  const handleAddDetail = () => {
    setDetails([...details, { key: '', value: '' }]);
  };

  const handleRemoveDetail = (index) => {
    const updatedDetails = [...details];
    updatedDetails.splice(index, 1);
    setDetails(updatedDetails);
  };

  const handleDetailChange = (index, key, value) => {
    const updatedDetails = [...details];
    updatedDetails[index] = { key, value };
    setDetails(updatedDetails);
  };

  const handleModulesChange = (event) => {
    const { value, checked } = event.target;

    if (checked) {
      setModules([...modules, value]);
    } else {
      const updatedModules = modules.filter((module) => module !== value);
      setModules(updatedModules);
    }
  };


  const loadOptionsDB = (category) => {
    const arr = [];
    category.map((item) => {
      arr.push({ value: item, label: item });
    });
    setCategoryData(arr);
  };

  useEffect(() => {
    loadOptionsDB(categoryList);
  }, [categoryList.length, selectedCategory.length]);

  return (
    <>
      <Stack>
        <BackButtons />
      </Stack>

      {detailProduct === false && formPage === false ? (
        <Container
          gap={5}
          mt={0}
          maxW={'container.lg'}
          bg={'white'}
          minH={'500px'}
          bgColor={'white'}
          p={[1, 1, 5]}
          spacing={5}
          borderRadius="md"
          shadow={'md'}
          mb={2}
        >

          <VStack spacing={4} align={'left'} w="100%">
            <Flex
              justify={'space-between'}
              w="full"
              gap={5}
              justifyItems={'center'}
              alignContent={'center'}
            >
              <FormControl id="image" isRequired>
                <HStack>
                  {/* {files?.length > 0 ? ( */}
                  {imageUrl ? (
                    <Stack alignItems={'center'}>
                      <Image
                        src={imageUrl}
                        boxSize="100%"
                        maxWidth={300}
                        borderRadius="xl"
                        alt={idProject ? title : files[0]?.name}
                        shadow="sm"
                      />
                      <Flex>
                        <Input
                          type="file"
                          onChange={handleFileInputChange}
                          display="none"
                          id="fileInput"
                        />

                        <label htmlFor="fileInput">
                          <HStack cursor="pointer">
                            <Stack>
                              <MdOutlinePermMedia />
                            </Stack>
                            <Text
                              fontSize="sm"
                              color="blue.600"
                              fontStyle="italic"
                            >
                              Add Image thumbnail
                            </Text>
                          </HStack>
                        </label>
                      </Flex>
                    </Stack>
                  ) : (
                    <Flex
                      border={'2px'}
                      borderRadius={'md'}
                      borderStyle={'dashed'}
                      borderColor={'gray.300'}
                      h={250}
                      w={300}
                      justifyContent={'center'}
                      alignItems={'center'}
                    >
                      <Input
                        type="file"
                        onChange={handleFileInputChange}
                        display="none"
                        id="fileInput"
                      />

                      <label htmlFor="fileInput">
                        <HStack cursor="pointer">
                          <Stack>
                            <MdOutlinePermMedia />
                          </Stack>
                          <Text
                            fontSize="sm"
                            color="blue.600"
                            fontStyle="italic"
                          >
                            Add Image thumbnail
                          </Text>
                        </HStack>
                      </label>
                    </Flex>
                  )}
                </HStack>
              </FormControl>



              <FormControl id="logo" isRequired>
                <HStack>
                  {/* {filesLogo?.length > 0 ? ( */}
                  {logoUrl ? (
                    <Stack alignItems={'center'}>
                      <Image
                        src={logoUrl}
                        boxSize="100%"
                        maxWidth={300}
                        borderRadius="xl"
                        alt={idProject ? `${title}-logo` : filesLogo[0].name}
                        shadow="sm"
                      />
                      <Input
                        type="file"
                        onChange={handleFileLogoInputChange}
                        display="none"
                        id="fileInputLogo"
                      />

                      <label htmlFor="fileInputLogo">
                        <HStack cursor="pointer">
                          <Stack>
                            <MdOutlinePermMedia />
                          </Stack>
                          <Text
                            fontSize="sm"
                            color="blue.600"
                            fontStyle="italic"
                          >
                            Add Image logo
                          </Text>
                        </HStack>
                      </label>
                    </Stack>
                  ) : (
                    <Flex
                      border={'2px'}
                      borderRadius={'md'}
                      borderStyle={'dashed'}
                      borderColor={'gray.300'}
                      h={250}
                      w={300}
                      justifyContent={'center'}
                      alignItems={'center'}
                    >
                      <Input
                        type="file"
                        onChange={handleFileLogoInputChange}
                        display="none"
                        id="fileInputLogo"
                      />

                      <label htmlFor="fileInputLogo">
                        <HStack cursor="pointer">
                          <Stack>
                            <MdOutlinePermMedia />
                          </Stack>
                          <Text
                            fontSize="sm"
                            color="blue.600"
                            fontStyle="italic"
                          >
                            Add Image logo
                          </Text>
                        </HStack>
                      </label>
                    </Flex>
                  )}
                </HStack>
              </FormControl>
            </Flex>

            <FormControl id="title" isRequired>
              <FormLabel>Title:</FormLabel>
              <Input
                type="text"
                w="100%"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormControl>
            <FormControl id="description" isRequired>
              <FormLabel>Description:</FormLabel>
              <Textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormControl>

            <FormControl id="sku" isRequired>
              <FormLabel>Sku:</FormLabel>
              <Input
                type={'text'}
                value={sku}
                onChange={(e) => setSku(e.target.value)}
              />
            </FormControl>


            <FormControl id="category" isRequired>
              <FormLabel>Category</FormLabel>
              <Box width={'full'}>
                <CreatableSelece
                  isClearable={false}
                  value={selectedCategory.filter((option) => option.value)}
                  isMulti
                  placeholder="Select or Create new ..."
                  name="db-react-select"
                  options={categoryData}
                  className="react-select"
                  classNamePrefix="select"
                  onChange={getSelectedCategory}
                  onInputChange={handleDBInputChange}
                />
                
              </Box>
            </FormControl>

            <FormControl mt="5" id="Project" isRequired>
              <FormLabel>Project</FormLabel>
              <Input value={projectName} variant={'unstyled'} disabled />
            </FormControl>

            <HStack w="100%" gap="2">
              <FormControl id="price" isRequired>
                <FormLabel>Price Start</FormLabel>
                <HStack alignItems={'center'} justifyContent="center">
                  <Text>Rp.</Text>
                  <Input
                    w={'auto'}
                    type="number"
                    size={'sm'}
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                  <Spacer />
                  <Text fontWeight={500}>
                    Rp.{formatFrice(parseFloat(price || 0))}
                  </Text>
                </HStack>
              </FormControl>

              <Center height="50px">
                <Divider
                  orientation="vertical"
                  fontWeight={'bold'}
                  color="black"
                />
              </Center>

              {checkPrice && (
                <FormControl id="price" isRequired>
                  <FormLabel>Price End</FormLabel>
                  <HStack>
                    <Text>Rp.</Text>
                    <Input
                      size={'sm'}
                      w={'auto'}
                      type="number"
                      value={priceEnd}
                      onChange={(e) => setPriceEnd(e.target.value)}
                    />
                    <Spacer />
                    <Text fontWeight={500}>
                      Rp.{formatFrice(parseFloat(priceEnd || 0))}
                    </Text>
                  </HStack>
                </FormControl>
              )}
            </HStack>

            <Stack>

            </Stack>

            <Stack>
              <Checkbox
                isChecked={isVariant}
                onChange={() => setIsVariant(!isVariant)}
              >
                Inputan Variant
              </Checkbox>
              {isVariant && (
                <Stack spacing={4}>
                  <FormControl>
                    <FormLabel>SKU</FormLabel>
                    <Input
                      type="text"
                      placeholder="SKU"
                      value={newVariant.sku_variant}
                      onChange={(e) => setNewVariant({ ...newVariant, sku_variant: e.target.value })}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Title</FormLabel>
                    <Input
                      type="text"
                      placeholder="title"
                      value={newVariant.title_variant}
                      onChange={(e) => setNewVariant({ ...newVariant, title_variant: e.target.value })}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Price</FormLabel>
                    <Input
                      type="number"
                      placeholder="Price"
                      value={newVariant.price_variant}
                      onChange={(e) => setNewVariant({ ...newVariant, price_variant: e.target.value })}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Stok</FormLabel>
                    <Input
                      type="text"
                      placeholder="Stok"
                      value={newVariant.stock_variant}
                      onChange={(e) => setNewVariant({ ...newVariant, stock_variant: e.target.value })}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Gambar</FormLabel>
                    <Input
                      type="text"
                      placeholder="URL Gambar"
                      value={newVariant.image_variant}
                      onChange={(e) => setNewVariant({ ...newVariant, image_variant: e.target.value })}
                    />
                    <Box>
                      {newVariant.image_variant && <Image src={newVariant.image_variant} alt="Gambar" boxSize="100px" />}
                    </Box>
                  </FormControl>
                  <Button onClick={handleAddVariant} colorScheme="teal" size="sm">
                    Tambahkan
                  </Button>
                </Stack>
              )}

              {variants?.length > 0 && (
                <Stack>

                  <h2>Variant yang sudah ditambahkan:</h2>
                  <ul>
                    {variants?.map((variant, index) => (
                      <li key={index}>
                        <strong>SKU:</strong> {variant.sku_variant}, <strong>Title:</strong> {variant.title_variant}, <strong>Stok:</strong> {variant.stock_variant}. <strong>price:</strong> {variant.price_variant}
                        {variant.image_variant && <Image src={variant.image_variant} alt="Gambar" boxSize="100px" />}
                        <CloseButton size="sm" onClick={() => handleRemoveVariant(index)} />
                      </li>
                    ))}
                  </ul>
                </Stack>

              )}

            </Stack>


            <HStack>
              <FormControl id="weight" isRequired>
                <FormLabel>
                  Product weight / pcs {'('}gram{')'}
                </FormLabel>
                <Input
                  type="number"
                  value={weight}
                  onChange={(e) => setWeight(e.target.value)}
                />
              </FormControl>
              <FormControl id="weight" isRequired>
                <FormLabel>
                  Product Volume {'('}in meter{')'}
                </FormLabel>
                <Input
                  type="number"
                  value={volume}
                  onChange={(e) => setVolume(e.target.value)}
                />
              </FormControl>

            </HStack>

            <FormControl id="stock" isRequired>
              <FormLabel>Stock Product</FormLabel>
              <Input
                type="text"
                value={stock}
                onChange={(e) => setStock(e.target.value)}
              />
            </FormControl>
            {details.map((detail, index) => (
              <HStack key={index} align={'center'} justify={'center'}>
                <FormControl id={`detail-key-${index}`}>
                  <FormLabel>Key:</FormLabel>
                  <Input
                    type="text"
                    placeholder={'Enter detail name (ex: Author)'}
                    value={detail.key}
                    onChange={(e) =>
                      handleDetailChange(index, e.target.value, detail.value)
                    }
                  />
                </FormControl>
                <FormControl id={`detail-value-${index}`}>
                  <FormLabel>Value:</FormLabel>
                  <Input
                    type="text"
                    placeholder={'Enter detail name value (ex: John Doe)'}
                    value={detail.value}
                    onChange={(e) =>
                      handleDetailChange(index, detail.key, e.target.value)
                    }
                  />
                </FormControl>
                <Box pt="7">
                  <IconButton
                    icon={<MdDelete />}
                    aria-label="Remove Detail"
                    onClick={() => handleRemoveDetail(index)}
                  />
                </Box>
              </HStack>
            ))}

            <Button
              variant={'outline'}
              colorScheme="blue"
              onClick={handleAddDetail}
            >
              Add Detail
            </Button>
            <Flex my>
              <FormControl id="isActive">
                <Switch
                  isChecked={isActive}
                  onChange={() => setIsActive((prev) => !prev)}
                >
                  Activate Product
                </Switch>
                <Text color={'gray.400'} fontSize={13} py={3}>
                  The Product will active and appear to your pageview
                </Text>

              </FormControl>

              <FormControl id="isShipping">
                <Switch
                  isChecked={isShipping}
                  onChange={() => setIsShipping((prev) => !prev)}
                >
                  Use Shipping:
                </Switch>
                <Text color={'gray.400'} fontSize={13} py={3}>
                  The Product will use shipping for delivery, and user/costumer
                  will be required to fill the shipping form{' '}
                </Text>

              </FormControl>
            </Flex>

            <FormControl id="modules">
              <FormLabel>Modules:</FormLabel>
              <Checkbox
                value="rms"
                onChange={handleModulesChange}
                isChecked={modules.includes('rms')}
              >
                RMS
              </Checkbox>
              <Checkbox
                value="listing"
                onChange={handleModulesChange}
                isChecked={modules.includes('listing')}
                mx="5"
              >
                Listing
              </Checkbox>

              <Checkbox
                value="lms"
                onChange={handleModulesChange}
                isChecked={modules.includes('lms')}
              >
                LMS
              </Checkbox>

              <Checkbox
                value="product"
                onChange={handleModulesChange}
                isChecked={modules.includes('product')}
                mx="5"
              >
                Product
              </Checkbox>

              <Checkbox
                value="CRM"
                onChange={handleModulesChange}
                isChecked={modules.includes('crm')}
              >
                CRM
              </Checkbox>
            </FormControl>

            {params.type === 'edit' ? (
              <Box>
                <Text fontWeight={'semibold'} my={2}>
                  Current Form Plugged in this product
                </Text>
                {currentForm?.length > 0 ? (
                  <SimpleGrid>
                    {currentForm.map((x, i) => (
                      <Stack
                        key={i}
                        w={300}
                        borderWidth="1px"
                        p={3}
                        cursor="pointer"
                        rounded={5}
                        borderColor={'black'}
                      >
                        <Text fontWeight={'semibold'}>{x.title}</Text>
                        <HStack>
                          {x.category?.length > 0 &&
                            x.category.map((y, i) => {
                              return <Text key={i}>{y}</Text>;
                            })}
                        </HStack>
                      </Stack>
                    ))}
                  </SimpleGrid>
                ) : (
                  <Text>Product is not assigned to any form</Text>
                )}
              </Box>
            ) : null}

            <Stack>
              <Box>
                <Text fontWeight={'semibold'}>
                  Choose form to display this product
                </Text>
              </Box>

              {dataForm?.length > 0 ? (
                <SimpleGrid columns={3} spacing={2}>
                  {dataForm?.map((form, i) => (
                    <Stack
                      _hover={{ transform: 'scale(1.02)', transition: '0.3s' }}
                      shadow={'sm'}
                      key={i}
                      borderWidth="1px"
                      p={3}
                      cursor="pointer"
                      onClick={() => setFormId(form.id)}
                      rounded={5}
                      borderColor={formId === form.id && 'black'}
                    >
                      <Text>{form.title}</Text>
                      {form.category.length > 0 &&
                        form.category.map((y, i) => {
                          return <Text key={i}>{y}</Text>;
                        })}
                    </Stack>
                  ))}
                </SimpleGrid>
              ) : (
                <Center>
                  <Stack spacing={1} align={'center'} py={2}>
                    <Text>No Form Data</Text>
                    <Text fontSize={'xs'}>
                      Either you have used all your form or you have not build
                      any form
                    </Text>
                    <Button
                      size={'xs'}
                      variant={'outline'}
                      colorScheme="blue"
                      onClick={() => navigate('/form-builder')}
                    >
                      Create Form
                    </Button>
                  </Stack>
                </Center>
              )}
            </Stack>

            {!idProject ? (
              !loading ? (
                <Flex align={'right'} justify={'right'}>
                  <Button colorScheme="blue" onClick={handleSubmit}>
                    Add Product
                  </Button>
                </Flex>
              ) : (
                <Flex align={'right'} justify={'right'}>
                  <Button isLoading colorScheme="blue" isDisabled>
                    Add Product
                  </Button>
                </Flex>
              )
            ) : !loading ? (
              <Button colorScheme="blue" onClick={handleEditSubmit}>
                Edit Listing Product
              </Button>
            ) : (
              <Button isLoading colorScheme="blue" isDisabled>
                Edit Listing Product
              </Button>
            )}
          </VStack>
        </Container>
      ) : null}
    </>
  );
}

export default FormPageProduct;
