import React, { useEffect } from 'react'
import { Icon } from '@chakra-ui/icons'
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Badge, Box, Button, HStack, Input, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import { BsCake, BsInstagram, BsLinkedin, BsMailbox, BsTags, BsTiktok, BsTwitter, BsWhatsapp, BsFacebook, BsPerson } from 'react-icons/bs'


function ChatSidebarComponent(props) {
  if(!props) return

  useEffect(() => {
    if(!props) return
  
    return () => {
      
    }
  }, [props])
  
  return (
    <Stack spacing="4" flex="1" overflowY="auto" >
      <Box p="2" mt='1' shadow='base'>
        <Text fontSize="lg" fontWeight="medium">
            Statistics 
        </Text>
        {/* <HStack p="2">
          <Text fontSize="md" fontWeight="medium">Sentiment</Text>
          <Spacer/>
          <Text fontSize="md" fontWeight="medium">30</Text>
          <Tooltip label='Run AI on all conversation' fontSize='md'>
            <InfoIcon /> 
          </Tooltip>
        </HStack> */}
      </Box>
         

      <Box p="2" mt='1' shadow='base'>

        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex='1' textAlign='left'>
                    Contacts Detail
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <HStack p='1'>
                <Icon as={BsTags}/>
                <Input type='text' size='xs' value={'uid'} disabled placeholder='ID number'/>
              </HStack>
              <HStack p='1'>
                <Icon as={BsPerson}/>
                <Input type='text' size='xs' placeholder='Full name'/>
              </HStack>
              <HStack p='1'>
                <Icon as={BsMailbox}/>
                <Input type='text' size='xs' placeholder='Email address'/>
              </HStack>
              <HStack p='1'>
                <Icon as={BsWhatsapp}/>
                <Input type='text' size='xs' placeholder='Whatsapp Number'/>
              </HStack>
              <HStack p='1'>
                <Icon as={BsInstagram}/>
                <Input type='text' size='xs' placeholder='Instagram handle @deoappcom'/>
              </HStack>
              <HStack p='1'>
                <Icon as={BsFacebook}/>
                <Input type='text' size='xs' placeholder='Facebook handle @deoappcom'/>
              </HStack>
              <HStack p='1'>
                <Icon as={BsTwitter}/>
                <Input type='text' size='xs' placeholder='Twitter / X handle @deoappcom'/>
              </HStack >
              <HStack p='1'>
                <Icon as={BsTiktok}/>
                <Input type='text' size='xs' placeholder='Tiktok handle @deoappcom'/>
              </HStack>
              <HStack p='1'>
                <Icon as={BsLinkedin}/>
                <Input type='text' size='xs' placeholder='Linkedin handle'/>
              </HStack>
              <HStack p='1'>
                <Icon as={BsCake}/>
                <Input type='date' size='xs' placeholder='Date of birth'/>
              </HStack>
              <Button colorScheme='green' size='xs' width='full' p='1'>Save Contact</Button>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
           
      </Box>

      <Box p="2" mt='1' shadow='base'>
        <Text fontSize="lg" fontWeight="medium">
            Pipeline
        </Text>
        <Input type='text' placeholder='Add to pipeline' size='xs'/>
        <SimpleGrid columns='2' fontSize='xx-small'>
          <Text> Name</Text>
          <Text> Status</Text>

          <Text> Gayung</Text>
          <Text> On Progress</Text>
          <Text> Name</Text>
          <Text> Status</Text>

        </SimpleGrid>
      </Box>
       
      <Box p="2" mt='1' shadow='base'>
        <Text fontSize="lg" fontWeight="medium">
            Tags
        </Text>
        <Input type='text' placeholder='Add tag' size='xs'/>
        <HStack columns='2' gap='1'>
          <Badge fontSize='xx-small' colorScheme='green'>Kodok</Badge>
          <Badge fontSize='xx-small'>Kodok</Badge>
          <Badge fontSize='xx-small'>Kodok</Badge>
        </HStack>
      </Box>

      <Stack mt="2" spacing="4">
        {/* <ChatGroupHeader icon={BsPinAngleFill}>Pinned</ChatGroupHeader> */}
            
        <Stack spacing="0" mx="-4">
          {/* {messages.map((message, index) => (
                <ChatMessage key={index} data={message} />
              ))} */}
        </Stack>
      </Stack>

      <Stack mt="2" spacing="4">
        {/* <ChatGroupHeader icon={BsChatTextFill}>Messages</ChatGroupHeader> */}
        {/* <Stack spacing="0" mx="-4">
              {messages.map((message, index) => (
                <ChatMessage key={index} data={message} />
              ))}
            </Stack> */}
      </Stack>
    </Stack>
  )
}

export default ChatSidebarComponent


