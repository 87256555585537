import React from 'react';
import ManagementConfigurationPage from '../Pages/Management/ManagementConfigurationPage';
import ManagementPage from '../Pages/Management/ManagementPage';

const ManagementRouter = [
  {
    path: '/management',
    element: <ManagementPage />,
  },
  {
    path: '/management/configuration',
    element: <ManagementConfigurationPage />,
  },

];

export default ManagementRouter;
