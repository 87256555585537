/* eslint-disable react/prop-types */
import {
  Avatar,
  Icon,
  Box,
  Flex,
  HStack,
  IconButton,
  Input,
  Stack,
  Text,
  AvatarBadge,
  Switch,
  Spacer,
  Button,
  Center,
  useColorMode,
  Image,
  Grid,
  GridItem,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  useToast,
} from '@chakra-ui/react';
import { signOut } from 'firebase/auth';
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import {
  BsBook,
  BsReverseListColumnsReverse,
  BsHouse,
  BsBookmarkCheck,
  BsGear,
  BsCurrencyDollar,
  BsFilter,
} from 'react-icons/bs';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getCollectionFirebaseV4, getSingleDocumentFirebase } from '../Api/firebaseApi';
import { clientTypessense } from '../Api/Typesense';
import BackButtons from '../Components/Buttons/BackButtons';
import KaryawanCard from '../Components/Card/KaryawanCard';
import KaryawanMessageCard from '../Components/Card/KaryawanMessageCard';
import SelectCompanyProject from '../Components/Select/SelectCompanyProject';
import { auth, db } from '../Config/firebase';
import { logoutUserWithIp } from '../Hooks/Middleware/sessionMiddleWare';
import useUserStore from '../Hooks/Zustand/Store';


function LayoutChat({ children }) {
  const globalState = useUserStore();
  const [config, setConfig] = useState()
  const [loading, setLoading] = useState(false);
  const [customerChat, setCustomerChat] = useState();
  const toast = useToast()
  const [highlightChat, setHighlightChat] = useState('')

  const navigate = useNavigate()

  const { colorMode, toggleColorMode } = useColorMode('dark');

  const [searchInput, setSearchInput] = useState('');
  const [dataSearched, setDataSearched] = useState([]);


  const handleSearch = (q) => {
    setSearchInput(q);

    const searchParameters = {
      q: q,
      query_by: 'name,divisi,title,description,department',
      sort_by: '_text_match:desc',
    };
    clientTypessense
      .collections('karyawanAi')
      .documents()
      .search(searchParameters)
      .then((x) => {
        const hits = x.hits.map((x) => x.document);
        setDataSearched(hits);
      })
      .catch((err) => {
        toast({
          title: 'Deoapp.com',
          duration: 3000,
          description: err.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      });
  };

  const linkMenu = [
    {
      name: 'home',
      icon: BsHouse,
      path: '/',
    },
    {
      name: 'bookMark',
      icon: BsBookmarkCheck,
      path: '/bookmark',
    },
    {
      name: 'bookMark',
      icon: BsBook,
      path: '/knowledge',
    },
    {
      name: 'logs',
      icon: BsReverseListColumnsReverse,
      path: '/logs',
    },
    {
      name: 'payment',
      icon: BsCurrencyDollar,
      path: '/pricing',
    },
    {
      name: 'setting',
      icon: BsGear,
      path: '/setting',
    },
  ];

  const getAi = async () => {
    try {
      const res = await getCollectionFirebaseV4('karyawanAi', [], {}, {}, {})
      // setKaryawan(res)
      globalState.setKaryawanAi(res)
    } catch (error) {
      console.log(error.message)
    }
  }

  const getConfig = async () => {
    try {
      const res = await getSingleDocumentFirebase('config', 'karyawanAi')
      setConfig(res.data)
    } catch (error) {
      console.log(error.message)
    }
  }

  const logout = async () => {
    const pathLink = 'karyawan';

    await logoutUserWithIp(
      window.location.hostname,
      globalState?.email,
      pathLink
    );

    signOut(auth)
      .then(() => {
        // Sign-out successful.
        toast({
          status: 'success',
          description: 'Logged out success',
          duration: 2000,
        });
        navigate('/');
        localStorage.clear();
        globalState.setIsLoading(false);
        window.location.reload();
      })
      .catch((error) => {

        toast({
          title: 'Deoapp.com',
          description: error.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      });
  };

  useEffect(() => {
    getAi()
  }, [])

  useEffect(() => {
    setLoading(true);
    getConfig()

    const q = query(
      collection(db, 'chat_customer'),
      where('projectId', '==', globalState.currentProject),
      where('user', '==', globalState.uid),
      orderBy('createdAt', 'desc'),
      limit(20)
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const chats = [];
      querySnapshot.forEach((doc) => {
        let newData = { id: doc.id, ...doc.data() }
        const updateData = globalState?.karyawanAi?.find((x) => x.id === newData.karyawanAi)
        newData = { ...newData, ...updateData }
        chats.push(newData);
      });
      setCustomerChat(chats);
      setLoading(false);
      // console.log(chats)
    });

    return () => {
      unsubscribe();
    };
  }, [globalState?.karyawanAi, globalState.currentProject]);

  const handleFilterDepartement = (departement) => {
    setSearchInput(departement)

    const searchParameters = {
      q: departement,
      query_by: 'name,divisi,title,description,department',
      filter_by: `department:${departement}`,
      sort_by: '_text_match:desc',
    };

    clientTypessense
      .collections('karyawanAi')
      .documents()
      .search(searchParameters)
      .then((x) => {
        const hits = x.hits.map((x) => x.document);
        setDataSearched(hits);
      })
      .catch((err) => {
        toast({
          title: 'Deoapp.com',
          duration: 3000,
          description: err.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      });
  }

  const handleNavigate = (message) => {
    if (globalState?.karyawanRole.includes(message?.level) || message.level === 'magang') {
      navigate(`/karyawanAi/${message.id}`)
    } else {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: 'You dont have access to use this AI level',
        status: 'warning',
        position: 'top-right',
        isClosable: true,
      });
    }
  }


  return (
    <Grid templateAreas={'"nav main"'} gridTemplateColumns={'350px 1fr'}>
      <GridItem area={'nav'}>
        <Flex height="100vh" overflow="hidden">
          <Stack spacing="4" width="350px" borderEndWidth="1px" pt="6">
            {/* <HStack px="5">
              <Image
                height="30px"
                src={
                  colorMode === 'light'
                    ? 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/img-fzl/logo/1.png'
                    : 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/img-fzl/logo/2.png'
                }
                alt="logo"
                onClick={() => console.log(globalState, 'check')}
              />

              <SelectCompanyProject globalStateProps={globalState} moduleProps="karyawan" />
            </HStack> */}

            <HStack px="5">
              <BackButtons />

              {linkMenu.map((x, i) => (
                <Link key={i} to={x.path}>
                  <Icon my={0} py={0} as={x.icon} />
                </Link>
              ))}
              <Spacer />

              <Switch
                size="sm"
                defaultChecked
                colorScheme={colorMode === 'light' ? 'gray' : 'teal'} // Sesuaikan dengan warna yang sesuai untuk dark mode
                onChange={toggleColorMode}
              />

              {/* <Button colorScheme='green' size='sm' p='1'>+</Button> */}
            </HStack>

            <HStack p='1'>
              <Input onChange={(e) => handleSearch(e.target.value)} type="text" size="sm" placeholder="Search User / Message" />
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label='Options'
                  icon={<BsFilter />}
                  // variant='outline'
                  boxSize='30px'
                />
                <MenuList>
                  {config?.map((x, i) => <MenuItem key={i} textTransform='capitalize' onClick={() => handleFilterDepartement(x?.department)}>{x?.department}</MenuItem>)}
                </MenuList>
              </Menu>
            </HStack>

            <Stack flex="1" overflowY="auto"  >
              <Stack >
                <Tabs size='sm' isFitted variant='enclosed' >
                  <TabList >
                    <Tab>Karyawan</Tab>
                    <Tab>Chat</Tab>
                  </TabList>
                  <TabPanels m='0' h={window.innerHeight / 0.9}>
                    <TabPanel >

                      {searchInput === '' ? (
                        globalState?.karyawanAi?.map((message, index) => (
                          <Stack onClick={() => handleNavigate(message)} key={index}>

                            <KaryawanCard message={message} highlightChat={highlightChat} setHighlightChat={setHighlightChat} />
                          </Stack>
                        ))
                      ) : (
                        dataSearched?.map((message, index) => (
                          <Stack onClick={() => handleNavigate(message)} key={index}>
                            <KaryawanCard message={message} highlightChat={highlightChat} setHighlightChat={setHighlightChat} />
                          </Stack>
                        ))
                      )}

                      <Center>
                        <Button colorScheme="green" mt="2" size="sm">
                          Load More
                        </Button>
                      </Center>
                    </TabPanel>
                    <TabPanel>
                      {searchInput === '' ? (
                        customerChat?.map((message, index) => (
                          <KaryawanMessageCard message={message} key={index} highlightChat={highlightChat} setHighlightChat={setHighlightChat} />
                        ))
                      ) : (
                        customerChat?.map((message, index) => {
                          const dataFilter = dataSearched.filter((x) => x.id === message.karyawanAi);

                          // Periksa apakah dataFilter tidak kosong sebelum menampilkan komponen
                          if (dataFilter.length > 0) {
                            return (
                              <KaryawanMessageCard message={message} key={index} highlightChat={highlightChat} setHighlightChat={setHighlightChat} />
                            );
                          }

                          // Return null jika dataFilter kosong
                          return null;
                        })
                      )}

                    </TabPanel>
                  </TabPanels>
                </Tabs>

              </Stack>

            </Stack>
          </Stack>
        </Flex>
      </GridItem>
      <GridItem area={'main'}>{children}</GridItem>

    </Grid>
  );
}

export default LayoutChat;
