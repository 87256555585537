/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  VStack,
  HStack,
  Divider,
  Text,
  IconButton,
  Stack,
  useToast,
  Container,
  Select,
  Spacer,
  Center,
  Image,
  SimpleGrid, // Tambahkan import untuk Checkbox
} from '@chakra-ui/react';
import { MdArrowDropDown, MdDelete } from 'react-icons/md';
import {
  addDocumentFirebase,
  arrayUnionFirebase,
  deleteDocumentFirebase,
  getCollectionFirebase,
  getSingleDocumentFirebase,
  updateDocumentFirebase,
} from '../../Api/firebaseApi';
import useUserStore from '../../Hooks/Zustand/Store';
import { formatFrice } from '../../Utils/numberUtil';
import BackButtons from '../../Components/Buttons/BackButtons';
import {
  useParams,
} from 'react-router-dom';
import CardFile from '../../Components/Accounting/CardFile';
import { decryptToken } from '../../Utils/encrypToken';
import UploadFileToDropbox from '../../Components/DropBox/UploadFileToDropbox';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import { auth } from '../../Config/firebase';


function VariantDetailPage() {

  const param = useParams()

  const productVariantId = param?.variantId
  const productId = param?.id
  // Variant State
  const [variantData, setVariantData] = useState({});
  const [name, setName] = useState('');
  const [projectId, setProjectId] = useState('');
  const [projectName, setProjectName] = useState('');
  const [files, setFiles] = useState([]); // Initialize with an empty array
  const [details, setDetails] = useState([]);
  const [productData, setProductData] = useState({});
  const [isTypeAlreadySet, setIsTypeAlreadySet] = useState(false);
  const globalState = useUserStore();
  const toast = useToast();
  const [delivery, setDelivery] = useState({ height: '', length: '', length_unit: 'cm', weight: '', weight_unit: 'gr', width: '' })
  const editData = productVariantId

  const [isLoading, setIsLoading] = useState(false);

  const [progress, setProgress] = useState(0)




  // console.log(details, 'ini detailnya')

  const getProject = () => {
    const res = globalState?.projects?.find(
      (e) => e.id === globalState?.currentProject
    );
    setProjectId(res?.id);
    setProjectName(res?.name);
  };

  const getProductData = () => {
    const res = localStorage.getItem('product_data_on_edit');
    const parsed = JSON.parse(res)
    console.log(parsed, 'product data from localstorage')
    if (typeof parsed === 'object') setProductData(parsed);
    if (res.type && res.type !== '' && res.type !== undefined) setIsTypeAlreadySet(true);
  };

  const getVariantData = async () => {
    try {
      const res = await getSingleDocumentFirebase('product_variants', productVariantId);
      setVariantData(res);
      console.log('should I update the type in variant?', res.type === '' && productData?.type !== '', 'because the product data is', productData?.type)
      if (
        res.type === '' &&
        productData?.type !== '' &&
        productData?.type !== undefined
      ) {
        await updateDocumentFirebase('product_variants', productVariantId, { type: productData?.type });
      }
      console.log(res, 'res variant data')
      // console.log(res.type, 'type of variant data')
      // console.log(productData.type, 'type of prpoduct data')
      if (res.type === 'digital' || productData?.type === 'digital') {
        const resFiles = await getCollectionFirebase(`product_variants/${productVariantId}/files`);
        console.log(resFiles, 'resFiles')
        setFiles(resFiles);
      }
    } catch (error) {
      toast({
        status: 'error',
        description: error.message,
      })
      console.log(error, 'error get variant data')
    }

  };

  useEffect(() => {
    getProject();
    setTimeout(() => getVariantData(), 1000)
    getProductData();
  }, [productId, globalState.currentProject]);


  const handledelete = async (fileId) => {
    try {
      await deleteDocumentFirebase(`product_variants/${productVariantId}/files`, fileId);
      await getVariantData();
      toast({
        status : 'success',
        description : 'Delete success'
      })
    } catch (error) {
      toast({
        status :'error',
        description : error.message,
        isClosable : true
      })
    }
  }

  const onSubmitVariant = async () => {
    const newData = {
      name: variantData.name || '',
      description: variantData.description || '',
      type: isTypeAlreadySet ? productData?.type : (variantData.type || ''),
      companyId: globalState.currentCompany,
      createdBy: auth.currentUser.createdBy || '',
      currency: variantData.currency || '',
      variant_skuId: variantData.variant_skuId || '',
      external_productId: variantData.external_productId || '',
      external_product_skuId: variantData.external_product_skuId || '',
      external_variantId: variantData.external_variantId || '',
      external_variant_skuId: variantData.external_variant_skuId || '',
      price: variantData?.price && variantData.price || '' || '',
      price_high: variantData.price_high || '',
      price_low: variantData.price_low || '',
      projectId: variantData.projectId || '',
      productId: productId || '',
      details: details.map((detail) => ({
        key: detail.key.toLowerCase(),
        value: detail.value.toLowerCase(),
      })),
      stocks: variantData.stocks || '0',
      // delivery: delivery
    };
    console.log(newData)

    try {
      setIsLoading(true)


      if (editData) {
        await updateDocumentFirebase('product_variants', editData, newData)
      } else {
        await addDocumentFirebase('product_variants', newData, globalState.currentCompany);
      }
      toast({
        status: 'success',
        duration: 3000,
        isClosable: true,
        description: 'Update variant details success'
      })

    } catch (error) {
      console.log(error.message)
      toast({
        title: 'Admin',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true
      });
      setIsLoading(false);
    }
  };


  const handleAddDetail = () => {
    setDetails([...details, { key: '', value: '' }]);
  };

  const handleRemoveDetail = (index) => {
    const updatedDetails = [...details];
    updatedDetails.splice(index, 1);
    setDetails(updatedDetails);
  };

  const handleDetailChange = (index, key, value) => {
    const updatedDetails = [...details];
    updatedDetails[index] = { key, value };
    setDetails(updatedDetails);
  };


  const onSelectFile = async (e) => {
    if (!productData?.type && !variantData.type) return toast({
      status: 'error',
      message: 'Please select product type first, physical or digital!'
    })

    const file = e.target.files[0];
    console.log(file)
    try {
      setIsLoading(true);
      const token = await getSingleDocumentFirebase('token', 'dropbox');
      const decryptResult = decryptToken(`${token?.access_token}`);


      const parentPath = `/${globalState?.currentCompany}/${globalState?.currentProject}/kanban`; // Ganti dengan path folder di Dropbox yang ingin Anda gunakan
      const dropboxLink = await UploadFileToDropbox(
        file,
        parentPath,
        decryptResult,
        setProgress,
        progress
      );

      if (dropboxLink) {
        const collectionName = 'product_variants';
        const docName = param.variantId;
        const field = 'images';
        const values = [dropboxLink?.link];


        if (productData?.type === 'physical' ||
          variantData?.type === 'physical'
        ) {
          await arrayUnionFirebase(collectionName, docName, field, values);
        } else if (productData?.type === 'digital' ||
          variantData?.type === 'digital') {
          await addDocumentFirebase(`product_variants/${productVariantId}/files`, {
            fileName: file.name,
            fileType: file.name.split('.').pop() || 'unknown_type',
            source: dropboxLink.link,
            sourceId: ''
          }, globalState?.currentCompany)
        }





        toast({
          status: 'success',
          duration: 9000,
          title: 'Success upload your background card.',
          isClosable: true,
        });
        getVariantData();

      } else {
        console.log(dropboxLink)
      }



    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false)
    }

  }
  const handleDataInput = (key, value) => {
    const obj = { ...variantData };
    obj[key] = value;
    // console.log(obj, 'updated obj');
    setVariantData(obj)
  }


  return (
    <Stack p={[1, 1, 5]}>
      <HStack>
        <BackButtons />
        <Text fontSize={'xl'} fontWeight={500}>
          Product Variant : <strong>{variantData?.name}</strong>
        </Text>
      </HStack>

      <Container
        gap={5}
        mt={0}
        maxW={'container.lg'}
        minH={'500px'}
        p={[1, 1, 5]}
        spacing={5}
        borderRadius="md"
        shadow={'md'}
        mb={2}
      >

        <VStack spacing={5} align={'left'} w="100%">
          <Stack >
            <Text fontWeight={'bold'}>Image Product: </Text>

            <CardFile onChange={onSelectFile} />
            {isLoading && <Text color='green'>Uploading....</Text>}
            {variantData?.images?.length > 0 && (

              <SimpleGrid h={'250px'} overflowY="scroll" py={3} spacing={4} columns={[2]}>
                {variantData?.images?.map((x, index) => {

                  return (
                    <Stack key={index} borderWidth={1} position="relative" alignItems="center" justifyContent={'center'} borderRadius="md" minH={'120px'}>
                      <HStack flexDirection={['column', 'row', 'row']} position={'absolute'} top={-2} right={-2} spacing={2}>
                        <DynamicButton action={'read'} size="sm" variant={'solid'} onClick={() => window.open(x, '_blank')} />
                        <DynamicButton action={'delete'} onClick={() => console.log(x)} size="sm" variant={'solid'} />
                      </HStack>
                      <Stack overflow={'hidden'}>
                        <Image src={x} objectFit="contain" w={'200px'} borderRadius={'md'} shadow="md" />
                      </Stack>
                    </Stack>

                  )
                })}
              </SimpleGrid>
            )}
            {files?.length > 0 && files?.map((file, i) => (
              <Stack
                alignItems={'center'}
                justifyContent="center"
                p={5}
                key={i}
                borderWidth={1}
                borderColor={'gray'}
                cursor={'pointer'}
                _hover={{
                  transform: 'scale(1.1)'
                }}
                _active={{
                  transform: 'scale(1.05)'
                }}
              >
                <HStack>
                  <DynamicButton action={'delete'} size="sm" variant={'solid'} onClick={() => handledelete(file?.id)} />
                  <DynamicButton action={'read'} size="sm" variant={'solid'} onClick={() => window.open(file?.source, '_blank')} />
                </HStack>
                <Text>{file?.fileName}</Text>
              </Stack>
            ))}

          </Stack>

          <Stack>
            <Spacer />
            <Text fontSize={'lg'} fontWeight={500}>Product Data</Text>
            <Divider />

          </Stack>

          <FormControl mt="5" id="Project" isRequired>
            <FormLabel>Project</FormLabel>
            <Input value={projectName} variant={'unstyled'} disabled />
          </FormControl>


          <HStack>
            <FormControl id="sku" isRequired>
              <FormLabel>Product ID:</FormLabel>
              <Input
                type={'text'}
                value={productId}
                variant={'unstyled'}
                disabled
              />
            </FormControl>

            <FormControl id="sku" isRequired>
              <FormLabel>External Product ID:</FormLabel>
              <Input
                type={'text'}
                value={variantData?.external_productId}
                defaultValue={variantData?.external_productId}
                id={'external_productId'}
                onChange={(e) => handleDataInput(e.target.id, e.target.value)}
              />
            </FormControl>
            <FormControl id="sku" isRequired>
              <FormLabel>External Product SKU ID:</FormLabel>
              <Input
                type={'text'}
                id={'external_product_skuId'}
                value={variantData?.external_product_skuId}
                defaultValue={variantData?.external_product_skuId}
                onChange={(e) => handleDataInput(e.target.id, e.target.value)}
              />
            </FormControl>


          </HStack>

          <HStack>
            <FormControl id="sku" isRequired>
              <FormLabel>Variant SKU ID:</FormLabel>
              <Input
                type={'text'}
                value={variantData?.variant_skuId}
                defaultValue={variantData?.variant_skuId}
                id={'variant_skuId'}
                onChange={(e) => handleDataInput(e.target.id, e.target.value)}
              />
            </FormControl>

            <FormControl id="sku" isRequired>
              <FormLabel>External Variant ID:</FormLabel>
              <Input
                type={'text'}
                value={variantData?.external_variantId}
                defaultValue={variantData?.external_variantId}
                id={'external_variantId'}
                onChange={(e) => handleDataInput(e.target.id, e.target.value)}

              />
            </FormControl>
            <FormControl id="sku" isRequired>
              <FormLabel>External Variant SKU ID:</FormLabel>
              <Input
                type={'text'}
                value={variantData?.externalVariantSKUId}
                defaultValue={variantData?.externalVariantSKUId}
                id={'external_variant_skuId'}
                onChange={(e) => handleDataInput(e.target.id, e.target.value)}

              />
            </FormControl>

          </HStack>

          <HStack>

            <FormControl id="name" isRequired>
              <FormLabel>Name:</FormLabel>
              <Input
                type="text"
                w="100%"
                value={variantData?.name}
                id={'name'}
                onChange={(e) => handleDataInput(e.target.id, e.target.value)}

              />
            </FormControl>

            <FormControl id="stock" isRequired>
              <FormLabel>Stock Product</FormLabel>
              <Input
                type="text"
                value={variantData?.stocks}
                defaultValue={variantData?.stocks}
                // onChange={(e) => setStocks(e.target.value)}
                onChange={(e) => handleDataInput(e.target.id, e.target.value)}
                id={'stocks'}
              />
            </FormControl>


          </HStack>
          <HStack>
            <FormControl id="type" isRequired>
              <FormLabel>
                Type : {productData?.type}
              </FormLabel>
              <Select
                disabled={isTypeAlreadySet ? false : true}
                icon={<MdArrowDropDown />}
                onChange={(e) => handleDataInput(e.target.id, e.target.value)}
                id={'type'}
                required
              >
                <option
                  selected={
                    productData.type === 'physical'
                  }
                  value='physical'
                >
                  Physical
                </option>
                <option
                  selected={
                    productData.type === 'digital'
                  }
                  value='digital'
                >
                  Digital
                </option>
              </Select>
            </FormControl>


          </HStack>

          <FormControl id="description" isRequired>
            <FormLabel>Description:</FormLabel>
            <Textarea
              value={variantData?.description}
              defaultValue={variantData?.description}
              // onChange={(e) => setDescription(e.target.value)}
              onChange={(e) => handleDataInput(e.target.id, e.target.value)}
              id={'description'}
            />
          </FormControl>

          <Stack>
            <Spacer />
            <Text fontSize={'lg'} fontWeight={500}>Product Price</Text>
            <Divider />

          </Stack>

          <HStack>
            <FormControl id="currency" isRequired>
              <FormLabel>Currency:</FormLabel>
              <Select
                defaultValue={variantData?.currency}
                value={variantData?.currency}
                icon={<MdArrowDropDown />}
                // onChange={(e) => setCurrency(e.target.value)}
                id={'currency'}
                onChange={(e) => handleDataInput(e.target.id, e.target.value)}

              >
                <option value='IDR'>IDR</option>
                <option value='US'>US</option>
              </Select>
            </FormControl>

            <FormControl id="price" isRequired>
              <FormLabel>Price</FormLabel>
              <HStack alignItems={'center'} justifyContent="center">
                <Text>Rp.</Text>
                <Input
                  w={'auto'}
                  type="number"
                  size={'sm'}
                  defaultValue={variantData?.price}
                  value={variantData?.price}
                  // onChange={(e) => setPrice(e.target.value)}
                  onChange={(e) => handleDataInput(e.target.id, e.target.value)}
                  id={'price'}

                />
                <Spacer />
                <Text fontWeight={500}>
                  Rp.{formatFrice(parseFloat(variantData?.price || 0))}
                </Text>
              </HStack>
            </FormControl>


          </HStack>


          <HStack w="100%" gap="2">

            <FormControl id="priceLow" isRequired>
              <FormLabel>Price Low</FormLabel>
              <HStack>
                <Text>Rp.</Text>
                <Input
                  size={'sm'}
                  w={'auto'}
                  type="number"
                  defaultValue={variantData?.price_low}
                  value={variantData?.price_low}
                  // onChange={(e) => setPriceLow(e.target.value)}
                  onChange={(e) => handleDataInput(e.target.id, e.target.value)}
                  id={'price_low'}
                />
                <Spacer />
                <Text fontWeight={500}>
                  Rp.{formatFrice(parseFloat(variantData?.price_low || 0))}
                </Text>
              </HStack>
            </FormControl>



            <Center height="50px">
              <Divider
                orientation="vertical"
                fontWeight={'bold'}
                color="black"
              />
            </Center>

            <FormControl id="priceHigh" isRequired>
              <FormLabel>Price High</FormLabel>
              <HStack alignItems={'center'} justifyContent="center">
                <Text>Rp.</Text>
                <Input
                  w={'auto'}
                  type="number"
                  size={'sm'}
                  defaultValue={variantData?.price_high}
                  value={variantData?.price_high}
                  // onChange={(e) => setPriceHigh(e.target.value)}
                  id={'price_high'}
                  onChange={(e) => handleDataInput(e.target.id, e.target.value)}

                />
                <Spacer />
                <Text fontWeight={500}>
                  Rp.{formatFrice(parseFloat(variantData?.price_high || 0))}
                </Text>
              </HStack>
            </FormControl>

          </HStack>

          <Stack>
            <Spacer />
            <Text fontSize={'lg'} fontWeight={500}>Product Details</Text>
            <Divider />
          </Stack>

          {details?.map((detail, index) => (
            <HStack key={index} align={'center'} justify={'center'}>
              <FormControl id={`detail-key-${index}`}>
                <FormLabel>Key:</FormLabel>
                <Input
                  type="text"
                  defaultValue={detail.key}
                  placeholder={'Enter detail name (ex: Author)'}
                  value={detail.key}
                  onChange={(e) =>
                    handleDetailChange(index, e.target.value, detail.value)
                  }
                />
              </FormControl>
              <FormControl id={`detail-value-${index}`}>
                <FormLabel>Value:</FormLabel>
                <Input
                  type="text"
                  defaultValue={detail.value}
                  placeholder={'Enter detail name value (ex: John Doe)'}
                  value={detail.value}
                  onChange={(e) =>
                    handleDetailChange(index, detail.key, e.target.value)
                  }
                />
              </FormControl>
              <Box pt="7">
                <IconButton
                  icon={<MdDelete />}
                  aria-label="Remove Detail"
                  onClick={() => handleRemoveDetail(index)}
                />
              </Box>
            </HStack>
          ))}
          <Button
            variant={'outline'}
            colorScheme="blue"
            onClick={handleAddDetail}
          >
            Add Detail
          </Button>

          <Button colorScheme="blue" onClick={onSubmitVariant}>
            Update Data Product Variant
          </Button>
        </VStack>
      </Container>
    </Stack>
  );
}

export default VariantDetailPage;
