/* eslint-disable react/react-in-jsx-scope */
import OrdersHomePage from '../Pages/Orders/OrdersHomePage';
import OrdersPage from '../Pages/Orders/OrdersPage';



const OrdersRouter = [
  {
    path: '/order',
    element: <OrdersHomePage />,
  },

  {
    path: '/orders',
    element: <OrdersPage />,
  },
];

export default OrdersRouter;
