/* eslint-disable react/prop-types */
import { AddIcon, CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  HStack,
  Input,
  Select,
  SimpleGrid,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useReducer, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  getSingleDocumentFirebase,
  setDocumentFirebase,
} from '../../Api/firebaseApi';
import useUserStore from '../../Hooks/Zustand/Store';
import DynamicButton from '../Buttons/DynamicButton';

function DetailPipelineCard({
  data,
  stages,
  handleModalClose,
  navigate,
  pipeline,
  fetchData,
}) {
  const toast = useToast();

  const globalState = useUserStore();
  const nameRef = useRef(data?.name);
  const emailRef = useRef(data?.email);
  const phoneRef = useRef(data?.phone);
  const columnRef = useRef(data?.column);
  const statusRef = useRef(data?.status);
  const sourceRef = useRef(data?.source);
  const opportunityValueRef = useRef(data?.opportunity_value);
  const detailsRef = useRef(data?.details || []);

  const [isLoading, setIsLoading] = useState(false);

  const [, forceUpdate] = useReducer((x) => x + 1, 0);


  const handleSaveData = async () => {
    const updatedData = {
      name: nameRef.current.value,
      email: emailRef.current.value,
      phone: phoneRef.current.value,
      column: columnRef.current.value,
      status: statusRef.current.value,
      source: sourceRef.current.value,
      opportunity_value: opportunityValueRef.current.value,
      details: detailsRef.current
    };

    const collectionName = 'pipelines';
    const docName = data.id;
    const value = updatedData;

    setIsLoading(true);
    try {
      const result = await setDocumentFirebase(
        collectionName,
        docName,
        value,
        globalState.currentCompany
      );
      handleModalClose();

      toast({
        title: 'Deoapp.com',
        description: 'success update card',
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });
      if (result) {
        fetchData();
      }

      console.log(result); // Pesan toast yang berhasil
    } catch (error) {
      console.log('Terjadi kesalahan:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDetailChange = (index, key, value, timeFrom, timeTo) => {
    const updatedDetails = [...detailsRef.current];
    updatedDetails[index] = {
      key: key || '',
      value: value || '',
      timeFrom: timeFrom || '',
      timeTo: timeTo || '',
    };
    detailsRef.current = updatedDetails;
    forceUpdate()
  };

  const navigateToContact = async () => {
    try {
      const result = await getSingleDocumentFirebase(
        'contacts',
        `${data?.phone}-${globalState.currentProject}`
      );
      if (result) {
        navigate(
          `/contacts/detail/${data?.phone}-${globalState.currentProject}`,
          {
            state: {
              result: result,
              pipeline: pipeline,
              price: opportunityValueRef?.current?.value,
            },
          }
        );
      } else {
        toast({
          title: 'Deoapp.com',
          description: 'You dont have any contact',
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddDetail = () => {
    detailsRef.current = [...detailsRef.current, { key: '', value: '', timeFrom: '', timeTo: '' }];
    forceUpdate()
  };

  const handleRemoveDetail = (index) => {
    const updatedDetails = [...detailsRef.current];
    updatedDetails.splice(index, 1);
    detailsRef.current = updatedDetails;
    forceUpdate()
  };


  return (
    <Stack>
      <Stack>
        <Grid templateColumns={{ base: '1fr', md: '1fr' }}>
          {/* <Stack py={2}>
                        <Text fontWeight={500}>Opportunity Details</Text>
                    </Stack> */}
          <Stack h={'550px'} overflowY="scroll">
            <Stack py={2}>
              <Text fontWeight={500}>Contact Details</Text>
            </Stack>

            <Divider />
            <SimpleGrid columns={[2]} gap={3} fontSize="sm">
              <Stack>
                <Text>Contact Name</Text>
                <Input defaultValue={data?.name} ref={nameRef} />
              </Stack>

              <Stack>
                <Text>Email</Text>
                <Input defaultValue={data?.email} ref={emailRef} />
              </Stack>

              <Stack>
                <Text>Phone</Text>
                <Input defaultValue={data?.phone} ref={phoneRef} />
              </Stack>
            </SimpleGrid>

            <Divider pt={3} />

            <Stack>
              <Text fontWeight={500}>Opportunity Details</Text>
            </Stack>

            <Divider />

            <SimpleGrid columns={[2]} gap={3} fontSize="sm">
              <Stack>
                <Text>Name</Text>
                <Input defaultValue={data?.name} ref={nameRef} />
              </Stack>

              <Stack>
                <Text>Stage</Text>
                <Select
                  defaultValue={data?.column}
                  variant="outline"
                  fontWeight="normal"
                  placeholder='select columns'
                  ref={columnRef}
                >
                  {stages?.length > 0 &&
                    stages.map((x, index) => {
                      return (
                        <option key={index} value={x.stageName}>
                          {x.stageName}
                        </option>
                      );
                    })}
                </Select>
              </Stack>

              <Stack>
                <Text>Status</Text>
                <Select
                  defaultValue={data?.status}
                  variant="outline"
                  fontWeight="normal"
                  placeholder='select status'
                  ref={statusRef}
                >
                  <option value={'open'}>Open</option>
                  <option value={'won'}>Won</option>
                  <option value={'lost'}>Lost</option>
                </Select>
              </Stack>
              <Stack>
                <Text>Source</Text>
                <Input defaultValue={data?.source} ref={sourceRef} />
              </Stack>

              <Stack>
                <Text>Opportunity Value</Text>
                <Input
                  type={'number'}
                  defaultValue={Number(data?.opportunity_value) || 0}
                  ref={opportunityValueRef}
                />
              </Stack>
            </SimpleGrid>
          </Stack>
        </Grid>
        <Grid gap={3} templateColumns={{ base: '1fr', md: '1fr' }}>
          <Stack w={'100%'}>
            <Text fontWeight={'bold'}>Details Card :</Text>
          </Stack>
          <Stack>
            {detailsRef?.current?.length > 0 && (
              detailsRef?.current?.map((detail, index) => (
                <HStack key={index} align={'center'} justify={'center'}>
                  <FormControl id={`detail-key-${index}`}>
                    <FormLabel>Point:</FormLabel>
                    <Input
                      size={'sm'}
                      type="text"
                      placeholder="Title detail, ex: location"
                      value={detail?.key}
                      onChange={(e) =>
                        handleDetailChange(
                          index,
                          e.target.value,
                          detail?.value,
                          detail?.timeFrom,
                          detail?.timeTo
                        )
                      }
                    />
                  </FormControl>

                  <FormControl id={`detail-value-${index}`}>
                    <FormLabel>Time From:</FormLabel>
                    <Input
                      size={'sm'}
                      defaultValue={detail?.timeFrom}
                      type="date"
                      onChange={(e) =>
                        handleDetailChange(
                          index,
                          detail?.key,
                          detail?.value,
                          e.target.value,
                          detail?.timeTo
                        )
                      }
                    />
                  </FormControl>

                  <FormControl id={`detail-value-${index}`}>
                    <FormLabel>Time To:</FormLabel>
                    <Input
                      size={'sm'}
                      defaultValue={detail?.timeTo}
                      type="date"
                      onChange={(e) =>
                        handleDetailChange(
                          index,
                          detail?.key,
                          detail?.value,
                          detail?.timeFrom,
                          e.target.value)
                      }
                    />
                  </FormControl>

                  <FormControl id={`detail-value-${index}`}>
                    <FormLabel>Value:</FormLabel>
                    <Input
                      size={'sm'}
                      type="text"
                      placeholder="Value detail, ex: location"
                      value={detail?.value}
                      onChange={(e) =>
                        handleDetailChange(
                          index,
                          detail?.key,
                          e.target.value,
                          detail?.timeFrom,
                          detail?.timeTo
                        )
                      }
                    />
                  </FormControl>
                  <Box pt="7">
                    <DynamicButton variant={'solid'} action='delete' onClick={() => handleRemoveDetail(index)} size="sm" />
                  </Box>
                </HStack>
              ))
            )}

          </Stack>
          <Stack>
            <DynamicButton size={'sm'} variant={'solid'} title='Point' action='create' onClick={handleAddDetail} />

          </Stack>
        </Grid>
        <HStack
          gap={5}
          py={3}
          alignItems="flex-end"
          justifyContent={'flex-end'}
        >
          <Button
            colorScheme="green"
            onClick={() =>
              window.open(
                `https://api.whatsapp.com/send?phone=${data.phone}&text=Send%20Message%20To%20This%20User`,
                '_blank'
              )
            }
          >
            Chat via Whatsapp
          </Button>
          <Button colorScheme="green" onClick={navigateToContact}>
            Go to contact
          </Button>
          <Button
            isLoading={isLoading}
            leftIcon={<AddIcon boxSize={3} />}
            colorScheme="green"
            onClick={handleSaveData}
          >
            Save
          </Button>
        </HStack>
      </Stack>
    </Stack>
  );
}

export default DetailPipelineCard;
