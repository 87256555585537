/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import {
  Box,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
} from '@chakra-ui/react';
import moment from 'moment';
import { useState, useRef } from 'react';
import {
  addDocumentFirebase,
  getCollectionFirebase,
} from '../../Api/firebaseApi';
import useUserStore from '../../Hooks/Zustand/Store';
import { BsFillChatDotsFill } from 'react-icons/bs';
import MessageUser from '../../Components/Chat/MessageUser';

function ChatPageFirst({ module, companyId, projectId }) {
  const globalState = useUserStore();
  const [idMessage, setIdMessage] = useState('');

  const [notif, setNotif] = useState(0);


  const initialFocusRef = useRef();

  const checkSessionStorageData = () => {
    const storedData = sessionStorage.getItem('dataChat');

    if (storedData) {
      const parsedData = JSON.parse(storedData);
      const currentTime = new Date().getTime();

      if (currentTime < parsedData.expiration) {
        // Data masih berlaku, gunakan nilai uid dari sessionStorage

        handleLoadChat(parsedData);

        return true;
      } else {
        // Data sudah kadaluwarsa, hapus dari sessionStorage
        sessionStorage.removeItem('dataChat');
        return false;
      }
    } else {
      return false;
    }
  };

  const saveDataToSessionStorage = (key, name, id) => {
    const currentTime = new Date().getTime();
    const expirationTime = currentTime + 15 * 60 * 1000; // 15 menit dalam milidetik
    const dataToStore = {
      name: name,
      uid: id,
      expiration: expirationTime,
    };
    sessionStorage.setItem(key, JSON.stringify(dataToStore));
  };

  const handleChatButtonClick = async () => {
    // Cek session sebelum menindaklanjuti tombol "Chat"
    const res = checkSessionStorageData();

    if (res) {
      return;
    }

    if (globalState.uid === '') {
      try {
        const idTime = moment(new Date()).valueOf();

        // Simpan data ke sessionStorage
        saveDataToSessionStorage('dataChat', 'visitor', idTime);

        handleNewChat('visitor', idTime);
      } catch (error) {
        throw new Error(error.message, 'Failed to send Slack login message');
      }
    } else {
      saveDataToSessionStorage('dataChat', globalState.name, globalState.uid);

      handleNewChat(globalState.name, globalState.uid);
    }
  };

  //   const handleNotification = async () => {
  //     const getCurrentMessage = await getSingleDocumentFirebase(
  //       `messages/${idMessage}`
  //     );
  //     console.log(getCurrentMessage.userNotification);
  //   };

  const handleLoadChat = async (data) => {
    const conditions = [
      { field: 'uids', operator: 'array-contains', value: data?.uid || globalState?.uid },
      { field: 'companyId', operator: '==', value: companyId || globalState?.currentCompany },
      { field: 'projectId', operator: '==', value: projectId || globalState?.currentProject },
    ];
    const sortBy = { field: 'lastConversation', direction: 'desc' };
    const limitValue = 1;

    try {
      const res = await getCollectionFirebase(
        'messages',
        conditions,
        sortBy,
        limitValue
      );

      const data = res[0];

      // navigate(`/chat-user/${data.id}`, { state: data });

      setIdMessage(data.id);

      //   const getCurrentMessage = await getSingleDocumentFirebase(
      //     `messages/${idMessage}`
      //   );

      //   console.log(getCurrentMessage);

      if (data?.userNotification) {
        setNotif(data?.userNotification);
      }
    } catch (error) {
      throw new Error(error.message, 'Failed to send Slack login message');
    }
  };

  const handleNewChat = async (name, uid) => {
    const collectionName = 'messages';
    const data = {
      lastConversation: new Date(),
      lastChat: '',
      module: module || 'chat',
      name: [name, 'admin123'],
      uids: [uid, 'admin'],
      projectId: projectId,
      companyId: companyId,
    };

    try {
      const docID = await addDocumentFirebase(collectionName, data, companyId);

      setIdMessage(docID);

      // navigate(`/chat-user/${docID}`, { state: data });
    } catch (error) {
      throw new Error(error.message, 'Failed to send Slack login message');
    }
  };

  return (
    <Stack>
      <Stack bgColor={'transparent'} alignItems="flex-end">
        <Stack bottom={5} right={5} p={[1, 1, 5]}>
          <Popover
            initialFocusRef={initialFocusRef}
            placement="top"
            closeOnBlur={false}
          >
            <PopoverTrigger>
              <Stack
                alignItems={'center'}
                justifyContent="center"
                onClick={() => handleChatButtonClick()}
              >
                <Box
                  p={5}
                  borderRadius="full"
                  bgColor="blue.700"
                  _hover={{ transform: 'scale(1.05)' }}
                  transition="0.3s ease-in-out"
                  shadow={'md'}
                  cursor="pointer"
                >
                  <BsFillChatDotsFill color="white" size={30} />
                </Box>
              </Stack>
            </PopoverTrigger>

            <PopoverContent bgColor="blue.200" width={'500px'} mr={5}>
              <MessageUser id={idMessage} companyId={companyId} notif={notif} />
            </PopoverContent>
          </Popover>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default ChatPageFirst;
