import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import HomeRouter from './HomeRouter';
import SettingRouter from './SettingRouter';
import AuthenticationMainRouter from './AuthenticationMainRouter';
import useUserStore from '../Hooks/Zustand/Store';
import HRISRouter from './HRISRouter';

import KaryawanRouter from './KaryawanRouter';
import KnowledgeRouter from './KnowledgeRouter';
import ChatRouter from './ChatRouter';
import BookmarkRouter from './BookmarkRouter';
import PricingRouter from './PricingRouter';
import LogsRouter from './LogsRouter';
import Layout from '../Layouts';
import ManagementRouter from './ManagementRouter';
import CustomerRelationRouter from './CustomerRelationRouter';
import ProductRouter from './ProductRouter';
import OrdersRouter from './OrdersRouter';
import IntegrationRouter from './IntegrationRouter';
import ShippingRouter from './ShippingRouter';

function MainRouter() {

  const globalState = useUserStore();
  const location = useLocation()
  const navigate = useNavigate()


  const allRouter = [
    ...AuthenticationMainRouter,
    ...ManagementRouter,
    ...HRISRouter,
    ...SettingRouter,
    ...HomeRouter,
    ...ChatRouter,
    ...KaryawanRouter,
    ...KnowledgeRouter,
    ...SettingRouter,
    ...BookmarkRouter,
    ...PricingRouter,
    ...LogsRouter,
    ...CustomerRelationRouter,
    ...ProductRouter,
    ...OrdersRouter,
    ...IntegrationRouter,
    ...ShippingRouter
  ];




  useEffect(() => {
    const path = location.pathname;



    if (globalState.roleCompany !== 'owner') {
      if (
        path === '/integration'
      ) {
        return navigate('/no-access');
      }
    }



    return () => {
      // Cleanup, if needed
    }
  }, [location.pathname]);





  return (
    <Routes>
      {allRouter.map((item, index) => {
        return <Route key={index} path={item.path} element={<Layout>{item.element}</Layout>} />;
      })}
    </Routes>
  );
}

export default MainRouter;
