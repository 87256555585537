import { Flex } from '@chakra-ui/react'
import { collection, limit, onSnapshot, orderBy, query, where } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { db } from '../../Config/firebase'
import useUserStore from '../../Hooks/Zustand/Store'
import ChatComponent from '../Chat/ChatComponent'

function HomePage() {
  const globalState = useUserStore()
  const [activeChat]=useState()


  useEffect(() => {
    const q = query(collection(db, 'chat_customer'), 
      where('projectId', '==', globalState.currentProject),
      orderBy('createdAt','desc'),
      limit(10));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const chats = [];
      querySnapshot.forEach((doc) => {
        chats.push(doc.data());
      });
    })
  
    return () => {
      unsubscribe()
    }
  }, [globalState.currentProject])
  
  return (
    <Flex height="100vh" overflow="hidden">

      <Flex direction="column" flex="1">
        {activeChat && <ChatComponent userId={activeChat}/> }
      </Flex>

    </Flex>
  )
}

export default HomePage