import React from 'react';
import BookmarkPage from '../Pages/Ai/Bookmark/BookmarkPage';

const BookmarkRouter = [
  {
    path: '/bookmark',
    element: <BookmarkPage/>,
  },
  {
    path: '/bookmark/:id',
    element: <BookmarkPage />,
  },

];

export default BookmarkRouter;
