import React from 'react';
import KnowledgePage from '../Pages/Ai/Knowledge/KnowledgePage';

const KnowledgeRouter = [
  {
    path: '/knowledge',
    element: <KnowledgePage/>,
  },
  {
    path: '/knowledge/:id',
    element: <KnowledgePage />,
  },

];

export default KnowledgeRouter;
