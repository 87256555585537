/* eslint-disable no-useless-escape */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import { Icon, InfoIcon } from '@chakra-ui/icons'
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Avatar, AvatarGroup, Box, Button, Flex, HStack, IconButton, Input, InputGroup, InputLeftElement, InputRightElement, Spacer, Spinner, Stack, Text, Tooltip } from '@chakra-ui/react'
import { addDoc, collection, getCountFromServer, limit, onSnapshot, orderBy, query, where } from 'firebase/firestore'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { BsBook, BsMicFill, BsPaperclip } from 'react-icons/bs'
import { setDocumentFirebase } from '../../Api/firebaseApi'
import { db } from '../../Config/firebase'
import useUserStore from '../../Hooks/Zustand/Store'
import { readMore } from '../../Utils/Helper'
import { Link } from 'react-router-dom'
import { convertImageToBase64, uploadBase64ToDropbox } from '../../Utils/ImageUtils'

function ChatComponent(props) {
  if (!props) return

  const [chat, setChat] = useState()
  const [messageLoading, setMessageLoading] = useState(false)
  const [inputValue, setInputValue] = useState()
  const globalState = useUserStore()
  const messagesEndRef = useRef(null)
  const apiKey = `Bearer ${process.env.REACT_APP_FLOWISE}`
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handleMessage = async () => {
    setMessageLoading(true);
    setInputValue('');

    try {
      // insert to firestore human
      const insertData = {
        companyId: globalState.currentCompany,
        projectId: globalState.currentProject,
        message: inputValue,
        characters: inputValue.length,
        type: 'human',
        karyawanAi: props?.data?.id,
        user: globalState?.uid,
        status: 'unread',
        medium: 'web',
        createdAt: moment().unix(),
        lastUpdated: new Date()
      };
      const userDoc = await addDoc(collection(db, 'chat'), insertData);
      if (userDoc) await handleAI(insertData);

      // querying AI
      const query = { question: inputValue };
      const response = await fetch(
        `https://flowise.deoapp.com/api/v1/prediction/${props.data.flowiseId}`,
        {
          headers: {
            Authorization: apiKey,
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify(query),
        }
      );
      const result = await response.json();

      const characterOri = result?.text?.length

      if (props?.data?.type === 'image') {
        const imageUrlMatch = result.text.match(/src="(.*?)"/);
        const imageUrl = imageUrlMatch ? imageUrlMatch[1] : null;

        const coverBase = await convertImageToBase64(imageUrl);

        const dropboxResult = await uploadBase64ToDropbox(coverBase, globalState);

        const linkResultFlowise = `<image src=\"${dropboxResult.link}\"/>\n<a href=${dropboxResult.link} target="_blank">LINK</a>`;

        result.text = linkResultFlowise;
      }

      if (props?.data?.type === 'web') {
        result.text = 'gak tau mau bikin apa hahaha';
      }

      // insert to firestore AI
      const aiReply = {
        companyId: globalState.currentCompany,
        projectId: globalState.currentProject,
        message: result.text,
        // characters: result.text.length,
        characters: characterOri,
        karyawanAi: props.data.id,
        type: 'AI',
        user: globalState.uid,
        createdAt: moment().unix(),
        lastUpdated: new Date(),
        status: 'read',
      };
      const aiDoc = await addDoc(collection(db, 'chat'), aiReply);
      if (aiDoc) await handleAI(aiReply);

      setMessageLoading(false);
      // scrollToBottom();

      return result;
    } catch (error) {
      setMessageLoading(false);
      // Handle other error handling logic here
    }
  };



  // const handleChat = async() => {
  //   setInputValue('')

  //   try {
  //     const insertData = {
  //       companyId:globalState.currentCompany,
  //       projectId: globalState.currentProject,
  //       message: inputValue,
  //       characters:inputValue.length,
  //       type:'admin',
  //       user:props?.userId,
  //       admin:globalState.uid,
  //       status:'read',
  //       medium:'admin',
  //       createdAt:moment().unix()
  //     }
  //     await addDocumentFirebaseV3('chat',insertData,globalState.currentProject)
  //     await setDocumentFirebase('chat_customer',`${props?.userId}-${globalState.currentProject}`,{
  //       status:'read'
  //     })

  //   } catch (error) {
  //     console.log(error.message)
  //   }

  // }

  const handleAI = async (data) => {
    try {
      await setDocumentFirebase('chat_customer', `${globalState.uid}-${data.karyawanAi}`, data)
    } catch (error) {
      console.log(error.message)
    }
  }

  const scrollToBottom = () => {
    // console.log(messagesEndRef.current)
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
  }


  const getData = async () => {
    if (!props?.data?.id) return;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const limitValue = startIndex + itemsPerPage;
    const q = query(
      collection(db, 'chat'),
      where('projectId', '==', globalState.currentProject),
      where('user', '==', globalState.uid),
      where('karyawanAi', '==', props.data.id),
      orderBy('createdAt', 'desc'),
      limit(limitValue)
    );
    
    // onSnapshot(q, (querySnapshot) => {
    //   const chats = [];
    //   querySnapshot.forEach((doc) => {
    //     chats.push(doc.data());
    //   });
    //   setChat(chats);
    //   // if (chats)
    //   //   scrollToBottom()
    //   const collectionRef = collection(db, 'chat');

    //   const countQuery = query(collectionRef, 
    //   // where('companyId', '==', globalState.currentCompany), 
    //     where('projectId', '==', globalState.currentProject), 
    //     where('user', '==', globalState.uid),
    //     where('karyawanAi', '==', props.data.id),
    //   );
   
    
    //   const snapshot = getCountFromServer(countQuery);
    //   const assessmentLength = snapshot.data().count;
    
    //   setTotalPages(Math.ceil(assessmentLength / itemsPerPage));
    // });

    return new Promise((resolve, reject) => {
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const chats = [];
        querySnapshot.forEach((doc) => {
          chats.push(doc.data());
        });
        setChat(chats); // Set the chat data once outside the loop
  
        const collectionRef = collection(db, 'chat');
        const countQuery = query(
          collectionRef,
          where('projectId', '==', globalState.currentProject),
          where('user', '==', globalState.uid),
          where('karyawanAi', '==', props.data.id),
        );
  
        getCountFromServer(countQuery)
          .then((snapshot) => {
            const assessmentLength = snapshot.data().count;
            setTotalPages(Math.ceil(assessmentLength / itemsPerPage));
            resolve(); // Resolve the promise when both data and count are fetched
          })
          .catch((error) => {
            reject(error); // Reject the promise in case of an error
          });
      });
  
      // Return the unsubscribe function to clean up the listener when needed
      return unsubscribe;
    });

  };

  useEffect(() => {
    getData();

    return () => {
      setChat([]);
      setMessageLoading(false);
      // messagesEndRef.current=null
    };
  }, [props?.data?.id, currentPage]);

  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
    scrollToBottom()
    //buatkan fungsi lihat lebih banyak disiini menggunakan table contact has more yang sudah ad a
  }

  
  // const getData = async () => {
  //   if (!props.data.id) return
  //   // const startIndex = (currentPage - 1) * itemsPerPage;

  //   const conditions = [
  //     { field: 'projectId', operator: '==', value: globalState.currentProject },
  //     {
  //       field: 'user',
  //       operator: '==',
  //       value: globalState.uid,
  //     },
  //     {
  //       field: 'projectId',
  //       operator: '==',
  //       value: globalState.currentProject,
  //     },
  //     {
  //       field: 'karyawanAi',
  //       operator: '==',
  //       value: props.data.id,
  //     },
  //   ];
  //   const sortBy = { field: 'createdAt', direction: 'desc' };
  //   // const limitValue = startIndex + itemsPerPage;


  //   try {
  //     const res = await getCollectionFirebase(
  //       'chat',
  //       conditions,
  //       sortBy,
  //       // limitValue,
  //     );
  //     setChat(res);
  //     console.log(res)

  //     const collectionRef = collection(db, 'chat');

  //     const countQuery = query(collectionRef, 
  //     // where('companyId', '==', globalState.currentCompany), 
  //       where('projectId', '==', globalState.currentProject), 
  //       where('user', '==', globalState.uid),
  //       where('karyawanAi', '==', props.data.id),
  //     );
   
    
  //     const snapshot = await getCountFromServer(countQuery);
  //     const assessmentLength = snapshot.data().count;
    
  //     setTotalPages(Math.ceil(assessmentLength / itemsPerPage));

  //   } catch (error) {
  //     toast({
  //       title: 'Deoapp.com',
  //       description: error.message,
  //       status: 'error',
  //       position: 'top-right',
  //       isClosable: true,
  //     });
  //   }
  // };

  // useEffect(() => {
  //   getData()
  //   return () => {
  //     setChat()
  //     setMessageLoading(false)
  //     // messagesEndRef.current=null
  //   };

  // }, [props.data.id, currentPage]);
  return (
    <>
      <Flex borderBottomWidth="1px" px="4" py="4">
        <HStack spacing="4" flex="1">
          <Avatar name={props?.data?.name} src={props?.data?.image} />
          <Box>
            <Text width='max-content'>{readMore(props?.data?.name, 25)}</Text>
            <Text fontSize='sm' width='max-content'>{readMore(props?.data?.title, 25)}</Text>

          </Box>
          {/* <Text fontWeight="medium">{group.name}</Text> */}
          {/* <Text fontSize="sm" color="fg.subtle">
                {group.stats.count} members, {group.stats.online} online
              </Text> */}
          <Spacer />
          {/* <Text>AI</Text>       */}
          {/* <Switch isDisabled={false} id='ai'  onChange={(e) => handleAI(e.target.checked)}/> */}
          <Input type='text' width='sm' placeholder={`Search Message from ${props?.data?.name}`} />
          <Link to={'/knowledge/:id'} >
            <Icon cursor={'pointer'} as={BsBook} />
          </Link>
          <Tooltip label='Run AI for this conversation (only for positive sentiment users)' fontSize='sm'>
            <InfoIcon />
          </Tooltip>
        </HStack>

        <AvatarGroup size="sm">
          {/* {group.members.map((member) => (
              <Avatar name={member.name} src={member.image} />
            ))} */}
        </AvatarGroup>
      </Flex>
      {currentPage < totalPages ? (
        <Stack>
          <Button onClick={handleLoadMore}>See more</Button>
        </Stack>
      ) 
        : 
        (
          <></>
          // <Container  mt='1' display='flex' justifyContent='center'><IconButton  position='relative' isRound={true} icon={<ArrowUpIcon/>}/></Container>
        )} 

      <Stack flex="1" overflow="auto" px="5" py="4"
        direction={'column-reverse'}
      >


        {chat?.map((x, i) =>
          <Stack key={i} m='1'

          >

            <HStack gap="5">

              {x.type === 'human' ? <Spacer /> : <></>}

              <Stack spacing="1" bgColor={x.type !== 'human' ? '#1F2C33' : '#015C4B'} maxW='xl' borderRadius='lg' p='2' textColor='white'>
                {/* <Text fontWeight="medium">{x.userId}</Text> */}
                <HStack spacing="2">
                  <Spacer />
                  <Box color="fg.muted" lineHeight="tall" >
                    <div dangerouslySetInnerHTML={{ __html: x.message.replace(/(?:\r\n|\r|\n)/g, '<br>') }} />
                    <HStack>
                      <Spacer />
                      <Text fontSize='xx-small' textAlign={x.type !== 'human' ? 'end' : 'start'}>{moment.unix(x.createdAt).fromNow()}</Text>
                      {x.type !== 'human' ?
                        <Text fontSize='xx-small' textAlign='end'>By {x.type}</Text>
                        : <></>
                      }
                    </HStack>
                  </Box>
                </HStack>
              </Stack>

              {x.type !== 'human' ? <Spacer /> : <></>}

            </HStack>
          </Stack>)}
        {/* {inputLoading?  <Skeleton height='50px' startColor='blue.100' endColor='blue.300' width='250px' borderRadius='md'/>:<></>} */}
        <div ref={messagesEndRef} />
      </Stack>

      <Box bg="bg.subtle" py="4" px="5" borderTopWidth="1px">
        {props?.data?.promptReccomendation ?

          <Accordion allowToggle>
            {props?.data?.promptReccomendation?.map((x, i) =>
              <AccordionItem key={i + 1}>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex='1' textAlign='left'>
                      Rekomendasi chat#{i + 1}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  {x}
                </AccordionPanel>
              </AccordionItem>
            )}



          </Accordion>
          :
          <></>
        }

        <InputGroup>
          <InputLeftElement>
            <IconButton
              size="sm"
              aria-label="Record voice"
              icon={<Icon as={BsMicFill} fontSize="md" />}
              variant="ghost"
            />
          </InputLeftElement>
          <Input
            placeholder="Send a message..."
            fontSize="sm"
            value={inputValue}
            isDisabled={messageLoading}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                // handleSave();
                // ChatComponent()
                handleMessage()
                // console.log('enter')
              }
            }}
          />
          <InputRightElement>
            {messageLoading ?
              <Spinner />
              :
              <IconButton
                size="sm"
                variant="ghost"
                aria-label="Send message"
                icon={<Icon as={BsPaperclip} fontSize="md" />}
              />
            }

          </InputRightElement>
        </InputGroup>
      </Box>
    </>
  )
}

export default ChatComponent