import React, { useEffect, useState } from 'react'
import ChatComponent from './ChatComponent'
import { useParams } from 'react-router-dom'
import { Box, Flex, Stack, Text } from '@chakra-ui/react'
import { getSingleDocumentFirebase } from '../../Api/firebaseApi'
import useUserStore from '../../Hooks/Zustand/Store'
import { FaLock } from 'react-icons/fa'

function ChatConversation(props) {
  const [karyawan, setKaryawan] = useState()
  const globalState = useUserStore()
  const param = useParams()

  const getKaryawan = async () => {
    try {

      const dataKaryawan = globalState.karyawanAi.find((x) => x.id === param.id)
      setKaryawan(dataKaryawan)
    } catch (error) {
      console.log(error.message)
    }
  }

  useEffect(() => {
    getKaryawan()
    return () => {
    }
  }, [param.id])


  return (
    <Flex height="100vh" overflow="hidden">
      <Flex direction="column" flex="1" position={'relative'}>
        {/* pake param ambil id chat*/}

        {/* <Stack p={1} w={'full'} h={window.innerHeight} position={'absolute'} zIndex={100} opacity={0.5} alignItems="center" justifyContent={'center'} bgColor="gray.300">
          <FaLock size={50} />
          <Text fontSize={'xl'} fontWeight={500}>You dont have access</Text>
        </Stack> */}

        {karyawan?.name ?
          <ChatComponent data={karyawan} />
          // <Text>kodok</Text>
          :
          <></>
        }
      </Flex>

    </Flex>
  )
}

export default ChatConversation