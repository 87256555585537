import React from 'react'
import {Container, Text, SimpleGrid, Stack, Show, Hide, Spacer} from '@chakra-ui/react'
import PricingComponent, { PricingComponentMagang } from '../../../Components/Pricing/PricingComponent'

const PricingPage = () => {
  const linearColor ='linear(to-r, #FFA500, #ffd600)'
  return (
    <Stack h='100vh' overflowY='auto' mt='1em'>
      <Container maxW='container.lg'>
        <Text mt={{lg: '0.5em'}} bgGradient={linearColor} textAlign='center'  bgClip="text" fontSize={['1em', '1.5em', '2.75em']} fontWeight={700}>Gaji Karyawan AI kamu</Text>
        <Text mt='0.75em' mb='1em' color='white' textAlign='center' >
					Beragam macam karyawan ai yang bisa kamu rekrut untuk organisasi kamu.
        </Text>
					
        <SimpleGrid columns={{md:1, lg: 2, xl: 4}} color='white' gap='2'>
          <PricingComponentMagang keterangan={'Recruit staff Magang di karyawan.ai mampu menyelesaikan 3 tugas mu.'} linearColor={linearColor} posisi={'Magang'} credit={'1.000'} harga={'Trial'}/>
          <PricingComponent keterangan={'Recruit seluruh staff di karyawan.ai mampu menyelesaikan tugas per KPI.'} linearColor={linearColor} posisi={'Staff'} credit={'12.000'} harga={'1.000k/bln'}/>
          <PricingComponent fiturTambahan={['API']} keterangan={'Recruit seluruh staff & manager di karyawan.ai mampu menyelesaikan tugas dan menggabungkan KPI dari sebuah divisi.'} linearColor={linearColor} posisi={'Manager'} credit={'50.000'} harga={'3.500k/bln'}/>
          <PricingComponent fiturTambahan={['API','Customer Service AI','ONSITE TRAINING']}keterangan={'Seorang direktur di karyawan.ai mampu menyelesaikan tugas, menganalisa report dan menggabungkan KPI dalam sebuah divisi.'} linearColor={linearColor} posisi={'Direktur'} credit={'100.000'} harga={'7.500k/bln'}/>
        </SimpleGrid>
      </Container>
    </Stack>
  )
}

export default PricingPage