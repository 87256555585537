import React from 'react';
import HrisPage from '../Pages/HRIS/HrisPage';
import HrisUsers from '../Pages/HRIS/HrisUsers';
import ProjectsPage from '../Pages/Configurations/ProjectPage';
import ProjectNewPage from '../Pages/Configurations/ProjectNewPage';

const HRISRouter = [
  {
    path: '/hris',
    element: <HrisPage />,
  },
  {
    path: '/hris/users',
    element: <HrisUsers />,
  },

  {
    path: '/configuration/project',
    element: <ProjectsPage />,
  },
  {
    path: '/configuration/project/:id',
    element: <ProjectNewPage />,
  }
];

export default HRISRouter;
