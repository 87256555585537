/* eslint-disable react/prop-types */
import { AddIcon } from '@chakra-ui/icons';
import { Avatar, Box, Button, Divider, FormControl, FormLabel, Grid, HStack, Input, Select, SimpleGrid, Spacer, Stack, Text, useToast } from '@chakra-ui/react';
import React, { useReducer, useRef, useState } from 'react';
import { addDocumentFirebase } from '../../Api/firebaseApi';
import useUserStore from '../../Hooks/Zustand/Store';
import DynamicButton from '../Buttons/DynamicButton';

function DetailPipelineAddCardV2({ stages, handleModalAddClose, filesId, searchResult, handleSearchUsers, fetchData, bg }) {
  const toast = useToast();

  const globalState = useUserStore();
  const [showSearchResults, setShowSearchResults] = useState(true);
  const [selectedUser, setSelectedUser] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [, forceUpdate] = useReducer((x) => x + 1, 0);


  const nameRef = useRef('');
  const emailRef = useRef('');
  const phoneRef = useRef('');
  const columnRef = useRef();
  const statusRef = useRef('');
  const sourceRef = useRef('');
  const opportunityValueRef = useRef('');
  const detailsRef = useRef([]);


  const handleUserProjectClick = (user) => {
    setSelectedUser(user);
    nameRef.current.value = user.name;
    emailRef.current.value = user.email;
    phoneRef.current.value = user.phone;
    columnRef.current.value = user.column || '';
    statusRef.current.value = user.status || '';
    sourceRef.current.value = user.source || '';
    opportunityValueRef.current.value = user.opportunity_value;

    setShowSearchResults(false);
  };

  const handleDetailChange = (index, key, value, timeFrom, timeTo) => {
    const updatedDetails = [...detailsRef.current];
    updatedDetails[index] = {
      key: key || '',
      value: value || '',
      timeFrom: timeFrom || '',
      timeTo: timeTo || '',
    };
    detailsRef.current = updatedDetails;
    forceUpdate()
  };


  const handleSaveData = async () => {
    if (!selectedUser) {
      toast({
        title: 'Deoapp.com',
        description: 'Please select a user first.',
        status: 'warning',
        position: 'top-right',
        isClosable: true,
      });
      return;
    }

    const updatedData = {
      name: nameRef.current.value,
      email: emailRef.current.value,
      phone: phoneRef.current.value,
      column: columnRef.current.value,
      status: statusRef.current.value,
      source: sourceRef.current.value,
      opportunity_value: Number(opportunityValueRef.current.value), // Konversi ke tipe angka
      projectId: globalState.currentProject,
      companyId: globalState.currentCompany,
      createdAt: new Date(),
      lastUpdated: new Date(),
      filesId: filesId,
      assignee: [globalState.uid],
      type: 'acquisition',
      details: detailsRef?.current,

    };


    const collectionName = 'pipelines';
    const value = updatedData;

    setIsLoading(true);
    try {
      const result = await addDocumentFirebase(collectionName, value, globalState.currentCompany);
      handleModalAddClose();

      toast({
        title: 'Deoapp.com',
        description: 'success update card',
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });

      if (result) {
        fetchData();
      }
    } catch (error) {
      console.log('Terjadi kesalahan:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddDetail = () => {
    detailsRef.current = [...detailsRef.current, { key: '', value: '', timeFrom: '', timeTo: '' }];
    forceUpdate()
  };

  const handleRemoveDetail = (index) => {
    const updatedDetails = [...detailsRef.current];
    updatedDetails.splice(index, 1);
    detailsRef.current = updatedDetails;
    forceUpdate()
  };

  return (
    <Stack bg={bg}>
      <Stack>
        <Grid templateColumns={{ base: '1fr', md: '1fr' }}>
          {/* <Stack py={2}>
                        <Text fontWeight={500}>Opportunity Details</Text>
                    </Stack> */}
          <Stack
            h={'550px'}
            overflowY='scroll'>
            <Stack py={2}>
              <Text fontWeight={500}>Contact Details</Text>
            </Stack>

            <Divider />
            <SimpleGrid
              columns={[2]}
              gap={3}
              fontSize='sm'>
              <Stack>
                <Stack>
                  <Text>Contact List</Text>

                  <Input
                    type='text'
                    placeholder='Search users'
                    onChange={(e) => {
                      setShowSearchResults(true);
                      handleSearchUsers(e.target.value);
                    }}
                  />
                </Stack>
                {showSearchResults && searchResult?.length > 0 ? (
                  searchResult?.map((x, index) => {
                    return (
                      <HStack
                        key={index}
                        p='2'
                        zIndex={99}
                        width={'auto'}
                        borderBottom='1px'>
                        <Avatar
                          name={x.document.name}
                          src={x.document.image ? x.document.image : ''}
                        />
                        <Box>
                          <Text>{x.document.name}</Text>
                          <Text>{x.document.email}</Text>
                        </Box>
                        <Spacer />
                        <Button
                          colorScheme='green'
                          onClick={() => handleUserProjectClick(x.document)}>
                          +
                        </Button>
                      </HStack>
                    );
                  })
                ) : (
                  <></>
                )}
              </Stack>
              <Stack>
                <Stack>
                  <Text>Email</Text>
                  <Input ref={emailRef} />
                </Stack>

                <Stack>
                  <Text>Phone</Text>
                  <Input
                    type={'number'}
                    ref={phoneRef}
                  />
                </Stack>
              </Stack>
            </SimpleGrid>

            <Divider pt={3} />

            <Stack>
              <Text fontWeight={500}>Opportunity Details</Text>
            </Stack>

            <Divider />

            <SimpleGrid
              columns={[2]}
              gap={3}
              fontSize='sm'>
              <Stack>
                <Text>Contact Name</Text>
                <Input ref={nameRef} />
              </Stack>

              <Stack>
                <Text>Stage</Text>
                <Select
                  variant='outline'
                  fontWeight='normal'
                  placeholder='Select stage ..'
                  ref={columnRef}>
                  {stages?.length > 0 &&
                    stages.map((x, index) => {
                      return (
                        <option
                          key={index}
                          value={x.stageName}>
                          {x.stageName}
                        </option>
                      );
                    })}
                </Select>
              </Stack>

              <Stack>
                <Text>Status</Text>
                <Select
                  variant='outline'
                  fontWeight='normal'
                  placeholder='Select status ..'
                  ref={statusRef}>
                  <option value={'open'}>Open</option>
                  <option value={'won'}>Won</option>
                  <option value={'lost'}>Lost</option>
                </Select>
              </Stack>
              <Stack>
                <Text>Source</Text>
                <Input ref={sourceRef} />
              </Stack>

              <Stack>
                <Text>Opportunity Value</Text>
                <Input
                  type={'number'}
                  ref={opportunityValueRef}
                />
              </Stack>
            </SimpleGrid>

          </Stack>
        </Grid>
        <Grid gap={3} templateColumns={{ base: '1fr', md: '1fr' }}>
          <Stack w={'100%'}>
            <Text fontWeight={'bold'}>Details Card :</Text>
          </Stack>
          <Stack>
            {detailsRef?.current?.length > 0 && (
              detailsRef?.current?.map((detail, index) => (
                <HStack key={index} align={'center'} justify={'center'}>
                  <FormControl id={`detail-key-${index}`}>
                    <FormLabel>Point:</FormLabel>
                    <Input
                      size={'sm'}
                      type="text"
                      placeholder="Title detail, ex: location"
                      value={detail?.key}
                      onChange={(e) =>
                        handleDetailChange(
                          index,
                          e.target.value,
                          detail?.value,
                          detail?.timeFrom,
                          detail?.timeTo
                        )
                      }
                    />
                  </FormControl>

                  <FormControl id={`detail-value-${index}`}>
                    <FormLabel>Time From:</FormLabel>
                    <Input
                      size={'sm'}
                      defaultValue={detail?.timeFrom}
                      type="date"
                      onChange={(e) =>
                        handleDetailChange(
                          index,
                          detail?.key,
                          detail?.value,
                          e.target.value,
                          detail?.timeTo
                        )
                      }
                    />
                  </FormControl>

                  <FormControl id={`detail-value-${index}`}>
                    <FormLabel>Time To:</FormLabel>
                    <Input
                      size={'sm'}
                      defaultValue={detail?.timeTo}
                      type="date"
                      onChange={(e) =>
                        handleDetailChange(
                          index,
                          detail?.key,
                          detail?.value,
                          detail?.timeFrom,
                          e.target.value
                        )
                      }
                    />
                  </FormControl>

                  <FormControl id={`detail-value-${index}`}>
                    <FormLabel>Value:</FormLabel>
                    <Input
                      size={'sm'}
                      type="text"
                      placeholder="Value detail, ex: location"
                      value={detail?.value}
                      onChange={(e) =>
                        handleDetailChange(
                          index,
                          detail?.key,
                          e.target.value,
                          detail?.timeFrom,
                          detail?.timeTo
                        )
                      }
                    />
                  </FormControl>
                  <Box pt="7">
                    <DynamicButton variant={'solid'} action='delete' onClick={() => handleRemoveDetail(index)} size="sm" />
                  </Box>
                </HStack>
              ))
            )}

          </Stack>
          <Stack>
            <DynamicButton size={'sm'} variant={'solid'} title='Point' action='create' onClick={handleAddDetail} />

          </Stack>
        </Grid>
        <HStack
          gap={5}
          alignItems='flex-end'
          justifyContent={'flex-end'}>
          <Button
            isLoading={isLoading}
            leftIcon={<AddIcon boxSize={3} />}
            colorScheme='green'
            onClick={handleSaveData}>
            Save
          </Button>
        </HStack>
      </Stack>
    </Stack>
  );
}

export default DetailPipelineAddCardV2;
