import { Heading } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import Shipping from '../../Components/Shipment/Shipping'
import JneForm from '../Products/JneForm'
import useUserStore from '../../Hooks/Zustand/Store'
import { getCollectionFirebase } from '../../Api/firebaseApi'
import ShippingRajaOngkir from '../../Components/Shipment/ShippingRajaOngkir';

const ShippingPage = () => {
  const [selectedDestination, setSelectedDestination] = useState('');
  const [selectedCourier, setSelectedCourier] = useState('');
  const [setFullAddress, setFormValues] = useState('');
  const [selectedService, setSelectedService ] = useState({});
  const [selectedSubdistrict, setSelectedSubdistirct] = useState({});
  const [weight, setWeight] = useState(0);

  const globalState = useUserStore();

  const getDataShipping = async () => {
    const conditions = [
      { field: 'companyId', operator: '==', value: globalState.currentCompany },
      { field: 'projectId', operator: '==', value: globalState.currentProject },
    ]
    try {
      const resShipping = await getCollectionFirebase('shipping', conditions);
      console.log(resShipping, 'resShipping')
    } catch (error) {
      console.log(error.message, 'error getting shipping data')
    }
  };


  useEffect(() => {
    getDataShipping();
  }, [])
  return (
    <>
      <Heading>Shipping page</Heading>
      {/* <JneForm
        selectedDestination={selectedDestination}
        setSelectedDestination={setSelectedDestination}
        selectedCourier={selectedCourier}
        setSelectedCourier={setSelectedCourier}
        setFullAddress={setFullAddress}
        setFormValues={setFormValues}
      /> */}
      {/* <Shipping /> */}

      <ShippingRajaOngkir
        setFullAddress={setFullAddress}
        setSelectedCourier={setSelectedCourier}
        selectedCourier={selectedCourier}
        setSelectedDestination={setSelectedDestination}
        selectedDestination={selectedDestination}
        selectedService={selectedService}
        setSelectedService={setSelectedService}
        selectedSubdistrict={selectedSubdistrict}
        setSelectedSubdistirct={setSelectedSubdistirct}
        weight={weight}
      />
    </>
  )
}

export default ShippingPage