/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import ReactFlow, { Background, Controls, Position } from 'reactflow'
import 'reactflow/dist/style.css';
import OrganizationNodeComponent from './OrganizationNodeComponent';

const OrganizationChartComponent = (props) => {
  const [node, setNode] = useState()
  const [edge, setEdge] = useState()
  const [defaultViewport, setDefaultViewPort] = useState({})



  const data = props.data
  const nodeTypes = {
    custom: OrganizationNodeComponent,
  };

  const organisasi = [
    {
      department: 'marketing', divisi: [
        { name: 'digital marketing', subDivisi: ['facebook ads', 'google ads', 'tiktok ads'] },
        { name: 'kol', subDivisi: ['kol instagram', 'kol tiktok'] },
        { name: 'social media', subDivisi: ['copywriter', 'story telling script'] },
      ]
    },
    {
      department: 'creative', divisi: [
        { name: 'design', subDivisi: ['graphic design', 'motion graphic'] },
        { name: 'copywriting', subDivisi: ['headline', 'website copy', 'seo writer', 'story telling script writer'] },
        { name: 'production', subDivisi: ['video editor', 'image editor', 'photographer'] },
      ]
    },
    {
      department: 'Analyst', divisi: [
        { name: 'acconting', subDivisi: ['coa', 'accountin 2', 'accounting 3'] },
        { name: 'finance', subDivisi: ['finance uang masuk', 'finance uang keluar'] },
        { name: 'tax', subDivisi: ['tax PPN', 'tax PPH'] },
      ]
    },
  ]




  //   const handleInitNewObj = () => {
  //     const xIniti = 300
  //     const newNode = []
  //     const newEdges = [];
  //     let divider = 0
  //     let secondDivider = 0
  //     let counter = 0

  //     organisasi.map((x) => {
  //       let initValOrg = 0
  //       initValOrg = divider

  //       x.divisi.map((y) => {
  //         let initValDiv = 0
  //         initValDiv = divider

  //         y.subDivisi.map((z) => {

  //           newEdges.push({ id: `e${z}-STAFF`, source: `${y.name}MANAGER`, target: `${z}STAFF`, animated: true, style: { color: 'green' } })
  //           newNode.push({ id: `${z}STAFF`, type: 'custom', position: { x: divider, y: 500 }, data: { label: `${z.toUpperCase()} STAFF` } })

  //           newEdges.push({ id: `e${z}-INTERN`, source: `${z}STAFF`, target: `${z}INTERN`, animated: true, style: { color: 'green' } })
  //           newNode.push({ id: `${z}INTERN`, type: 'custom', position: { x: divider, y: 700 }, data: { label: `${z.toUpperCase()} INTERN ` } })

  //           counter++
  //           divider += xIniti
  //         })
  //         // add edge and node for subDivisi

  //         const posX = ((divider - xIniti) + initValDiv) / 2
  //         newEdges.push({ id: `e${y.name}-MANAGER`, source: `${x.department}DIRECTOR`, target: `${y.name}MANAGER`, animated: true, style: { color: 'green' } })
  //         newNode.push({ id: `${y.name}MANAGER`, type: 'custom', position: { x: posX, y: 300 }, data: { label: `${y.name.toUpperCase()} MANAGER ` } })
  //         secondDivider += xIniti
  //       })
  //       // add edge and node for divisi
  //       const posX = (divider - initValOrg) / 2 + initValOrg
  //       newEdges.push({ id: `e${x.department}-DIRECTOR`, source: '1', target: `${x.department}DIRECTOR`, animated: true, style: { color: 'green' } })
  //       newNode.push({ id: `${x.department}DIRECTOR`, type: 'custom', position: { x: posX, y: 100 }, data: { label: `${x.department.toUpperCase()} DIRECTOR ` } })

  //     })
  //     // add edge and node for organisasi
  //     const posX = (divider - xIniti) / 2
  //     newNode.push({ id: '1', position: { x: posX - 600, y: -300 }, type: 'custom', data: { label: 'KARYWAN.AI' } })

  //     setNode(newNode)
  //     setEdge(newEdges)

  //   }

  const handleInitialVertical = () => {

    const xIniti = 100
    const newNode = []
    const newEdges = [];
    let divider = 0
    // let secondDivider = 0
    // let counter = 0

    organisasi.map((x, i) => {
      let initValOrg = 0
      initValOrg = divider

      x.divisi.map((y, j) => {
        let initValDiv = 0
        initValDiv = divider
        console.log(y.name, initValDiv, j, 'ini initValDiv')

        y.subDivisi.map((z, k) => {

          newEdges.push({ id: `e${z}-STAFF`, source: `${y.name}MANAGER`, target: `${z}STAFF`, sourcePosition: Position.Right, targetPosition: Position.Left, animated: true, style: { color: 'green' } })
          newNode.push({ id: `${z}STAFF`, type: 'custom', position: { x: 1500, y: divider }, data: { label: `${z.toUpperCase()} STAFF` } })

          // newEdges.push({ id: `e${z}-INTERN`, source: `${z}STAFF`, target: `${z}INTERN`,sourcePosition:Position.Right,targetPosition:Position.Left,animated: true ,style:{ color:'green'}})
          // newNode.push({id:`${z}INTERN`,type: 'custom',position:{x:2000,y:divider},data:{label:`${z.toUpperCase()} INTERN `}})

          //   counter++
          divider += xIniti
        })
        // add edge and node for subDivisi

        const posX = ((divider - xIniti) + initValDiv) / 2
        newEdges.push({ id: `e${y.name}-MANAGER`, source: `${x.department}DIRECTOR`, target: `${y.name}MANAGER`, sourcePosition: Position.Right, targetPosition: Position.Left, animated: true, style: { color: 'green' } })
        newNode.push({ id: `${y.name}MANAGER`, type: 'custom', position: { x: 1000, y: posX }, data: { label: `${y.name.toUpperCase()} MANAGER ` } })
        // secondDivider += xIniti
      })
      // add edge and node for divisi
      const posX = (divider - initValOrg) / 2 + initValOrg
      newEdges.push({ id: `e${x.department}-DIRECTOR`, source: '1', target: `${x.department}DIRECTOR`, sourcePosition: Position.Right, targetPosition: Position.Left, animated: true, style: { color: 'green' } })
      newNode.push({ id: `${x.department}DIRECTOR`, type: 'custom', position: { x: 500, y: posX }, data: { label: `${x.department.toUpperCase()} DIRECTOR ` } })

    })
    // add edge and node for organisasi
    const posX = (divider - xIniti) / 2
    newNode.push({ id: '1', position: { x: 0, y: posX }, type: 'custom', data: { label: 'KARYWAN.AI' } })
    setDefaultViewPort({ x: 0, y: posX, zoom: 10 })

    setNode(newNode)
    setEdge(newEdges)
  }

  const handleInitialFirst = () => {
    const xIniti = 200
    const newNode = []
    const newEdges = [];
    let divider = 0

    if (data) {
      // console.log('running data subsidi')

      console.log(data, ' ini data di handle init')
      //
      data.map((y, a) => {
        let initVal = 0
        y.divisi.map((x, i) => {
          if (i === 0) {
            initVal = divider
          }
          x.

            newEdges.push({ id: `e${x}-${a * i + 2}MANAGER`, source: `${y.name}`, target: `${x}MANAGER`, animated: true, style: { color: 'green' } })
          newNode.push({ id: `${x}MANAGER`, type: 'custom', position: { x: divider, y: 200 }, data: { label: `${x.toUpperCase()} MANAGER ` } })


          newEdges.push({ id: `e${x}-${a * i + 2}STAFF`, source: `${x}MANAGER`, target: `${x}STAFF`, animated: true, style: { color: 'green' } })
          newNode.push({ id: `${x}STAFF`, type: 'custom', position: { x: divider, y: 300 }, data: { label: `${x.toUpperCase()} STAFF ` } })

          newEdges.push({ id: `e${x}-${a * i + 2}INTERN`, source: `${x}STAFF`, target: `${x}INTERN`, animated: true, style: { color: 'green' } })
          newNode.push({ id: `${x}INTERN`, type: 'custom', position: { x: divider, y: 400 }, data: { label: `${x.toUpperCase()} INTERN ` } })


          // if(y.subDivisi[i])
          divider += xIniti
        })

        const posX = (initVal + (divider - xIniti)) / 2
        newEdges.push({ id: `e1-${a + 2}`, source: '1', target: `${y.name}`, animated: true, style: { color: 'green' } })
        newNode.push({ id: `${y.name}`, type: 'custom', position: { x: posX, y: 100 }, data: { label: `${y.name.toUpperCase()} Director ` } })

      })

    }
    newNode.push({
      id: '1',
      position: { x: (divider - xIniti) / 2, y: 0 },
      type: 'custom',
      data: { label: 'KARYWAN.AI' }
    }
    )
    setNode(newNode)
    setEdge(newEdges)

  }



  useEffect(() => {
    //   if(!props.data?.length)
    //   handleInitialObj()
    // handleInitNewObj()
    handleInitialVertical()



  }, [props])

  return (

    <div style={{ width: 'full', height: '70vh' }}>
      <ReactFlow
        nodes={node}
        edges={edge}
        nodeTypes={nodeTypes}
        // defaultViewport={defaultViewport}
        fitView
        // fitViewOptions={
        //     nodes:{id: Node['i']}
        // }
        proOptions={{ account: 'paid-pro', hideAttribution: true }}
      >
        <Controls />
        <Background variant="dots" gap={12} size={1} />
      </ReactFlow>
    </div>
  )
}
export default OrganizationChartComponent