/* eslint-disable react/prop-types */
import { Divider, HStack, Spacer, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { FiDelete, FiEdit } from 'react-icons/fi'

function CardAddress({ data, handleDeleteAddress, handleEditAddress }) {
  return (
    <Stack p={2} spacing={1}>
      {data && (
        <>
          <HStack>
            <Spacer />
            <HStack spacing={4}>
              <FiEdit cursor={'pointer'} size={20} onClick={handleEditAddress} />
              <FiDelete cursor={'pointer'} size={20} onClick={handleDeleteAddress} />
            </HStack>
          </HStack>

          <Divider />
          <Stack>
            <Text fontSize={'sm'} noOfLines={1}>{data?.street}</Text>
            <Text fontSize={'sm'}>{data?.city}, {data?.state}, {data?.country}, {data?.postal_code}</Text>
          </Stack>
        </>
      )}
      
    </Stack>
  )
}

export default CardAddress