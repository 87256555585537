/* eslint-disable react/react-in-jsx-scope */
import {
  Box,
  Button,
  Center,
  Divider,
  Grid,
  Heading,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import useUserStore from '../../Hooks/Zustand/Store';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCollectionFirebase, setDocumentFirebase } from '../../Api/firebaseApi';
import { clientTypessense } from '../../Api/Typesense';
import moment from 'moment';
import BackButtons from '../../Components/Buttons/BackButtons';
import { FaRegCalendar, FaWhatsapp } from 'react-icons/fa';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import DynamicTable from '../../Components/Table/DynamicTable';
import DatePicker from '../../Components/DatePicker/DatePicker';
  
  
const ContactsPage = () => {
  const globalState = useUserStore();
  const itemsPerPage = 10; // Jumlah data per halaman
  
  const [modalContact, setModalContact] = useState(false);
  const [dataNew, setDataNew] = useState();
  const [dataSearchContact, setDataSearchContact] = useState([]);
  const [inputSearch, setInputSearch] = useState('');
  
  const modalFilterDate = useDisclosure();
  const navigate = useNavigate();
  const toast = useToast();
  
  const [selectedDateRange, setSelectedDateRange] = useState();
  const [contactList, setContactList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  
  
  const getData = async () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const conditions = [
      { field: 'companyId', operator: '==', value: globalState.currentCompany },
      { field: 'projectId', operator: '==', value: globalState.currentProject },
    ];
  
    const sortBy = { field: 'createdAt', direction: 'desc' };
    const limitValue = startIndex + itemsPerPage;
  
    try {
      const contacts = await getCollectionFirebase(
        'contacts',
        conditions,
        sortBy,
        limitValue
      );
      setContactList(contacts);
  
      setTotalPages(Math.ceil(1000 / itemsPerPage));
    } catch (error) {
      console.log(error, 'ini error');
    }
  };
  
  const getFilteredData = async () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const conditions = [
      { field: 'companyId', operator: '==', value: globalState.currentCompany },
      { field: 'projectId', operator: '==', value: globalState.currentProject },
      {
        field: 'createdAt',
        operator: '>=',
        value: selectedDateRange.startDate,
      },
      { field: 'createdAt', operator: '<=', value: selectedDateRange.endDate },
    ];
  
    const sortBy = { field: 'createdAt', direction: 'desc' };
    const limitValue = startIndex + itemsPerPage;
  
    try {
      const contacts = await getCollectionFirebase(
        'contacts',
        conditions,
        sortBy,
        limitValue
      );
      setContactList(contacts);
  
      const totalContacts = await getCollectionFirebase('contacts', conditions);
      setTotalPages(Math.ceil(totalContacts.length / itemsPerPage));
    } catch (error) {
      console.log(error, 'ini error');
    }
  };
  
  const handleTypesenseSearch = (q) => {
    const searchParameters = {
      q: q,
      query_by: 'name, email, phoneNumber',
      filter_by: `projectId: ${globalState.currentProject}`,
      sort_by: '_text_match:desc',
    };
  
    if (q) {
      clientTypessense
        .collections('contacts')
        .documents()
        .search(searchParameters)
        .then((x) => {
          const newData = x.hits.map((y) => {
            return { ...y.document };
          });
          setDataSearchContact(newData, 'ini data');
          setInputSearch(q);
        });
    } else {
      setDataSearchContact(contactList);
      setInputSearch(q);
    }
  };
  
  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1); // Pindahkan ke halaman berikutnya saat tombol "Load More" diklik
  };
  
  useEffect(() => {
    if (!selectedDateRange) {
      getData();
    } else {
      getFilteredData();
    }
  }, [globalState.currentProject, currentPage, selectedDateRange]);
  
  const handleDetail = (contact) => {
    navigate(`/relations/contacts/detail/${contact.id}`, { state: contact });
  };
  
  const handleModalOpen = () => {
    setModalContact(true);
  };
  
  const handleModalClose = () => {
    setModalContact(false);
    setDataNew();
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setDataNew((prev) => ({ ...prev, [name]: value }));
  };
  
  const handleChangeRadioButton = (value) => {
    console.log(value);
    setDataNew({ ...dataNew, contactType: value });
  };
  
  const handleDateRangeChange = (dateRange) => {
    setSelectedDateRange(dateRange);
  };
  
  const handleSaveContact = async () => {
    const collectionName = 'contacts';
    const docName = `${dataNew.phoneNumber}-${globalState?.currentProject}`;
    const data = {
      ...dataNew,
      projectId: globalState?.currentProject,
      createdAt: new Date(),
      lastUpdated: new Date(),
      companyId: globalState?.currentCompany,
    };
  
    setIsLoading(true);
    try {
      const result = await setDocumentFirebase(
        collectionName,
        docName,
        data,
        globalState?.currentCompany
      );
      console.log(result); // Pesan toast yang berhasil
      toast({
        title: 'Success',
        description: 'Success add new contact. ',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      handleModalClose();
      getData();
    } catch (error) {
      console.log('Terjadi kesalahan:', error);
    } finally {
      setIsLoading(false);
    }
  };
  
  const inputStyles = {
    '&::placeholder': {
      color: 'gray.500',
    },
  };
  
  const searchProject = globalState.projects.find(
    (x) => x.id === globalState.currentProject
  );

  const searchCompanyName = globalState.companies.find(
    (x) => x.id === globalState.currentCompany
  );
  
  const tableHeader = ['name', 'tags', 'phone', 'email', 'ltv', 'trx', 'aov', 'last activity'];
  
  let tableData = ''
  
  if (inputSearch === '') {
    tableData = contactList?.map((data, index) => {
  
      const modifiedPhoneNumber = data?.phoneNumber?.startsWith('08')
        ? '628' + data?.phoneNumber.slice(2) // Replace "08" with "628"
        : data?.phoneNumber;
  
  
      const name = data.name || '';
      const tags = data?.tags || 'No Tags'
      const phone = `+${modifiedPhoneNumber ? modifiedPhoneNumber : ''}` || '';
      const email = data.email || '';
      const ltv = data?.ltv || 0
      const trx = data?.trx || 0
      const aov = data?.aov || 0
      const lastActivity = moment(data?.lastUpdated.seconds * 1000).format('LLL')
  
      return {
        data,
        name: name,
        tags: tags,
        phone: phone,
        email: email,
        ltv: ltv,
        trx: trx,
        aov: aov,
        'last activity': lastActivity
      };
    });
  
  } else {
    tableData = dataSearchContact?.map((data, index) => {
  
      const modifiedPhoneNumber = data?.phoneNumber?.startsWith('08')
        ? '628' + data?.phoneNumber.slice(2) // Replace "08" with "628"
        : data?.phoneNumber;
    
      const name = data.name || '';
      const tags = data?.tags || 'No Tags'
      const phone = `+${modifiedPhoneNumber ? modifiedPhoneNumber : ''}` || '';
      const email = data.email || '';
      const ltv = data?.ltv || 0
      const trx = data?.trx || 0
      const aov = data?.aov || 0
      const lastActivity = moment(data?.lastUpdated.seconds * 1000).format('LLL')
  
      return {
        data,
        name: name,
        tags: tags,
        phone: phone,
        email: email,
        ltv: ltv,
        trx: trx,
        aov: aov,
        'last activity': lastActivity
      };
    });
  }
  
  
  
  
  const handleRead = (row) => {
    // Tambahkan logika hapus sesuai kebutuhan Anda
    handleDetail(row.data)
  };
  
  const handleCustom = (row) => {
  
    window.open(`https://wa.me/${row.phone}?text=Halo,%20Saya%20dari%20tim%20${searchCompanyName.name}`)
  
  }
  
  
  
  
  return (
    <Stack p={[1, 1, 5]}>
      <Stack spacing={4}>
  
        <HStack gap={5}>
          <BackButtons />
          <Heading size={'md'} fontWeight="bold">
              Contacts
          </Heading>
          <Spacer />
          <HStack >
            <Input
  
              w='40%'
              type="text"
              placeholder="Search Contact"
              bgColor="white"
              color="black"
              sx={inputStyles}
              fontSize="sm"
              onChange={(e) => handleTypesenseSearch(e.target.value)}
            />
            <Box>
  
              <Button
                onClick={modalFilterDate.onOpen}
                bgColor={'transparent'}
                // shadow="md"
                variant="outline"
                borderColor="#F05A28"
                color="#F05A28"
              >
                <HStack>
                  <FaRegCalendar />
                  <Text>Filter date</Text>
                </HStack>
              </Button>
            </Box>
            <Box w='15%'>
  
              <DynamicButton title={'Add Contact'} action="create" onClick={handleModalOpen} />
            </Box>
          </HStack>
        </HStack>
  
  
        <DynamicTable
          header={tableHeader}
          data={tableData}
          onRead={handleRead}
          onCustom={handleCustom}
          action_color="green"
          action_icon={FaWhatsapp}
        />
  
        <Stack alignItems={'center'} justifyContent="center">
          {currentPage < totalPages && (
            <Button colorScheme={'blue'} fontSize="sm" onClick={handleLoadMore}>
                Load More
            </Button>
          )}
        </Stack>
  
      </Stack >
  
      <Modal size={'xl'} isOpen={modalContact} onClose={handleModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>New Contact</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <Grid templateColumns={{ base: '1fr', md: '1fr' }}>
                {/* <Stack py={2}>
                          <Text fontWeight={500}>Opportunity Details</Text>
                      </Stack> */}
                <Stack overflowY="scroll">
                  <Stack py={2}>
                    <Text fontWeight={500}>Contact</Text>
                  </Stack>
  
                  <Divider />
                  <Stack>
                    <Text>Name</Text>
                    <Input
                      name="name"
                      onChange={handleChange}
                      placeholder="name"
                    />
                  </Stack>
  
                  <Stack>
                    <Text>Email</Text>
                    <Input
                      name="email"
                      onChange={handleChange}
                      placeholder="email"
                    />
                  </Stack>
  
                  <Stack>
                    <Text>Phone</Text>
                    <Input
                      name="phoneNumber"
                      type="number"
                      onChange={handleChange}
                      placeholder="Phone Number"
                    />
                  </Stack>
  
                  <Stack>
                    <Text>Contact Type</Text>
                    <HStack>
                      <RadioGroup
                        name="contactType"
                        onChange={handleChangeRadioButton}
                      >
                        <Stack direction="row" spacing={5}>
                          <Radio value={'leads'}>Lead</Radio>
                          <Radio value={'costumer'}>Costumer</Radio>
                        </Stack>
                      </RadioGroup>
                      {/* <Select
                        onChange={handleChange}
                        name="contactType"
                        variant="outline"
                        placeholder="Content type"
                        fontWeight="normal"
                        >
                        <option value={"leads"}>Lead</option>
                        <option value={"costumer"}>Costumer</option>
                      </Select> */}
                    </HStack>
                  </Stack>
  
                  {/* <Stack>
                <Text>Tags</Text>
                <CreatableSelect
                  isClearable={true}
                  value={selectedTagsRef.current}
                  options={data?.category?.map((category) => ({ label: category, value: category })) || []}
                  isMulti
                  onChange={handleTagChange}
                />
              </Stack> */}
                </Stack>
              </Grid>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              isLoading={isLoading}
              colorScheme="blue"
              // variant={"outline"}
              size="sm"
              mr={3}
              onClick={handleSaveContact}
            >
                Save
            </Button>
            <Button
              colorScheme="red"
              // variant={"outline"}
              size="sm"
              mr={3}
              onClick={handleModalClose}
            >
                Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
  
      <Modal
        isOpen={modalFilterDate.isOpen}
        onClose={modalFilterDate.onClose}
        size={'2xl'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Filter Date</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Center>
              <DatePicker onDateChange={handleDateRangeChange} />
            </Center>
  
            <HStack>
              <HStack spacing={4}>
                <Stack fontSize={12} spacing={1}>
                  <Text fontWeight={'semibold'}>Start Date: </Text>
                  <Text>
                    {moment(selectedDateRange?.startDate).format('LLL')}
                  </Text>
                </Stack>
                <Stack fontSize={12} spacing={1}>
                  <Text fontWeight={'semibold'}>End Date:</Text>
                  <Text>
                    {moment(selectedDateRange?.endDate).format('LLL')}
                  </Text>
                </Stack>
              </HStack>
              <Spacer />
              <Button
                size={'xs'}
                colorScheme="blue"
                onClick={() => setSelectedDateRange()}
              >
                  Clear Filter
              </Button>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Stack >
  );
};
  
export default ContactsPage;
  