import moment from 'moment'
import _axios from '../Api/AxiosBarrier'

export const uploadBase64ToDropbox = async (base64, globalState) => {
  const dataModule = {
    companyId:globalState?.currentCompany,
    projectId:globalState?.currentProject,
    module:'karyawanAi',
    base64Data :base64,
    mimeType: 'image/png', // Ganti dengan tipe MIME yang sesuai
    fileName: `example-${moment(new Date()).valueOf()}.png`
  }
  try {
    const resImage = await _axios.post('/dropbox-upload_file_base64', dataModule)
    return resImage.data.link
  } catch (error) {
    console.log(error, 'ini error')
  }
}


export const convertImageToBase64 = async (imageUrl) => {
  try {
    const response = await fetch(imageUrl);
    const blob = await response.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error('Error converting image to base64:', error);
    throw error;
  }
};