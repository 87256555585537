import React from 'react';
import KaryawanMainPage from '../Pages/Karyawan/KaryawanMainPage';

const KaryawanRouter = [
  {
    path: '/karyawan/:id',
    element: <KaryawanMainPage />,
  },
 
];

export default KaryawanRouter;
