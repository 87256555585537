/* eslint-disable react/prop-types */
import { Avatar, Box, HStack, Image, Text } from '@chakra-ui/react';
import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';

function OrganizationNodeComponent({ data }) {
  const linearColor ='linear(to-r, #FFA500, #ffd600)'

  return (
    <Box bgGradient={data.label==='KARYWAN.AI'?'black':linearColor} p='2' borderRadius='md'>
      {data.label==='KARYWAN.AI'?<Image width='400px' src='https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/img-fzl/logo/2.png' alt='logo'/> 
        :
        <HStack>
          <Avatar src='https://assets.promptbase.com/DALLE_IMAGES%2Fc4FFJSl9SQUooZL16kdfJX3uxZh1%2Fresized%2F1694386294188m_1000x1000.webp?alt=media&token=a90056d2-1596-438c-b037-22f5fdfe4f3c'/>

          <Box>
            <Text fontSize='md'>{data.label}</Text>
            <Text fontSize='sm'>Name here</Text>
          </Box>
        </HStack>
      }

      {/* <Handle type="source" position={Position.Bottom} /> */}
      <Handle type="source" position={Position.Right} />
      <Handle type="target" position={Position.Left} />

      {data.label==='KARYWAN.AI'?<></>:
        <>
          {/* <Handle type="target" position={Position.Top}  /> */}
          <Handle type="source" position={Position.Right} />

        </>}
    </Box>
  );
}

export default memo(OrganizationNodeComponent);