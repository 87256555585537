/* eslint-disable react/prop-types */
import { Avatar, AvatarBadge, Box, Divider, HStack, Spacer, Stack, Text } from '@chakra-ui/react'
import moment from 'moment'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { stringShortener } from '../../Utils/stringUtil'

function KaryawanMessageCard({  message, highlightChat, setHighlightChat }) {

  const navigate = useNavigate()

  const handleNavigate = () => {
    setHighlightChat(message.id)
    navigate(`/karyawanAi/${message.karyawanAi}`)
  }


  return (
    <Stack onClick={handleNavigate}>
      <HStack
        py={1}
        _hover={{
          bg: 'bg.muted',
        }}
        rounded="md"
        cursor="pointer"
        border={highlightChat === message.id ? '1px' : '0px'}
        borderradius='lg'
        borderColor='#ffd600'
        
        w={'100%'}
        spacing={3}
      >
        <Box pt="1">
          <Avatar
            size="sm"
            src={message.image}
            name={message.name}
          >
            <AvatarBadge />
          </Avatar>
        </Box>
        <Link to={`/chat/${message.karyawanAi}`}>
          <Stack spacing="0" fontSize="sm" isTruncated w='170px'>
            <HStack spacing="1">
              <Text fontWeight="medium" >
                {message.name}
              </Text>
              <Spacer />

            </HStack>
            <Box color="fg.subtle" isTruncated alignItems={'center'} justifyContent='center'>
              <Text noOfLines={1}>
                {stringShortener(message?.message, 35)}

              </Text>
            </Box>
          </Stack>
        </Link>
        <Spacer />
        <Box >
          <Text color="fg.subtle" fontSize="xx-small" fontWeight={500}>
            {moment(message?.createdAt?.seconds * 1000).fromNow()}
          </Text>
        </Box>
      </HStack>
      <Divider />
    </Stack>
  )
}

export default KaryawanMessageCard