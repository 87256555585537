import React from 'react';
import PaymentPage from '../Pages/Payment/PaymentPage';


const PaymentRouter = [
  
  {
    path: '/payment',
    element: <PaymentPage />,
  }




];

export default PaymentRouter;
