import {
  Divider,
  Flex,
  Heading,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getSingleDocumentFirebase } from '../../Api/firebaseApi';

import BackButtons from '../../Components/Buttons/BackButtons';
import DetailPipelineCard from '../../Components/Card/DetailPipelineCard';
import useUserStore from '../../Hooks/Zustand/Store';
import { clientTypessense } from '../../Api/Typesense';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import AcquisitionColumnsComponent from '../../Components/Columns/AcquisitionColumnsComponent';
import DetailPipelineAddCardV2 from '../../Components/Card/DetailPipelineAddCardV2';

function AcquisitionPipelineViewPageV3() {
  const param = useParams();
  const globalState = useUserStore();
  const location = useLocation();
  const datas = location.state;
  const [pipelineList, setPipelineList] = useState('');

  const [searchResult, setSearchResult] = useState('');

  const navigate = useNavigate();


  const [modalDetail, setModalDetail] = useState(false);
  const [detailActive, setDetailActive] = useState('');

  const [modalAdd, setModalAdd] = useState(false);

  const [filterData, setFilterData] = useState({ search: '', status: '' });

  const fetchData = async () => {
    try {
      const result = await getSingleDocumentFirebase('files', param.id);
      setPipelineList(result);
    } catch (error) {
      console.log(error);
    }
  };

  const handleModalOpen = (x) => {
    setModalDetail(true);
    setDetailActive(x);
  };

  const handleModalClose = () => {
    setModalDetail(false);
    setDetailActive('');
  };

  const handleModalAddClose = () => {
    setModalAdd(false);
  };

  const handleModalAddOpen = () => {
    setModalAdd(true);
  };


  const handleSearchUsers = async (q) => {
    const companyUsers = globalState.companies.find((x) => x.id === globalState.currentCompany);
    const userChunks = chunkArray(companyUsers?.users, 100);

    const searchPromises = userChunks.map((userChunk) => {
      const searchParameters = {
        q: q,
        query_by: 'name,email',
        filter_by: `id: [${userChunk.join(',')}]`,
        sort_by: '_text_match:desc',
      };

      return clientTypessense.collections('users').documents().search(searchParameters);
    });

    Promise.all(searchPromises)
      .then((results) => {
        const combinedResults = results.flatMap((result) => result.hits);
        setSearchResult(combinedResults);
      })
      .catch((error) => {
        console.error('Error performing search:', error);
      });
  };

  const chunkArray = (arr, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < arr?.length; i += chunkSize) {
      chunks.push(arr.slice(i, i + chunkSize));
    }
    return chunks;
  };

  //   console.log(globalState);

  useEffect(() => {
    fetchData();

    return () => { };
  }, [globalState.currentProject]);

  return (
    <Stack
      p={[1, 1, 5]}
      spacing={5}>
      <HStack gap='5'>
        <BackButtons />
        <Heading size={'md'}>Pipeline {pipelineList?.title}</Heading>
        <Spacer />
        <HStack>
          <Input
            type='text'
            w='40%'
            placeholder='Search Leads'
            onChange={(e) => setFilterData({ search: e.target.value })}
          />
          <Select
            w='fit-content'
            placeholder='Sort by'
            onChange={(e) => setFilterData({  status: e.target.value })}>
            <option value={'won'}>Won</option>
            <option value={'lost'}>Lost</option>
            <option value={'open'}>Open</option>
          </Select>
          <DynamicButton
            action={'create'}
            onClick={handleModalAddOpen}
            title={'Add Contact'}
          />
        </HStack>
      </HStack>

      <Stack>{pipelineList?.length > 0}</Stack>
      <Flex overflowX='auto'>
        <DndProvider backend={HTML5Backend}>
          <HStack>
            {pipelineList?.stages?.map((x, i) => {
              return (
                <AcquisitionColumnsComponent
                  handleModalOpen={handleModalOpen}
                  key={i}
                  index={i}
                  kanbanData={{ name: x?.stageName }}
                  filesId={param.id}
                  allowedDropEffect='move'
                  filterData={filterData}
                  column={x?.stageName}
                />
              );
            })}
          </HStack>
        </DndProvider>
      </Flex>

      <Modal
        isOpen={modalDetail}
        onClose={handleModalClose}
        isCentered
        size={'4xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Stack>
              <Text textTransform={'capitalize'}>Detail {detailActive.name}</Text>
            </Stack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize={'sm'}>Add and edit opportunity details, tasks, notes and appointments.</Text>
            <Divider py={1} />
            <Stack>
              {detailActive && (
                <DetailPipelineCard
                  fetchData={fetchData}
                  pipeline={datas}
                  data={detailActive}
                  stages={pipelineList?.stages}
                  navigate={navigate}
                  handleModalClose={handleModalClose}
                />
              )}
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={modalAdd}
        onClose={handleModalAddClose}
        isCentered
        size={'4xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Stack>
              <Text textTransform={'capitalize'}>Add Contact</Text>
            </Stack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize={'sm'}>Add and edit opportunity details, tasks, notes and appointments. </Text>
            <Divider py={1} />
            <Stack>
              {pipelineList && (
                <DetailPipelineAddCardV2
                  fetchData={fetchData}
                  handleSearchUsers={handleSearchUsers}
                  searchResult={searchResult}
                  //   formId={pipelineList?.formId[0]}
                  filesId={param.id}
                  stages={pipelineList?.stages}
                  navigate={navigate}
                  handleModalAddClose={handleModalAddClose}
                />
              )}
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Stack>
  );
}

export default AcquisitionPipelineViewPageV3;
